import {
  calculateAngle,
  getTransformedPoint,
  pointInPolygon,
  toVirtualX,
  toVirtualY,
} from "utils/studio/utils";
import LayerObject from "../LayerObject";
import {
  CONTROLS_CANVAS,
  CORNER_CIRCLE_RADIUS,
  DRAW_OBJECT_TYPES,
  MOVEMENT_TYPES,
  RESIZE_RECT_FILL_COLOR,
  RESIZE_RECT_LINE_SIZE,
  RESIZE_RECT_STROKE_COLOR,
  ROTATION_LINE_WIDTH,
} from "constants/studio";
import { getMousePos } from "components/atoms/ImageEditor/canvasUtil";
import EraserObject from "lib/drawings/EraserObject";

class ShapeObject extends LayerObject {
  x = 0;
  y = 0;
  width = 0;
  height = 0;
  originalWidth = 0;
  originalHeight = 0;
  aspectRatio = 0;
  controlsCanvas = null;
  eraserLayers = [];

  constructor({
    type,
    position,
    meta,
    canvas = null,
    rotation = 0,
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    controlsCanvas = null,
    eraserLayers = [],
  }) {
    super({ type, position, canvas, meta });
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.rotation = rotation;
    this.aspectRatio = this.width / this.height;
    this.originalWidth = width;
    this.originalHeight = height;
    this.eraserLayers = eraserLayers;

    if (controlsCanvas) {
      this.controlsCanvas = controlsCanvas;
    } else {
      this.controlsCanvas = document.getElementById(CONTROLS_CANVAS);
    }
  }

  setAspectRatio(aspectRatio) {
    this.aspectRatio = aspectRatio;
  }

  setWidth(width) {
    this.width = width;
  }
  setHeight(height) {
    this.height = height;
  }
  setX(x) {
    this.x = x;
  }
  setY(y) {
    this.y = y;
  }
  setRotation(rotation) {
    this.rotation = rotation;
  }

  getCenterCoordinates({
    offsetX = 0,
    offsetY = 0,
    scale = 1,
    centerX,
    centerY,
  }) {
    const cos = Math.cos(this.rotation);
    const sin = Math.sin(this.rotation);

    const x = toVirtualX(centerX ?? this.x, offsetX, scale);
    const y = toVirtualY(centerY ?? this.y, offsetY, scale);

    const width = this.width * scale;
    const height = this.height * scale;
    const scaledWidth = width * this.scaleX;
    const scaledHeight = height * this.scaleY;

    const formatCenterX = x + scaledWidth / 2;
    const formatCenterY = y + scaledHeight / 2;
    const originX = -scaledWidth / 2;
    const originY = -scaledHeight / 2;

    const matrix = [cos, sin, -sin, cos, formatCenterX, formatCenterY];

    return {
      originX,
      originY,
      matrix,
      width: scaledWidth,
      height: scaledHeight,
      x,
      y,
      centerX: formatCenterX,
      centerY: formatCenterY,
    };
  }

  drawResizeRect({ offsetX, offsetY, scale }) {
    const canvas = this.controlsCanvas;
    const ctx = canvas?.getContext("2d");

    if (!canvas || !ctx) return;
    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const { originX, originY, matrix, width, height } =
      this.getCenterCoordinates({ offsetX, offsetY, scale });

    ctx.setTransform(...matrix);

    ctx.beginPath();
    ctx.rect(originX, originY, width, height);
    ctx.strokeStyle = RESIZE_RECT_STROKE_COLOR;
    ctx.lineWidth = 2;
    ctx.stroke();

    if (this.type === DRAW_OBJECT_TYPES.mainRect) {
      ctx.restore();
      return;
    }

    const resizePoints = this.getResizePoints({
      x: originX,
      y: originY,
      width,
      height,
    });

    const rotationPoint = resizePoints[MOVEMENT_TYPES.resize_AB];

    ctx.moveTo(rotationPoint.x, rotationPoint.y - ROTATION_LINE_WIDTH);
    ctx.lineTo(rotationPoint.x, rotationPoint.y);

    ctx.lineWidth = RESIZE_RECT_LINE_SIZE;
    ctx.stroke();

    ctx.fillStyle = RESIZE_RECT_FILL_COLOR;

    for (let coords of Object.values(resizePoints)) {
      ctx.beginPath();
      ctx.arc(coords.x, coords.y, CORNER_CIRCLE_RADIUS, 0, 2 * Math.PI);
      ctx.fill();
    }

    ctx.restore();
  }

  checkIfSelectedCircle({ x, y, offsetX, offsetY, scale }) {
    let isSelected = false;
    const { originX, originY, matrix, width, height } =
      this.getCenterCoordinates({ offsetX, offsetY, scale });
    const ctx = this.getContext();

    ctx.save();
    ctx.setTransform(...matrix);

    const resizePoints = this.getResizePoints({
      x: originX,
      y: originY,
      width,
      height,
    });

    for (let [movementType, coords] of Object.entries(resizePoints)) {
      ctx.beginPath();
      ctx.arc(coords.x, coords.y, CORNER_CIRCLE_RADIUS, 0, 2 * Math.PI);

      if (ctx.isPointInPath(x, y)) {
        isSelected = movementType;
        break;
      }
      ctx.closePath();
    }

    ctx.restore();

    return isSelected;
  }

  checkIsSelected({ offsetX, offsetY, scale, x, y }) {
    let isSelected = false;

    const { matrix, originX, originY, width, height } =
      this.getCenterCoordinates({
        offsetX,
        offsetY,
        scale,
      });

    const point = { x, y };

    const resizePoints = this.getResizePoints({
      x: originX,
      y: originY,
      width,
      height,
    });

    const vertices = {
      topLeft: getTransformedPoint(resizePoints.resize_A, matrix),
      topRight: getTransformedPoint(resizePoints.resize_B, matrix),
      bottomRight: getTransformedPoint(resizePoints.resize_C, matrix),
      bottomLeft: getTransformedPoint(resizePoints.resize_D, matrix),
    };

    isSelected = pointInPolygon(point, vertices);

    if (!isSelected) {
      isSelected = this.checkIfSelectedCircle({
        x,
        y,
        offsetX,
        offsetY,
        scale,
      });
    }

    return isSelected;
  }

  checkMovement({ clientX, clientY, offsetX, offsetY, scale }) {
    const canvas = this.getCanvas();

    const { x, y } = getMousePos(canvas, { clientX, clientY });
    const ctx = canvas.getContext("2d");

    return (
      this.checkIfSelectedCircle({
        ctx,
        x,
        y,
        offsetX,
        offsetY,
        scale,
      }) || MOVEMENT_TYPES.move
    );
  }

  rotateItem(x, y, scale) {
    const itemX = this.x;
    const itemY = this.y;

    const width = this.width * scale;
    const middleW = width / 2;
    const topCenterX = itemX + middleW;
    const topCenterY = itemY - 20;

    const angle = calculateAngle(topCenterX, topCenterY, x, y);

    this.rotation = angle;

    if (Math.abs(this.rotation) > Math.PI / 2) {
      this.rotation += Math.PI;
    }

    if (this.type === DRAW_OBJECT_TYPES.group) {
      for (const object of this.objects) {
        if (object instanceof EraserObject) {
          object?.setRotation && object.setRotation(this.rotation);
        } else {
          object.rotateItem && object.rotateItem(x, y, scale);
        }
      }
    }
  }

  resizeItem(movementType, { dx, dy, isShift }) {
    let newX = this.x || 0;
    let newY = this.y || 0;
    let newScaleX = 1;
    let newScaleY = 1;

    switch (movementType) {
      case MOVEMENT_TYPES.move:
        newX += dx;
        newY += dy;
        break;
      case MOVEMENT_TYPES.resize_A:
        // newX += dx;
        // newY += dy;
        newScaleX -= dx / this.width;
        newScaleY -= dy / this.height;
        break;
      case MOVEMENT_TYPES.resize_B:
        // newY += dy;
        newScaleX += dx / this.width;
        newScaleY -= dy / this.height;
        break;
      case MOVEMENT_TYPES.resize_C:
        newScaleX += dx / this.width;
        newScaleY += dy / this.height;
        break;
      case MOVEMENT_TYPES.resize_D:
        // newX += dx;
        newScaleX -= dx / this.width;
        newScaleY += dy / this.height;
        break;
      case MOVEMENT_TYPES.resize_AB:
        // newY += dy;
        newScaleY -= dy / this.height;
        break;
      case MOVEMENT_TYPES.resize_BC:
        newScaleX += dx / this.width;
        break;
      case MOVEMENT_TYPES.resize_CD:
        newScaleY += dy / this.height;
        break;
      case MOVEMENT_TYPES.resize_AD:
        // newX += dx;
        newScaleX -= dx / this.width;
        break;
      default:
        break;
    }

    if (isShift) {
      const newScale = Math.min(
        this.scaleX * newScaleX,
        this.scaleY * newScaleY
      );

      this.scaleX = newScale;
      this.scaleY = newScale;
    } else {
      this.scaleX *= newScaleX;
      this.scaleY *= newScaleY;
    }

    this.x = newX;
    this.y = newY;
  }

  getResizePoints({ x, y, width, height }) {
    const dtX = width / 2;
    const dtY = height / 2;
    const topCenterX = x + dtX;
    const topCenterY = y - ROTATION_LINE_WIDTH;

    const coordinates = {
      [MOVEMENT_TYPES.resize_A]: { x, y },
      [MOVEMENT_TYPES.resize_B]: { x: x + width, y },
      [MOVEMENT_TYPES.resize_C]: { x: x + width, y: y + height },
      [MOVEMENT_TYPES.resize_D]: { x, y: y + height },
      [MOVEMENT_TYPES.resize_AB]: {
        x: x + dtX,
        y,
      },
      [MOVEMENT_TYPES.resize_CD]: {
        x: x + dtX,
        y: y + height,
      },
      [MOVEMENT_TYPES.resize_BC]: {
        x: x + width,
        y: y + dtY,
      },
      [MOVEMENT_TYPES.resize_AD]: {
        x,
        y: y + dtY,
      },
      [MOVEMENT_TYPES.rotation]: {
        x: topCenterX,
        y: topCenterY,
      },
    };

    return coordinates;
  }

  addEraserLayer(layer) {
    this.eraserLayers = [...this.eraserLayers, layer];
    layer.initialScaleX = this.scaleX;
    layer.initialScaleY = this.scaleY;
  }

  setControlsCanvas(canvas) {
    this.controlsCanvas = canvas;
  }
}

export default ShapeObject;
