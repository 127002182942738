import { Link, useLocation } from "react-router-dom";
import { CButton } from "components/atoms/CButton";
import "./Auth/styles.scss";
import logo from "assets/imgs/logo.svg";
import Empty from "assets/imgs/auth/404.svg";

export function Page404() {
  const location = useLocation();
  
  // Define the path for the admin dashboard
  const adminDashboardPath = "/admin";

  // Check if the current location is within the admin panel
  const isAdminPanel = location.pathname.startsWith(adminDashboardPath);

  // Define the target URL based on whether it's the admin panel or user panel
  const targetUrl = isAdminPanel ? "/admin" : "/";

  return (
    <div id="#login" className="auth-area">
      <div className="login">
        <div className="logo mt-15">
          <img src={logo} alt="logo" />
        </div>
        <div className="active-area-404">
          <div className="login-card mt-35">
            <img src={Empty} alt="logo" />
            <div className="mt-20 text-center txt-lbl-404">
              Oops! We're Lost in the Digital Forest
            </div>
            <div className="mt-10 text-center txt-lbl-404-sub">
              It appears the page you were seeking has packed up its data and
              embarked on a binary vacation. The URL you've entered is a like a
              forgotten trail, leading nowhere within our web wilderness.
            </div>
            <div className="mt-25 mb-25">
              <Link to={targetUrl}>
                <CButton
                  addClass="primary text-size-20 py-10 full large"
                  mainClass="w-full"
                  type="submit"
                >
                  {isAdminPanel ? "Return to Admin Dashboard" : "Return to Dashboard"}
                </CButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
