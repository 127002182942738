import { ReactComponent as EditImg } from "assets/imgs/create/edit.svg";
import { ReactComponent as DownloadImg } from "assets/imgs/create/download.svg";
import { ReactComponent as HeartImg } from "assets/imgs/create/heart.svg";
import { ReactComponent as HeartFillImg } from "assets/imgs/create/heart_fill.svg";
import { ReactComponent as ViewImg } from "assets/imgs/create/view.svg";
import { ReactComponent as ArrowForward } from "assets/imgs/editor/arrow_forward.svg";
import { ReactComponent as ArrowBack } from "assets/imgs/editor/arrow_back.svg";
import CImage from "components/atoms/CImage";
import { useContext } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import useContent from "hooks/studio/useContent";
import { LICENSE } from "utils/constants";
import { Tooltip } from 'react-tooltip';

import "./index.scss";

const PAGE_SIZE = 4;

const FeedList = ({
  list,
  currentPage,
  onLike,
  selectedImage,
  onSelect,
  onReadMore,
}) => {
  const { showImageModal, showNoti, user } = useContext(LayoutContext);
  const { onImportImage } = useContent();

  const handleEdit = (image) => {
    if (user?.user?.license && !user.user.license.includes(LICENSE.PROFESSIONAL)) {
      showNoti(
        "info",
        `You need to upgrade to a Pro license to unlock this feature.`
      ); return;
    }
    onImportImage(image);
  }

  const handleDownload = (imageUrl, fileName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        showNoti("error", "Error downloading image");
      });
  };

  return (
    <div id="feed-list">
      <div className="images-section mt-25">
        <div onClick={() => onReadMore("back")} className="arrow-action-btn back">
          <ArrowBack />
        </div>
        <div
          onClick={() => onReadMore("forward")}
          className="arrow-action-btn forward"
        >
          <ArrowForward />
        </div>
        {list &&
          list.map((k, index) => {
            const pageMin = PAGE_SIZE * (currentPage - 1);
            const pageMax = PAGE_SIZE * currentPage;
            if (index >= pageMin && index < pageMax) {
              return (
                <div
                  className={`image-card ${selectedImage === k.image ? "active" : ""
                    }`}
                  key={index}
                >
                  <CImage
                    src={k.image}
                    alt={k.seed}
                    onClick={() => onSelect(k)}
                  />
                  <div className="card-action-bar">
                    <div className="first-part">
                      <ViewImg
                        data-tooltip-id={`tip-view-${index}`} data-tooltip-variant="light" data-tooltip-content="View"
                        className="action-btn"
                        onClick={() => showImageModal(k, list)}
                      />
                    </div>
                    <div className="last-part">
                      <EditImg
                        data-tooltip-id={`tip-edit-${index}`} data-tooltip-variant="light" data-tooltip-content="Edit"
                        className="action-btn"
                        onClick={() => handleEdit(k, list)}
                      />
                      <DownloadImg
                        data-tooltip-id={`tip-download-${index}`} data-tooltip-variant="light" data-tooltip-content="Download"
                        className="action-btn"
                        onClick={() => handleDownload(k.image, "download.png")}
                      />
                      {k.liked ? (
                        <div className="ttt">
                          <HeartFillImg
                            data-tooltip-id={`tip-unlike-${index}`} data-tooltip-variant="light" data-tooltip-content="Unlike"
                            className="action-btn"
                            onClick={() => onLike(k.id, k.liked)}
                          />
                          {k.like_ct}
                        </div>
                      ) : (
                        <div className="ttt">
                          <HeartImg
                            data-tooltip-id={`tip-like-${index}`} data-tooltip-variant="light" data-tooltip-content="Like"
                            className="action-btn"
                            onClick={() => onLike(k.id, k.liked)}
                          />
                          {k.like_ct}
                        </div>
                      )}
                    </div>
                  </div>
                  <Tooltip id={`tip-view-${index}`} />
                  <Tooltip id={`tip-edit-${index}`} />
                  <Tooltip id={`tip-download-${index}`} />
                  <Tooltip id={`tip-like-${index}`} />
                  <Tooltip id={`tip-unlike-${index}`} />
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
};

export default FeedList;
