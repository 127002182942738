import React from 'react'
import { Link } from 'react-router-dom'

export default function SocialLinks() {
  return (
    <p className="txt-link-lbl mt-20">
        <span><Link className="link" to="https://creativio.io/LP/privacy-policy.html" target='_blank'>Privacy Policy</Link></span>&nbsp;|&nbsp;
        <span><Link className="link" to="https://creativio.io/LP/terms-of-service.html" target='_blank'>Terms of Services</Link></span>&nbsp;|&nbsp;
        <span><Link className="link" to="https://support.creativio.io/" target='_blank'>Support Desk</Link></span>
    </p>
  )
}
