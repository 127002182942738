const varPlacement = [
  { key: 1, id: "On top of", checked: true, label: "On top of" },
  { key: 2, id: "In front of", checked: false, label: "In front of" },
  { key: 3, id: "Balancing on", checked: false, label: "Balancing on" },
  { key: 4, id: "Emerging from", checked: false, label: "Emerging from" },
  { key: 5, id: "Hiding behind", checked: false, label: "Hiding behind" },
  { key: 6, id: "Suspended above", checked: false, label: "Suspended above" },
  { key: 7, id: "Bathed in", checked: false, label: "Bathed in" },
  { key: 8, id: "Dangling from", checked: false, label: "Dangling from" },
  { key: 9, id: "Anchored on", checked: false, label: "Anchored on" },
  { key: 10, id: "Resting on", checked: false, label: "Resting on" },
  { key: 11, id: "Settled into", checked: false, label: "Settled into" },
  { key: 12, id: "Perched on", checked: false, label: "Perched on" },
  { key: 13, id: "Laid upon", checked: false, label: "Laid upon" },
  { key: 14, id: "Seated on", checked: false, label: "Seated on" },
  { key: 15, id: "Enveloped in", checked: false, label: "Enveloped in" },
  { key: 16, id: "Submerged in", checked: false, label: "Submerged in" },
  { key: 17, id: "Pinned to", checked: false, label: "Pinned to" },
  { key: 18, id: "Attached to", checked: false, label: "Attached to" },
  { key: 19, id: "Strewn across", checked: false, label: "Strewn across" },
  { key: 20, id: "Positioned at", checked: false, label: "Positioned at" },
];
const varBaseTexture = [
  { key: 1, id: "Smooth", checked: true, label: "Smooth" },
  { key: 2, id: "Rough", checked: false, label: "Rough" },
  { key: 3, id: "Soft", checked: false, label: "Soft" },
  { key: 4, id: "Hard", checked: false, label: "Hard" },
  { key: 5, id: "Glossy", checked: false, label: "cube" },
  { key: 6, id: "Matte", checked: false, label: "Matte" },
  { key: 7, id: "Furry", checked: false, label: "Furry" },
  { key: 8, id: "Grained", checked: false, label: "Grained" },
  { key: 9, id: "Metallic", checked: false, label: "Metallic" },
  { key: 10, id: "Glassy", checked: false, label: "Glassy" },
  { key: 11, id: "Wooden", checked: false, label: "Wooden" },
  { key: 12, id: "Fabric", checked: false, label: "Fabric" },
  { key: 13, id: "Fuzzy", checked: false, label: "Fuzzy" },
  { key: 14, id: "Crisp", checked: false, label: "Crisp" },
  { key: 15, id: "Slippery", checked: false, label: "Slippery" },
  { key: 16, id: "Wet", checked: false, label: "Wet" },
  { key: 17, id: "Dry", checked: false, label: "Dry" },
  { key: 18, id: "Icy", checked: false, label: "Icy" },
  { key: 19, id: "Hot", checked: false, label: "Hot" },
  { key: 20, id: "Cold", checked: false, label: "Cold" },
];
const varBase = [
  { key: 1, id: "pedestal", checked: true, label: "pedestal" },
  { key: 2, id: "cube", checked: false, label: "cube" },
  { key: 3, id: "platform", checked: false, label: "platform" },
  { key: 4, id: "table", checked: false, label: "table" },
  { key: 5, id: "chair", checked: false, label: "chair" },
  { key: 6, id: "ground", checked: false, label: "ground" },
  { key: 7, id: "floor", checked: false, label: "floor" },
  { key: 8, id: "wall", checked: false, label: "wall" },
  { key: 9, id: "shelf", checked: false, label: "shelf" },
  { key: 10, id: "desk", checked: false, label: "desk" },
  { key: 11, id: "bed", checked: false, label: "bed" },
  { key: 12, id: "stool", checked: false, label: "stool" },
  { key: 13, id: "mantelpiece", checked: false, label: "mantelpiece" },
  { key: 14, id: "bench", checked: false, label: "bench" },
  { key: 15, id: "tree stump", checked: false, label: "tree stump" },
  { key: 16, id: "rock", checked: false, label: "rock" },
  { key: 17, id: "sand", checked: false, label: "sand" },
  { key: 18, id: "water surface", checked: false, label: "water surface" },
  { key: 19, id: "grass", checked: false, label: "grass" },
  { key: 20, id: "carpet", checked: false, label: "carpet" },
  { key: 21, id: "marble slab", checked: false, label: "marble slab" },
  { key: 22, id: "wooden board", checked: false, label: "wooden board" },
  {
    key: 23,
    id: "concrete surface",
    checked: false,
    label: "concrete surface",
  },
  { key: 24, id: "tile", checked: false, label: "tile" },
  { key: 25, id: "mirror", checked: false, label: "mirror" },
  { key: 26, id: "acrylic surface", checked: false, label: "acrylic surface" },
  { key: 27, id: "metal grid", checked: false, label: "metal grid" },
  { key: 28, id: "fur rug", checked: false, label: "fur rug" },
  { key: 29, id: "leather surface", checked: false, label: "leather surface" },
  { key: 30, id: "glass panel", checked: false, label: "glass panel" },
  { key: 31, id: "rustic wood", checked: false, label: "rustic wood" },
  { key: 32, id: "colored paper", checked: false, label: "colored paper" },
  { key: 33, id: "cloth", checked: false, label: "cloth" },
  { key: 34, id: "chalkboard", checked: false, label: "chalkboard" },
  { key: 35, id: "brick", checked: false, label: "brick" },
  { key: 36, id: "lace", checked: false, label: "lace" },
  { key: 37, id: "silk", checked: false, label: "silk" },
  { key: 38, id: "velvet", checked: false, label: "velvet" },
  { key: 39, id: "jute fabric", checked: false, label: "jute fabric" },
  { key: 40, id: "satin", checked: false, label: "satin" },
  { key: 41, id: "linen", checked: false, label: "linen" },
];
const varSurrounding = [
  { key: 1, id: "With", checked: true, label: "With" },
  { key: 2, id: "Encircled by", checked: false, label: "Encircled by" },
  { key: 3, id: "Amidst", checked: false, label: "Amidst" },
  { key: 4, id: "Within", checked: false, label: "Within" },
  { key: 5, id: "Flanked by", checked: false, label: "Flanked by" },
  { key: 6, id: "Nestled within", checked: false, label: "Nestled within" },
  { key: 7, id: "Scattered around", checked: false, label: "Scattered around" },
  { key: 8, id: "Teeming with", checked: false, label: "Teeming with" },
  { key: 9, id: "Surrounded by", checked: false, label: "Surrounded by" },
  { key: 10, id: "Engulfed in", checked: false, label: "Engulfed in" },
  { key: 11, id: "Enveloped by", checked: false, label: "Enveloped by" },
  { key: 12, id: "Complemented by", checked: false, label: "Complemented by" },
  { key: 13, id: "Accented with", checked: false, label: "Accented with" },
  { key: 14, id: "Contrasted by", checked: false, label: "Contrasted by" },
  { key: 15, id: "Framed by", checked: false, label: "Framed by" },
  { key: 16, id: "Merged with", checked: false, label: "Merged with" },
  { key: 17, id: "Separated from", checked: false, label: "Separated from" },
  { key: 18, id: "Against", checked: false, label: "Against" },
  { key: 19, id: "Among", checked: false, label: "Among" },
  { key: 20, id: "Beside", checked: false, label: "Beside" },
];
const varBackgroundSub = [
  { key: 1, value: "white wall", checked: true, label: "white wall" },
  { key: 2, value: "colored wall", checked: false, label: "colored wall" },
  { key: 3, value: "brick wall", checked: false, label: "brick wall" },
  { key: 4, value: "concrete wall", checked: false, label: "concrete wall" },
  { key: 5, value: "wooden wall", checked: false, label: "wooden wall" },
  {
    key: 6,
    value: "patterned wallpaper",
    checked: false,
    label: "patterned wallpaper",
  },
  {
    key: 7,
    value: "gradient backdrop",
    checked: false,
    label: "gradient backdrop",
  },
  { key: 8, value: "curtain", checked: false, label: "curtain" },
  { key: 9, value: "window view", checked: false, label: "window view" },
  { key: 10, value: "cityscape", checked: false, label: "cityscape" },
  { key: 11, value: "forest", checked: false, label: "forest" },
  { key: 12, value: "beach", checked: false, label: "beach" },
  { key: 13, value: "mountain range", checked: false, label: "mountain range" },
  { key: 14, value: "garden", checked: false, label: "garden" },
  { key: 15, value: "desert", checked: false, label: "desert" },
  {
    key: 16,
    value: "field of flowers",
    checked: false,
    label: "field of flowers",
  },
  {
    key: 17,
    value: "snowy landscape",
    checked: false,
    label: "snowy landscape",
  },
  { key: 18, value: "cloudy sky", checked: false, label: "cloudy sky" },
  { key: 19, value: "sunset", checked: false, label: "sunset" },
  { key: 20, value: "sunrise", checked: false, label: "sunrise" },
  { key: 21, value: "galaxy", checked: false, label: "galaxy" },
  {
    key: 22,
    value: "sparkling lights",
    checked: false,
    label: "sparkling lights",
  },
  { key: 23, value: "bokeh lights", checked: false, label: "bokeh lights" },
  { key: 24, value: "plain color", checked: false, label: "plain color" },
  {
    key: 25,
    value: "textured fabric",
    checked: false,
    label: "textured fabric",
  },
  {
    key: 26,
    value: "metallic surface",
    checked: false,
    label: "metallic surface",
  },
  { key: 27, value: "chalkboard", checked: false, label: "chalkboard" },
  { key: 28, value: "mirror", checked: false, label: "mirror" },
  { key: 29, value: "rustic wood", checked: false, label: "rustic wood" },
  { key: 30, value: "marble surface", checked: false, label: "marble surface" },
  { key: 31, value: "grungy wall", checked: false, label: "grungy wall" },
  { key: 32, value: "paper cutouts", checked: false, label: "paper cutouts" },
  {
    key: 33,
    value: "collage of images",
    checked: false,
    label: "collage of images",
  },
  { key: 34, value: "colored smoke", checked: false, label: "colored smoke" },
  { key: 35, value: "neon lights", checked: false, label: "neon lights" },
  {
    key: 36,
    value: "floral arrangement",
    checked: false,
    label: "floral arrangement",
  },
  {
    key: 37,
    value: "abstract painting",
    checked: false,
    label: "abstract painting",
  },
  { key: 38, value: "bookshelf", checked: false, label: "bookshelf" },
  { key: 39, value: "old map", checked: false, label: "old map" },
  {
    key: 40,
    value: "musical notes sheet",
    checked: false,
    label: "musical notes sheet",
  },
  { key: 41, value: "vintage poster", checked: false, label: "vintage poster" },
  { key: 42, value: "graffiti", checked: false, label: "graffiti" },
];
const varBackground = [
  { key: 1, id: "In the midst of", label: "In the midst of", checked: true },
  { key: 2, id: "Against", label: "Against", checked: false },
  { key: 3, id: "Framed by", label: "Framed by", checked: false },
  { key: 4, id: "Overlooking", label: "Overlooking", checked: false },
  { key: 5, id: "Underneath", label: "Underneath", checked: false },
  { key: 6, id: "Shrouded by", label: "Shrouded by", checked: false },
  { key: 7, id: "Saturated with", label: "Saturated with", checked: false },
  { key: 8, id: "Silhouetted by", label: "Silhouetted by", checked: false },
  { key: 9, id: "Blanketed in", label: "Blanketed in", checked: false },
  { key: 10, id: "Echoing", label: "Echoing", checked: false },
  {
    key: 11,
    id: "Highlighted against",
    label: "Highlighted against",
    checked: false,
  },
  { key: 12, id: "Set against", label: "Set against", checked: false },
  { key: 13, id: "Looming over", label: "Looming over", checked: false },
  { key: 14, id: "Backdropped by", label: "Backdropped by", checked: false },
  { key: 15, id: "Mirrored in", label: "Mirrored in", checked: false },
  { key: 16, id: "Reflected on", label: "Reflected on", checked: false },
  { key: 17, id: "Shadowed by", label: "Shadowed by", checked: false },
  { key: 18, id: "Projected onto", label: "Projected onto", checked: false },
  { key: 19, id: "Embedded in", label: "Embedded in", checked: false },
  { key: 20, id: "Superimposed on", label: "Superimposed on", checked: false },
];
const varVariation = [
  { key: 1, id: 1, checked: true, label: 1 },
  { key: 2, id: 2, checked: false, label: 2 },
  { key: 3, id: 3, checked: false, label: 3 },
  { key: 4, id: 4, checked: false, label: 4 },
];
const varRender = [
  { key: 1, id: "30", checked: true, label: "Weak" },
  { key: 2, id: "40", checked: false, label: "Strong" },
  { key: 3, id: "50", checked: false, label: "Very strong" },
];
const varPrompt = [
  { key: 1, id: "7", checked: true, label: "Weak" },
  { key: 2, id: "12", checked: false, label: "Strong" },
  { key: 3, id: "20", checked: false, label: "Very strong" },
];
const varLightning = [
  { key: 0, id: "", checked: true, label: "--Select Lighting--" },
  { key: 1, id: "Natural light", checked: false, label: "Natural light" },
  { key: 2, id: "Soft light", checked: false, label: "Soft light" },
  { key: 3, id: "Hard light", checked: false, label: "Hard light" },
  { key: 4, id: "Backlight", checked: false, label: "Backlight" },
  { key: 5, id: "Side light", checked: false, label: "Side light" },
  { key: 6, id: "Fill light", checked: false, label: "Fill light" },
  { key: 7, id: "Spotlight", checked: false, label: "Spotlight" },
  { key: 8, id: "Ambient light", checked: false, label: "Ambient light" },
  { key: 9, id: "Diffused light", checked: false, label: "Diffused light" },
  { key: 10, id: "Studio lighting", checked: false, label: "Studio lighting" },
  { key: 11, id: "Warm light", checked: false, label: "Warm light" },
  { key: 12, id: "Cool light", checked: false, label: "Cool light" },
  { key: 13, id: "Shadowed", checked: false, label: "Shadowed" },
  { key: 14, id: "Highlighted", checked: false, label: "Highlighted" },
  { key: 15, id: "Silhouette", checked: false, label: "Silhouette" },
  { key: 16, id: "Glowing", checked: false, label: "Glowing" },
  { key: 17, id: "Neon", checked: false, label: "Neon" },
  { key: 18, id: "Twinkling", checked: false, label: "Twinkling" },
  { key: 19, id: "Reflection", checked: false, label: "Reflection" },
  { key: 20, id: "Candlelight", checked: false, label: "Candlelight" },
];

const varColors = [
  { key: 0, id: "", checked: true, label: "--Select Colors--" },
  { key: 1, id: "Monochrome", checked: false, label: "Monochrome" },
  { key: 2, id: "Complementary", checked: false, label: "Complementary" },
  { key: 3, id: "Analogous", checked: false, label: "Analogous" },
  { key: 4, id: "Triadic", checked: false, label: "Triadic" },
  {
    key: 5,
    id: "Split-complementary",
    checked: false,
    label: "Split-complementary",
  },
  { key: 6, id: "Square", checked: false, label: "Square" },
  { key: 7, id: "Tetradic", checked: false, label: "Tetradic" },
  { key: 8, id: "Neutral", checked: false, label: "Neutral" },
  { key: 9, id: "Pastel", checked: false, label: "Pastel" },
  { key: 10, id: "Vibrant", checked: false, label: "Vibrant" },
  { key: 11, id: "Warm", checked: false, label: "Warm" },
  { key: 12, id: "Cool", checked: false, label: "Cool" },
  { key: 13, id: "Black and white", checked: false, label: "Black and white" },
  { key: 14, id: "Sepia", checked: false, label: "Sepia" },
  { key: 15, id: "Primary", checked: false, label: "Primary" },
  { key: 16, id: "Secondary", checked: false, label: "Secondary" },
  { key: 17, id: "Tertiary", checked: false, label: "Tertiary" },
  { key: 18, id: "Earth tones", checked: false, label: "Earth tones" },
  { key: 19, id: "Metallic", checked: false, label: "Metallic" },
  { key: 20, id: "Rainbow", checked: false, label: "Rainbow" },
];

const varComposition = [
  { key: 0, id: "", checked: true, label: "--Select Composition--" },
  { key: 1, id: "Rule of thirds", checked: false, label: "Rule of thirds" },
  { key: 2, id: "Symmetry", checked: false, label: "Symmetry" },
  { key: 3, id: "Patterns", checked: false, label: "Patterns" },
  { key: 4, id: "Depth of field", checked: false, label: "Depth of field" },
  { key: 5, id: "Negative space", checked: false, label: "Negative space" },
  { key: 6, id: "Leading lines", checked: false, label: "Leading lines" },
  {
    key: 7,
    id: "Frame within a frame",
    checked: false,
    label: "Frame within a frame",
  },
  { key: 8, id: "Golden ratio", checked: false, label: "Golden ratio" },
  { key: 9, id: "Diagonals", checked: false, label: "Diagonals" },
  { key: 10, id: "Triangles", checked: false, label: "Triangles" },
  {
    key: 11,
    id: "Centered composition",
    checked: false,
    label: "Centered composition",
  },
  { key: 12, id: "Isolation", checked: false, label: "Isolation" },
  { key: 13, id: "Balance", checked: false, label: "Balance" },
  { key: 14, id: "Contrast", checked: false, label: "Contrast" },
  { key: 15, id: "Harmony", checked: false, label: "Harmony" },
  { key: 16, id: "Rhythm", checked: false, label: "Rhythm" },
  { key: 17, id: "Repetition", checked: false, label: "Repetition" },
  { key: 18, id: "Proportion", checked: false, label: "Proportion" },
  { key: 19, id: "Unity", checked: false, label: "Unity" },
  { key: 20, id: "Emphasis", checked: false, label: "Emphasis" },
];
const varCameraAngles = [
  { key: 0, id: "", checked: true, label: "--Select Camera Angles--" },
  { key: 1, id: "Eye level", checked: false, label: "Eye level" },
  { key: 2, id: "High angle", checked: false, label: "High angle" },
  { key: 3, id: "Low angle", checked: false, label: "Low angle" },
  { key: 4, id: "Dutch angle", checked: false, label: "Dutch angle" },
  { key: 5, id: "Bird's eye view", checked: false, label: "Bird's eye view" },
  { key: 6, id: "Worm's eye view", checked: false, label: "Worm's eye view" },
  {
    key: 7,
    id: "Over-the-shoulder",
    checked: false,
    label: "Over-the-shoulder",
  },
  { key: 8, id: "Close-up", checked: false, label: "Close-up" },
  { key: 9, id: "Macro", checked: false, label: "Macro" },
  { key: 10, id: "Wide shot", checked: false, label: "Wide shot" },
  { key: 11, id: "Tilted", checked: false, label: "Tilted" },
  { key: 12, id: "Aerial", checked: false, label: "Aerial" },
  { key: 13, id: "Ground level", checked: false, label: "Ground level" },
  { key: 14, id: "Diagonal", checked: false, label: "Diagonal" },
  { key: 15, id: "Horizontal", checked: false, label: "Horizontal" },
  { key: 16, id: "Vertical", checked: false, label: "Vertical" },
  { key: 17, id: "Skewed", checked: false, label: "Skewed" },
  { key: 18, id: "Fish eye", checked: false, label: "Fish eye" },
  { key: 19, id: "Panoramic", checked: false, label: "Panoramic" },
  { key: 20, id: "Tilt-shift", checked: false, label: "Tilt-shift" },
];

const varMood = [
  { key: 0, id: "", checked: true, label: "--Select Mood--" },
  { key: 1, id: "Minimalist", checked: false, label: "Minimalist" },
  { key: 2, id: "Vintage", checked: false, label: "Vintage" },
  { key: 3, id: "Luxury", checked: false, label: "Luxury" },
  { key: 4, id: "Rustic", checked: false, label: "Rustic" },
  { key: 5, id: "Modern", checked: false, label: "Modern" },
  { key: 6, id: "Whimsical", checked: false, label: "Whimsical" },
  { key: 7, id: "Dramatic", checked: false, label: "Dramatic" },
  { key: 8, id: "Peaceful", checked: false, label: "Peaceful" },
  { key: 9, id: "Peaceful", checked: false, label: "Peaceful" },
  { key: 10, id: "Dynamic", checked: false, label: "Dynamic" },
  { key: 11, id: "Romantic", checked: false, label: "Romantic" },
  { key: 12, id: "Mysterious", checked: false, label: "Mysterious" },
  { key: 13, id: "Festive", checked: false, label: "Festive" },
  { key: 14, id: "Surreal", checked: false, label: "Surreal" },
  { key: 15, id: "Gothic", checked: false, label: "Gothic" },
  { key: 16, id: "Retro", checked: false, label: "Retro" },
  { key: 17, id: "Futuristic", checked: false, label: "Futuristic" },
  { key: 18, id: "Industrial", checked: false, label: "Industrial" },
  { key: 19, id: "Bohemian", checked: false, label: "Bohemian" },
  { key: 20, id: "Nautical", checked: false, label: "Nautical" },
  { key: 21, id: "Glamorous", checked: false, label: "Glamorous" },
];

const varStyle = [
  { key: 1, id: "3d-model", checked: false, label: "3d model" },
  { key: 2, id: "analog-film", checked: false, label: "analog film" },
  { key: 3, id: "anime", checked: false, label: "anime" },
  { key: 4, id: "cinematic", checked: false, label: "cinematic" },
  { key: 5, id: "comic-book", checked: false, label: "comic book" },
  { key: 6, id: "digital-art", checked: false, label: "digital art" },
  { key: 7, id: "enhance", checked: false, label: "enhance" },
  { key: 8, id: "fantasy-art", checked: false, label: "fantasy art" },
  { key: 9, id: "isometric", checked: false, label: "isometric" },
  { key: 10, id: "line-art", checked: false, label: "line art" },
  { key: 11, id: "low-poly", checked: false, label: "low poly" },
  {
    key: 12,
    id: "modeling-compound",
    checked: false,
    label: "modeling compound",
  },
  { key: 13, id: "neon-punk", checked: false, label: "neon punk" },
  { key: 14, id: "origami", checked: false, label: "origami" },
  { key: 15, id: "photographic", checked: true, label: "photographic" },
  { key: 16, id: "pixel-art", checked: false, label: "pixel art" },
  { key: 17, id: "tile-texture", checked: false, label: "tile texture" },
];
const varCustomStyle = [
  { key: 0, id: "", checked: true, label: "--Select Style--" },
  { key: 1, id: "Natural", checked: false, label: "Natural" },
  { key: 2, id: "Studio", checked: false, label: "Studio" },
  { key: 3, id: "Lifestyle", checked: false, label: "Lifestyle" },
  { key: 4, id: "Flat lay", checked: false, label: "Flat lay" },
  { key: 5, id: "Hero shots", checked: false, label: "Hero shots" },
  { key: 6, id: "Abstract", checked: false, label: "Abstract" },
  { key: 7, id: "Macro", checked: false, label: "Macro" },
  { key: 8, id: "Scale shots", checked: false, label: "Scale shots" },
  { key: 9, id: "Detail shots", checked: false, label: "Detail shots" },
  { key: 10, id: "Group shots", checked: false, label: "Group shots" },
  { key: 11, id: "Action shots", checked: false, label: "Action shots" },
  { key: 12, id: "360 shots", checked: false, label: "360 shots" },
  { key: 13, id: "Documentary", checked: false, label: "Documentary" },
  { key: 14, id: "Fine art", checked: false, label: "Fine art" },
  { key: 15, id: "Commercial", checked: false, label: "Commercial" },
  { key: 16, id: "Editorial", checked: false, label: "Editorial" },
  { key: 17, id: "Candid", checked: false, label: "Candid" },
  { key: 18, id: "Conceptual", checked: false, label: "Conceptual" },
  { key: 19, id: "Environmental", checked: false, label: "Environmental" },
  { key: 20, id: "Fashion", checked: false, label: "Fashion" },
];

export {
  varPlacement,
  varBase,
  varBaseTexture,
  varSurrounding,
  varBackground,
  varBackgroundSub,
  varVariation,
  varRender,
  varPrompt,
  varLightning,
  varColors,
  varComposition,
  varCameraAngles,
  varMood,
  varStyle,
  varCustomStyle,
};
