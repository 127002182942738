import {
  BACKGROUND_CANVAS,
  MAIN_OBJECT_FILL_COLOR,
  MAIN_OBJECT_FILL_RECT,
} from "constants/studio";
import useStudioCreator from "hooks/studio/useStudioCreator";
import { useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setBackgroundCanvasRef } from "store/slices/studio";

const BackgroundCanvas = () => {
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const {
    onMouseDown,
    onMouseUp,
    onMouseMove,
    cursorStyle,
    onDoubleClick,
    onMouseLeave,
  } = useStudioCreator();

  const style = useMemo(() => ({ cursor: cursorStyle }), [cursorStyle]);

  useEffect(() => {
    if (!canvasRef.current) return;

    dispatch(setBackgroundCanvasRef(canvasRef));
  }, [canvasRef, dispatch]);

  return (
    <>
      <canvas
        className="studio-canvas__canvas"
        ref={canvasRef}
        style={style}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onDoubleClick={onDoubleClick}
        id={BACKGROUND_CANVAS}
      />
      <div
        id={MAIN_OBJECT_FILL_RECT}
        style={{ backgroundColor: MAIN_OBJECT_FILL_COLOR }}
        className="studio-canvas__main-object"
      />
    </>
  );
};

export default BackgroundCanvas;
