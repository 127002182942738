import { useState, useRef, useEffect } from "react";
import { ReactComponent as ArrowDown } from "assets/imgs/dropdown/arrow_down.svg";
import "./styles.scss";
import ProLabel from "components/molecules/ProLabel";

export function CDropdown({
  id, // "ratio_option"
  addClass = "",
  action,
  showIcon = false,
  activeMenu, // {key: 1, id: "square", icon: Square, color: 'white', checked: false, label: "Square (1:1)"},
  menu, // array [{key: 1, id: "square", checked: false, label: "Square (1:1)"}],
  disabled,
  proLabel = false,
}) {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (disabled) return;
    setOpen(!open);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <div
      className={`custom-dropdown ${addClass} ${disabled ? "disabled" : ""} ${open ? "open" : ""
        }`}
      onClick={handleOpen}
      ref={dropdownRef}
    >
      <ArrowDown className="arrow-down" />
      {activeMenu ? (
        <div className="active-item item" onClick={() => setOpen(!open)}>
          {showIcon && (
            <div className="img-wrap">
              <img src={activeMenu.icon} alt={activeMenu.value} />
            </div>
          )}
          {activeMenu.label}
        </div>
      ) : (
        <div className="active-item item">{proLabel && <ProLabel />}&nbsp;</div>
      )}

      {open ? (
        <div className="dropdown">
          {menu.length > 0 &&
            menu.map((item, index) => {
              if (item.key === activeMenu.key) return null;
              else
                return (
                  <div key={item.key} className="dropdown-item" onClick={() => action(id, index, !item.checked)}>
                    <div className="item">
                      {showIcon && (
                        <div className="img-wrap">
                          <img src={item.icon} alt={item.value} />
                        </div>
                      )}
                      {item.label}
                    </div>
                  </div>
                );
            })}
        </div>
      ) : null}
    </div>
  );
}
