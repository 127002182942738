import { CButton } from "components/atoms/CButton";
import { useContext, useState } from "react";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import "./styles.scss"
import logo from 'assets/imgs/logo.svg';
import {ReactComponent as Man} from 'assets/imgs/auth/people.svg';
import SocialLinks from "./SocialLinks";


export function ForgotPassword() {
    const { setLoading, showNoti } = useContext(LayoutContext)
    const [email, setEmail] = useState("");
    const [isSent, setIsSent] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const email = event.target.email.value;
        setLoading(true)
        try {
            const data = await getApi('/forgot-password', 'POST', { email })
            setIsSent(true)
        } catch (err) {
            showNoti('error', err)
        }
        setLoading(false)
    }

    return (
        <div id="#login" className="auth-area">
            <div className="login">
                <div className='logo mt-15'>
                    <img src={logo} alt='logo' />
                </div>
                <div className="active-area">
                    {!isSent && <form onSubmit={handleSubmit}>
                        <div className="login-card mt-35">
                            <div className="form-group">
                                <div className="input-with-icon">
                                    <Man className="icon-img" />
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mt-20 mb-60">
                                <CButton addClass='primary text-size-20 py-10 full large' mainClass="w-full" type="submit">Forgot Password</CButton>
                            </div>
                        </div>
                    </form>}
                    {isSent && <div className="my-60 text-center">
                            Reset password link sent to your email. Please check your inbox.
                        </div>}
                </div>
                <SocialLinks />
            </div>
        </div>
    )
}