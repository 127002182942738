import _ from "lodash";

export const onCloneStudio = ({ layers, ...state }) => {
  let newLayers = [];

  for (let layer of layers) {
    newLayers.push(layer.clone());
  }

  return { ..._.cloneDeep(state), layers: newLayers };
};
