import { LICENSE } from "./constants";
import { getLicense } from "./util";

const getUpgradeLicense = (user) => {
  const license = getLicense(user);

  if (!Array.isArray(license))
    return null;

  if (license.length === 1 && license[0] === LICENSE.FREE_TRIAL)
    return LICENSE.BUSINESS;

  if (license.includes(LICENSE.STARTER) && !license.includes(LICENSE.PROFESSIONAL))
    return LICENSE.PROFESSIONAL;

  if (license.includes(LICENSE.BUSINESS) && !license.includes(LICENSE.PROFESSIONAL))
    return LICENSE.PROFESSIONAL;

  if (license.includes(LICENSE.PROFESSIONAL) && !license.includes(LICENSE.ANIMATION))
    return LICENSE.ANIMATION;

  if (license.includes(LICENSE.PROFESSIONAL) && license.includes(LICENSE.ANIMATION) && !license.includes(LICENSE.AGENCY))
    return LICENSE.AGENCY;

  if (license.includes(LICENSE.BUSINESS) && license.includes(LICENSE.ANIMATION) && !license.includes(LICENSE.AGENCY))
    return LICENSE.AGENCY;

  if (license.includes(LICENSE.BUSINESS) && license.includes(LICENSE.AGENCY) && !license.includes(LICENSE.ANIMATION))
    return LICENSE.ANIMATION;

  if (license.includes(LICENSE.BUSINESS) && license.includes(LICENSE.ANIMATION) && license.includes(LICENSE.AGENCY) && !license.includes(LICENSE.PROFESSIONAL))
    return LICENSE.PROFESSIONAL;

  if (license.includes(LICENSE.ANIMATION) && !license.includes(LICENSE.PROFESSIONAL))
    return LICENSE.PROFESSIONAL;

  if (license.includes(LICENSE.AGENCY) && !license.includes(LICENSE.PROFESSIONAL))
    return LICENSE.PROFESSIONAL;

  return null;

  /* Old Logic */
  // switch (license) {
  //   case LICENSE.FREE_TRIAL:
  //     return LICENSE.BUSINESS;
  //   case LICENSE.BUSINESS:
  //   case LICENSE.STARTER:
  //     return LICENSE.PROFESSIONAL;
  //   default:
  //     return null;
  // }
};

export const getNextLicenses = (user) => {
  const license = getLicense(user);

  switch (license) {
    case LICENSE.FREE_TRIAL:
      return [LICENSE.BUSINESS, LICENSE.PROFESSIONAL];
    case LICENSE.BUSINESS:
    case LICENSE.STARTER:
      return [LICENSE.PROFESSIONAL];
    default:
      return null;
  }
}

export default getUpgradeLicense;
