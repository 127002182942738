import { DRAW_OBJECT_TYPES } from "constants/studio";
import ShapeObject from "./ShapeObject";

class RectObject extends ShapeObject {
  rotation = 0;
  data = {};

  constructor({
    position = 0,
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    rotation = 0,
    canvas = null,
    data = {},
    meta = {},
    type = DRAW_OBJECT_TYPES.rect,
  }) {
    super({
      type,
      x,
      y,
      width,
      height,
      meta,
      position,
      rotation,
      canvas,
    });
    this.data = data;
  }

  draw({ scale, offsetX, offsetY }) {
    const ctx = this.getContext();

    if (!ctx) return;
    ctx.save();

    const rectCoordinates = this.getCenterCoordinates({
      offsetX,
      offsetY,
      scale,
    });

    ctx.fillStyle = this.data.fillStyle;
    ctx.strokeStyle = this.data.strokeStyle;
    ctx.lineWidth = this.data.lineWidth;

    ctx.setTransform(...rectCoordinates.matrix);

    ctx.beginPath();

    ctx.rect(
      rectCoordinates.originX,
      rectCoordinates.originY,
      rectCoordinates.width,
      rectCoordinates.height
    );

    if (this.meta.fill) ctx.fill();

    if (this.meta.stroke) {
      ctx.stroke();
    }

    ctx.restore();
  }
}

export default RectObject;
