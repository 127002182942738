import { ReactComponent as VideoSvg } from "../../images/video.svg";
import { ReactComponent as CloseSvg } from "../../images/close.svg";

import "./index.scss";
import { Link } from "react-router-dom";

const VideoBlock = ({ onClose, name }) => {
  return (
    <div className="video-block">
      <button className="pro-plan__close-btn" onClick={onClose}>
        <CloseSvg />
      </button>
      <div className="video-block__content">
        <h2 className="video-block__title">Welcome To Creativio AI, {name}!</h2>
        <p className="video-block__subtitle">
          Your Account Setup is completed.To get started, watch the video
          trainings to kick-start your journey.
        </p>
      </div>
      <div className="video-block__video-block">
        <VideoSvg />
        <Link to="/video-training">
          <span className="video-block__button">Watch Video</span>
        </Link>
      </div>
    </div>
  );
};

export default VideoBlock;
