import { ReactComponent as TextToImageSvg } from "./images/textToImage.svg";
import { ReactComponent as ProductPhotoshootSvg } from "./images/productPhotoshoot.svg";
import { ReactComponent as RemoveBackgroundSvg } from "./images/removeBackground.svg";
import { ReactComponent as MagicRemoverSvg } from "./images/magicRemover.svg";
import { ReactComponent as CreditsSvg } from "./images/credits.svg";
import { ReactComponent as DesignSvg } from "./images/design.svg";
import { ReactComponent as ToolsSvg } from "./images/advanced.svg";

export const AI_TOOLS = [
  {
    Image: <TextToImageSvg />,
    title: "Text To Image",
    subtitle: "Scene Generation",
    link: "/create/text-to-image",
  },
  {
    Image: <ProductPhotoshootSvg />,
    title: "Product Photoshoot",
    subtitle: "Setup & Enhance",
    link: "/create/photoshoot",
  },
  {
    Image: <RemoveBackgroundSvg />,
    title: "Remove Background",
    subtitle: "AI Clean .png Results",
    link: "/magictool/ai-background-remover",
  },
  {
    Image: <MagicRemoverSvg />,
    title: "Magic Remover",
    subtitle: "AI Remove Objects",
    link: "/magictool/magic-remover",
  },
];

export const OPTIONS = [
  {
    key: 1,
    id: "image-to-image",
    icon: null,
    color: "white",
    checked: false,
    label: "Product Photoshoot",
  },
  {
    key: 2,
    id: "all",
    icon: null,
    color: "white",
    checked: true,
    label: "All Images",
  },
  {
    key: 3,
    id: "image-animation",
    icon: null,
    color: "white",
    checked: false,
    label: "Image Animations",
  },
  {
    key: 4,
    id: "upscale",
    icon: null,
    color: "white",
    checked: false,
    label: "Image Upscale",
  },
];

export const PRO_ITEMS = [
  {
    title: "Unlimited Credits",
    Icon: <CreditsSvg />,
  },
  {
    title: "AI Design Studio",
    Icon: <DesignSvg />,
  },
  {
    title: "Advanced Tools",
    Icon: <ToolsSvg />,
  },
];
