import "./index.scss";

const CRadio = ({ checked, label, value, name, onChange, disabled }) => {
  return (
    <label className="custom-radio">
      <input
        type="radio"
        name={name}
        onChange={onChange}
        checked={checked}
        value={value}
        disabled={disabled}
        hidden
      />
      <span className="custom-radio__span">{label}</span>
    </label>
  );
};

export default CRadio;
