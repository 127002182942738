import useTable from "hooks/useTable";
import { useState } from "react";
import Action from "./Action";

const AdminTable = ({
  headers = [],
  data = [],
  rowsPerPage = 10,
  actions = [],
  style = {}
}) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  const getRowValue = (header, row) => {
    const format = header.formatter;
    const value = row[header.value];
    if (format) {
      return format(value);
    }
    return value;
  };

  return (
    <div className="custom-table mt-25 mb-60" >
      <table style={style}>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="tableHeader">{header.label}</th>
            ))}
            {!!actions.length && <th className="tableHeader" key="actions">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {slice.length === 0 ? (
            <tr>
              <td colSpan={headers.length + (actions.length > 0 ? 1 : 0)} className="tableCell text-center">
                No records found
              </td>
            </tr>
          ) : (
            slice.map((row) => (
              <tr key={row.id}>
                {headers.map((header, index) => (
                  <td key={index} className="tableCell">{getRowValue(header, row) || ""}</td>
                ))}
                {!!actions.length && (
                  <td key="action" className="tableCell tableCellActions">
                    {actions.map((action, index) => (
                      <Action key={index} action={action} row={row} />
                    ))}
                  </td>
                )}
              </tr>
            )))}
        </tbody>
      </table>
      {range.length > 0 && (
        <div className="pagination mb-60 mt-10">
          <button
            onClick={() => setPage(1)}
            disabled={page === 1 || range.length === 1}
          >
            {"<<"}
          </button>
          <button
            onClick={() => setPage(page - 1)}
            disabled={page === 1 || range.length === 1}
          >
            {"<"}
          </button>
          <span>
            Page{" "}
            <strong>
              {page} of {range.length}
            </strong>{" "}
          </span>
          <button
            onClick={() => setPage(page + 1)}
            disabled={range.length === 1 || page === range.length}
          >
            {">"}
          </button>
          <button
            onClick={() => setPage(range.length)}
            disabled={page === range.length}
          >
            {">>"}
          </button>
        </div>
      )}
    </div>
  );
};

export default AdminTable;
