const Action = ({ action, row }) => {
  return (
    <>
      {action.type === "button" && (
        <button className="table-action" onClick={() => action.onClick(row)}>
          {action.icon} {action.text}
        </button>
      )}
    </>
  );
};

export default Action;
