import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import "./brush.scss";
// import customCursorImage from 'assets/imgs/editor/cursor.svg';
import { ReactComponent as ExitSvg } from "assets/imgs/studioCreator/exit.svg";
import { ReactComponent as DownloadImg } from "assets/imgs/create/download.svg";
import { ReactComponent as IcoUndo } from "assets/imgs/editor/undo.svg";
import { ReactComponent as IcoRedo } from "assets/imgs/editor/redo.svg";
import { ReactComponent as IcoEye } from "assets/imgs/editor/eye.svg";
import { ReactComponent as IcoEyeHide } from "assets/imgs/editor/eye_hide.svg";
import { ReactComponent as IcoBrushAdd } from "assets/imgs/editor/brush_add.svg";
import { ReactComponent as IcoBrushSub } from "assets/imgs/editor/brush_substract.svg";
import { ReactComponent as IcoRect } from "assets/imgs/editor/rectangle.svg";
import { ReactComponent as IcoSetting } from "assets/imgs/editor/setting.svg";
import {
  createNewCanvas,
  drawBrush,
  drawCorners,
  drawCursor,
  drawRect,
  erase,
  getCursorTypeInsideLastRect,
  getMousePos,
  resizeOrMoveRect,
} from "./canvasUtil";
import { download } from "utils/imageUtil";
import { Slide } from "react-awesome-reveal";

const defaultBrushSize = 20;
const BrushCanvas = forwardRef(
  (
    {
      history = {
        layers: [],
        backupLayers: [],
        setLayers: () => { },
        setBackupLayers: () => { },
        redoStep: 0,
        setRedoStep: () => { }
      },
      mainCaption = "",
      subCaption = "",
      orgImage = {
        src: null,
        width: 768,
        height: 768
      },
      image = {
        src: null,
        width: 768,
        height: 768
      },
      maskType = "black",
      onRemove = () => { },
      onChange = () => { },
      onExit = null
    },
    ref
  ) => {
    const { layers, setLayers, backupLayers, setBackupLayers, redoStep, setRedoStep } = history;

    const canvasRef = useRef(null);
    // const [ctx, setCtx] = useState(null);
    const cursorCanvasRef = useRef(null);

    const [brushSize, setBrushSize] = useState(defaultBrushSize);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const canvasWidth = 768;
    const canvasHeight = 768;

    const [activeStyle, setActiveStyle] = useState("brush");
    const [showSetting, setShowSetting] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const [cursorInsideRect, setCursorInsideRect] = useState(null);
    const [prevMovePoint, setPrevMovePoint] = useState();
    const [showOriginImg, setShowOriginImg] = useState(false);
    const [currentImage, setCurrentImage] = useState(image.src);

    const getContext = () => {
      if (!canvasRef) return null;

      const ctx = canvasRef.current.getContext('2d');
      return ctx
    }

    const drawAllLayers = (allLayers, showCorner = true) => {
      const ctx = getContext()
      if (!ctx) return;

      const canvas = canvasRef.current;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      allLayers.forEach((layer) => {
        if (layer.type === "eraser") {
          erase(ctx, layer.data, layer.brushSize);
        } else {
          if (layer.type === "brush") {
            ctx.globalCompositeOperation = "xor";
            ctx.drawImage(layer.canvas, 0, 0, canvas.width, canvas.height);
            ctx.globalCompositeOperation = "source-over";
          } else if (layer.canvas) {
            if (layer.type === 'rect') {
              ctx.globalCompositeOperation = "xor";
              ctx.drawImage(layer.canvas, 0, 0, canvas.width, canvas.height);
              ctx.globalCompositeOperation = "source-over";
            } else {
              ctx.drawImage(layer.canvas, 0, 0, canvas.width, canvas.height);
            }
          }
        }
        setCurrentImage(layer.imageSrc)
      });
      if (showCorner) {
        // draw corner points
        const lastLayer = allLayers.length
          ? allLayers[allLayers.length - 1]
          : null;
        if (
          lastLayer &&
          lastLayer.type === "rect" &&
          lastLayer.data.length > 1
        ) {
          // draw bounds
          const { x, y } = lastLayer.data[0];
          const { x: x1, y: y1 } = lastLayer.data[1];
          drawCorners(ctx, x, y, x1, y1);
        }
      }
    };

    useEffect(() => {
      onChange(layers.length);
    }, [layers]);

    useEffect(() => {
      if (image.src !== currentImage) {
        setCurrentImage(image.src)
      }
    }, [image.src])

    useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      setImageLoaded(true);
      drawAllLayers(layers);

      const scaleFactor = 1;
      canvas.style.transform = `scale(${scaleFactor})`;
    }, [canvasRef]);

    const onRedo = () => {
      if (redoStep > 0) {
        const lastBackupLayer = backupLayers[redoStep - 1];
        drawAllLayers([...layers, lastBackupLayer]);
        setLayers([...layers, lastBackupLayer]);
        setBackupLayers(backupLayers.slice(0, redoStep - 1));
        setRedoStep(redoStep - 1);
      }
    };

    const onUndo = () => {
      if (layers.length > 0) {
        const lastLayer = layers[layers.length - 1];
        const tempBackupLayers = [...backupLayers, lastLayer];
        setBackupLayers(tempBackupLayers);
        setLayers(layers.slice(0, layers.length - 1));
        setRedoStep(redoStep + 1);
        drawAllLayers(layers.slice(0, layers.length - 1));
      }
    };

    const clearBackupLayers = () => {
      setRedoStep(0);
      setBackupLayers([]);
    };

    const onShowResultImg = () => {
      if (showOriginImg) {
        // animation from right to left
      } else {
        // animation from left to right
      }
      setShowOriginImg(!showOriginImg);
    };

    const handleBrushSizeChange = (event) => {
      setBrushSize(event.target.value);
    };

    const handleCanvasMouseLeave = (event) => {
      const ctx = cursorCanvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      cursorCanvasRef.current.style.cursor = "none";
      setIsDrawing(false);
    };

    const handleCanvasMouseDown = (event) => {
      const ctx = getContext()

      if (!imageLoaded || !ctx) return;
      setIsDrawing(true);

      if (cursorInsideRect) {
        // movement or resize
        setIsMoving(true);
        const { x, y } = getMousePos(canvasRef.current, event);
        setPrevMovePoint({ x, y });
        return;
      }
      // when mouse down, set the starting point
      const canvas = canvasRef.current;
      const { x, y } = getMousePos(canvas, event);
      const newCanvas = createNewCanvas(canvas.width, canvas.height);
      const newCurLayer = {
        canvas: newCanvas,
        type: activeStyle,
        brushSize: brushSize,
        data: [{ x, y }],
        imageSrc: image.src
      };

      const newLayers = [...layers, newCurLayer]
      setLayers(newLayers);

      if (activeStyle === "eraser") {
        erase(ctx, newCurLayer.data, newCurLayer.brushSize);
      } else if (activeStyle === 'brush') {
        drawBrush(newCurLayer, "");
      }
      // setPrevMovePoint({ x, y });
      drawAllLayers(newLayers);
    };

    const handleCursorMouseMove = (event) => {
      const { x, y } = getMousePos(cursorCanvasRef.current, event);
      const ctx = cursorCanvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      cursorCanvasRef.current.style.cursor = "none";

      let cursorInRect = cursorInsideRect;
      if (!isMoving) {
        const lastLayer = layers.length ? layers[layers.length - 1] : null;
        if (
          lastLayer &&
          lastLayer.type === "rect" &&
          lastLayer.data.length > 1
        ) {
          // draw bounds
          const { x: x1, y: y1 } = lastLayer.data[0];
          const { x: x2, y: y2 } = lastLayer.data[1];
          const rect = { x: x1, y: y1, w: x2 - x1, h: y2 - y1 };
          cursorInRect = getCursorTypeInsideLastRect(x, y, rect);
          setCursorInsideRect(cursorInRect);
        }
      }

      if (cursorInRect) {
        cursorCanvasRef.current.style.cursor = cursorInRect.cursor;
      } else {
        if (activeStyle === "brush") {
          drawCursor(ctx, x, y, brushSize);
        } else if (activeStyle === "rect") {
          cursorCanvasRef.current.style.cursor = `url(${"https://cfcdn.apowersoft.info/projects/picwish/assets/img/cursor-rect.dc3b8cf1.png"}) 12.5 12.5, auto`;
        } else if (activeStyle === "eraser") {
          drawCursor(ctx, x, y, brushSize, "ring");
        }
      }
    };

    const handleCanvasMouseMove = (event) => {
      handleCursorMouseMove(event);
      if (isMoving) {
        const { x, y } = getMousePos(canvasRef.current, event);
        const lastLayer = layers.length ? layers[layers.length - 1] : null;
        if (!lastLayer || lastLayer.data.length < 2) return;
        const { x: x1, y: y1 } = lastLayer.data[0];
        const { x: x2, y: y2 } = lastLayer.data[1];
        const rect = { x: x1, y: y1, w: x2 - x1, h: y2 - y1 };
        const dx = x - prevMovePoint.x;
        const dy = y - prevMovePoint.y;

        const newData = resizeOrMoveRect(cursorInsideRect.move, dx, dy, rect);
        const newLastLayer = { ...lastLayer, data: newData };
        const allLayers = [...layers.slice(0, -1), newLastLayer];
        drawRect(newLastLayer);
        drawAllLayers(allLayers);
        setLayers(allLayers);
        setPrevMovePoint({ x, y });
        return;
      }
      if (isDrawing) {
        draw(event);
      }
    };

    const handleCanvasMouseUp = () => {
      setIsDrawing(false);
      setIsMoving(false);
      setPrevMovePoint();
    };

    const updateCurLayerData = (data) => {
      const _layers = [...layers];
      const cur = _layers.splice(layers.length - 1, 1)[0];
      const newCur = { ...cur, data };
      setLayers([..._layers, newCur]);
      return newCur;
    };

    const draw = (event, start = false) => {
      const ctx = getContext()

      const canvas = canvasRef.current;
      const { x, y } = getMousePos(canvas, event);

      let curLayer = layers.length ? layers[layers.length - 1] : null;
      // const curCanvas = (curLayer || {}).canvas;

      switch (activeStyle) {
        case "brush":
        case "eraser": {
          const pts = curLayer.data;
          const tmpList = [...pts, { x, y }];
          curLayer = updateCurLayerData(tmpList);
          if (activeStyle === "eraser") {
            erase(ctx, curLayer.data, curLayer.brushSize);
            return;
          }
          drawBrush(curLayer, "");
          break;
        }
        case "rect": {
          const pts = [...curLayer.data];

          if (pts.length === 1) {
            pts.push({ x, y });
          } else {
            pts[1] = { x, y }
          }
          const tmpList = [...pts];
          curLayer = updateCurLayerData(tmpList);
          drawRect(curLayer, "");
          break;
        }
        default:
          break;
      }
      if (redoStep > 0) {
        clearBackupLayers();
      }
      drawAllLayers(layers);
    };

    const handleClearImage = () => {
      const ctx = getContext()

      setLayers([]);
      ctx.clearRect(0, 0, 768, 768);
      clearBackupLayers();
    };

    const handleClearBrush = (image) => {
      const ctx = getContext()

      const canvas = canvasRef.current;
      const newCanvas = createNewCanvas(canvas.width, canvas.height);

      const newCurLayer = {
        canvas: newCanvas,
        type: 'eraser',
        brushSize: brushSize,
        data: {
          height: 768,
          width: 768
        },
        imageSrc: image
      };

      erase(ctx, newCurLayer.data, newCurLayer.brushSize);
      setLayers(([...layers, newCurLayer]))
    }

    const handleGetImage = () => {
      const canvas = canvasRef.current;
      const tempCanvas1 = document.createElement("canvas"); // Create a temporary canvas1
      tempCanvas1.width = canvas.width;
      tempCanvas1.height = canvas.height;
      const tempCtx1 = tempCanvas1.getContext("2d");
      tempCtx1.clearRect(0, 0, 768, 768);
      tempCtx1.fillStyle = maskType === "white" ? "black" : "white";
      tempCtx1.fillRect(0, 0, canvas.width, canvas.height);
      // new draw with white color

      const newLayers = [...layers];
      newLayers.forEach((layer) => {
        const newCanvas = createNewCanvas(canvas.width, canvas.height)

        const newLayer = {
          ...layer,
          canvas: newCanvas
        }

        if (newLayer.type === "eraser") {
          // drawBruch with black color instead of erase
          drawBrush(newLayer, maskType === "white" ? "black" : "white");
          tempCtx1.drawImage(newLayer.canvas, 0, 0, canvas.width, canvas.height);
        } else if (newLayer.type !== "image") {
          if (newLayer.type === "brush") {
            drawBrush(newLayer, maskType);
          } else if (newLayer.type === "rect") {
            drawRect(newLayer, maskType);
          }

          tempCtx1.drawImage(newLayer.canvas, 0, 0, canvas.width, canvas.height);
        }
      });

      const tempCanvas = document.createElement("canvas"); // Create a temporary canvas
      tempCanvas.width = 1024;
      tempCanvas.height = 1024;

      const tempCtx = tempCanvas.getContext("2d");
      // Scale the content of the original canvas and draw it on the temporary canvas
      tempCtx.drawImage(
        tempCanvas1,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        tempCanvas.width,
        tempCanvas.height
      );

      const dataURL = tempCanvas.toDataURL(); // Get base64 representation of the canvas image
      return dataURL;
    };

    const handleGetSteps = () => {
      return layers.length;
    };

    useImperativeHandle(ref, () => ({
      getImage: handleGetImage,
      clearRect: handleClearImage,
      clearBrush: handleClearBrush,
      isEdited: handleGetSteps,
    }));

    const handleRemove = async () => {

      const newImage = await onRemove()
      setCurrentImage(newImage);
      handleClearBrush(newImage);
    }

    return (
      <div ref={ref} className="main-brush-canvas">
        <div className="head">
          <Slide className="flex1">
            <h1 className="brush-title">
              <span className="title-span">
                {!!onExit && <button onClick={onExit} type='button'>
                  <ExitSvg />
                </button>}
                {mainCaption}
              </span> {subCaption}
            </h1>
          </Slide>
          <div className="top-bar">
            <div
              className={`top-action-btn ${layers.length > 0 ? "" : "disabled"
                }`}
              onClick={onUndo}
            >
              <IcoUndo />
            </div>
            <div
              className={`top-action-btn ${redoStep > 0 ? "" : "disabled"}`}
              onClick={onRedo}
            >
              <IcoRedo />
            </div>
            <div className={`top-action-btn`} onClick={onShowResultImg}>
              {showOriginImg ? <IcoEye /> : <IcoEyeHide />}
            </div>
            <div
              className={`top-action-btn`}
              onClick={() =>
                download(showOriginImg ? orgImage.src : currentImage, "download.png")
              }
            >
              <DownloadImg /> Download
            </div>
          </div>
        </div>
        <div className="brush-canvas">
          <img
            src={orgImage.src}
            className={`org-image-source pointer-events-none ${orgImage.width >= orgImage.height ? "full-width" : "full-height"
              }`}
            alt="original"
          />
          <img
            src={currentImage}
            className={`image-source pointer-events-none ${showOriginImg ? "" : "active"
              } ${image.width >= image.height ? "full-width" : "full-height"}`}
            alt="source"
          />
          <canvas
            ref={canvasRef}
            id="brush_canvas"
            width={canvasWidth}
            height={canvasHeight}
          />
          <canvas
            ref={cursorCanvasRef}
            id="cursor_canvas"
            width={canvasWidth}
            height={canvasHeight}
            onMouseDown={handleCanvasMouseDown}
            onMouseMove={handleCanvasMouseMove}
            onMouseUp={handleCanvasMouseUp}
            onMouseLeave={handleCanvasMouseLeave}
          />
          <div className={`slide-rect ${showOriginImg ? "" : "active"}`}></div>
        </div>
        <div className="control-area">
          <div
            className={`btn-control ${activeStyle === "brush" ? "active" : ""}`}
            onClick={() => {
              drawAllLayers(layers, false);
              setActiveStyle("brush");
            }}
          >
            <IcoBrushAdd />
            Add
          </div>
          <div
            className={`btn-control ${activeStyle === "eraser" ? "active" : ""
              }`}
            onClick={() => {
              drawAllLayers(layers, false);
              setActiveStyle("eraser");
            }}
          >
            <IcoBrushSub /> Eraser
          </div>
          <div
            className={`btn-control ${activeStyle === "rect" ? "active" : ""}`}
            onClick={() => setActiveStyle("rect")}
          >
            <IcoRect />
            Rectangle
          </div>
          <div
            className="btn-control"
            onClick={() => setShowSetting(!showSetting)}
          >
            <IcoSetting />
            Setting
            {showSetting && (
              <div className="setting-area">
                <div
                  className="cover-area"
                  onClick={() => setShowSetting(false)}
                />
                <div className="setting-part">
                  <div className="setting">
                    <input
                      type="range"
                      min={15}
                      max={50}
                      value={brushSize}
                      onChange={handleBrushSizeChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="btn-control external-btn" onClick={handleClearImage}>
            Clear
          </div>
          <div className="btn-control external-btn" onClick={handleRemove}>
            Remove
          </div>
        </div>
      </div>
    );
  }
);

export default BrushCanvas;
