import { useDispatch, useSelector } from "react-redux";
import { useState, useRef } from "react";
import { useContext } from "react";

import { CButton } from "components/atoms/CButton";
import { CTextarea } from "components/atoms/CTextarea";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { ReactComponent as Settings } from "assets/imgs/studioCreator/settings.svg";

import StudioNegativePrompt from "../StudioNegativePrompt";
import useClickOutside from "hooks/useClickOutside";
import useBackground from "hooks/studio/useBackground";
import {
  setGeneratedImages,
  setIsUserChooseImage,
  setNegativeText,
  setPositiveText,
  setSelectedItem,
} from "store/slices/studio";

import { getApi } from "utils/customNetwork";

import "./index.scss";
import { getNegativeText, getPositiveText } from "store/utils";
import useHistoryDispatch from "hooks/history/useHistoryStudio";

const StudioCanvasPrompt = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isNegativePopupOpen, setIsNegativePopupOpen] = useState(false);

  const positiveText = useSelector(getPositiveText);
  const negativeText = useSelector(getNegativeText);

  const { showNoti, setLoading, loading } = useContext(LayoutContext);

  const { drawMainSlider, onBrushOutpaint, onGetBrushOutpaint } =
    useBackground();
  const { historyLayer } = useHistoryDispatch();

  const onGenerateText = async () => {
    try {
      setLoading(true);

      const payload = {
        positiveText: positiveText?.trim(),
        imageCount: 4,
        width: 512,
        height: 512,
        ...(negativeText ? { negativeText: negativeText.trim() } : {}),
      };
      const data = await getApi("/v1/images/generate/text", "POST", payload);

      if (data.data) {
        dispatch(setGeneratedImages(data.data));
        dispatch(setIsUserChooseImage(true));
        drawMainSlider();
      }
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const onBrushGenerate = async ({ imageDataUrl, maskDataUrl, inverce }) => {
    try {
      setLoading(true);

      const payload = {
        initImage: imageDataUrl,
        maskImage: maskDataUrl,
        maskSource: "MASK_IMAGE_WHITE",
        positiveText: positiveText,
        steps: 30,
        cfgScale: 12,
        inpainting_mask_invert: 0,
        ...(negativeText ? { negativeText: negativeText.trim() } : {}),
      };

      const data = await getApi("/v1/images/generate/masking", "POST", payload);

      if (data.data) {
        onGetBrushOutpaint(data.data);
      }
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!positiveText.trim()) {
      return;
    }

    historyLayer();
    const brushInfo = onBrushOutpaint();

    if (brushInfo) {
      onBrushGenerate(brushInfo);
    } else {
      onGenerateText();
    }
  };

  useClickOutside(ref, () => setIsNegativePopupOpen(false));

  const onChangeNegativeText = (e) => {
    dispatch(setNegativeText(e.target.value));
  };

  const onChangePositiveText = (e) => {
    dispatch(setPositiveText(e.target.value));
  };

  const onEnter = (e) => {
    e.stopPropagation()
    if (e.code === "Enter") {
      onSubmit(e)
    }
  }

  return (
    <form className="studio-canvas-wrapper__bottom-bar canvas-actions">
      <div className="canvas-actions__left-bar">
        <div ref={ref}>
          <CButton
            type='button'
            addClass="gradient active canvas-actions__settings-btn"
            onClick={() => setIsNegativePopupOpen(!isNegativePopupOpen)}
            size="medium"
          >
            <Settings className="btn-icon" />
          </CButton>
          <StudioNegativePrompt
            value={negativeText}
            onEnter={onEnter}
            onChange={onChangeNegativeText}
            isOpen={isNegativePopupOpen}
            onFocus={() => {
              dispatch(setSelectedItem(null))
            }}
            onClose={() => setIsNegativePopupOpen(false)}
          />
        </div>
        <CTextarea
          addClass="canvas-actions__textarea"
          placeholder="Ex: Beautiful flowers and a blob object frozen in a block of ice, isolated on warm-grey background"
          onChange={onChangePositiveText}
          value={positiveText}
          rows={2}
          onKeyDown={onEnter}
          onFocus={() => {
            dispatch(setSelectedItem(null))
          }}
          name="positiveText"
        />
      </div>

      <CButton
        addClass="gradient active py-22i canvas-actions__button"
        onClick={onSubmit}
        size="medium"
        disabled={loading}
        type='submit'
      >
        Generate Image
      </CButton>
    </form>
  );
};

export default StudioCanvasPrompt;
