import { AdminLayout } from "components/layouts/AdminLayout";

import "./styles.scss";

export function AdminSettings() {

  return (
    <AdminLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome back admin"
    >
    </AdminLayout>
  );
}
