import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { getApi } from "utils/customNetwork";
import logo from "assets/imgs/logo.svg";
import { ReactComponent as Man } from "assets/imgs/auth/people.svg";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";

import "./styles.scss";

export const FillInformation = () => {
    const navigate = useNavigate()
    const { user, setLoading, setUser, showNoti, checkToken } = useContext(LayoutContext)
    const [values, setValues] = useState({
        name: ''
    })

    useEffect(() => {
        setValues(prev => ({ ...prev, name: user?.user?.first_name }))
    }, [user])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            checkToken();

            const data = await getApi("/v1/users/me/change-name", "PATCH", values);
            setUser({ ...user, user: data.user });
            navigate('/')
        } catch (err) {
            showNoti("error", err);
        }
        setLoading(false);
    }

    const handleChange = (e) => {
        setValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    return (
        <div id="#login" className="auth-area">
            <Helmet>
                <title>
                    Creativio AI Login - Access AI Product Photography & Photo Editor
                    Tools
                </title>
                <meta
                    name="description"
                    content="Sign in to Creativio AI for premium AI product photography, AI photoshoots, and advanced AI photo editor features. Elevate your photo quality now!"
                />
            </Helmet>
            <div className="login">
                <div className="logo mt-15">
                    <img src={logo} alt="logo" />
                </div>
                <div className="active-area">
                    <form onSubmit={handleSubmit}>
                        <div className="login-card mt-35">
                            <h3>Please, enter your name</h3>
                            <div className="form-group">
                                <div className="input-with-icon">
                                    <Man className="icon-img" />
                                    <input
                                        type="text"
                                        id="name"
                                        name='name'
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mt-20">
                                <CButton
                                    addClass="primary text-size-20 py-10 full large"
                                    mainClass="w-full"
                                    type="submit"
                                >
                                    Continue
                                </CButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}