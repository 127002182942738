import { ReactComponent as Ticker } from "assets/imgs/ticker.svg";
import { useState } from "react";
import { usePopper } from "react-popper";
import { ReactComponent as Ring } from "assets/imgs/ring.svg";
import { useLocalStorage } from "hooks";

const Notifications = () => {
  const [isShown, setIsShown] = useState(false);
  const [isShownNotifications, setIsShownNotifications] = useLocalStorage(
    "creativio_notifications-1",
    false
  );

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <>
      <button
        className={`alarm-trigger ${isShownNotifications ? "" : "active"}`}
        onClick={() => {
          setIsShown(!isShown);
          setIsShownNotifications(true);
        }}
      >
        <Ring
          className={`icon-ring ${isShown ? "active" : ""}`}
          ref={setReferenceElement}
        />
      </button>
      {isShown && (
        <div className="alarm-area">
          <div className="cover-area" onClick={() => setIsShown(false)} />
          <div
            className="alarm-part"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="alarm">
              <p className="title">Creativio AI 1.1 Update</p>
              <p className="description">
                For Standard Users:
                <br />
                <ul>
                  <li>
                    🚀  Introducing our New AI Engine, fine-tuned specifically for product photography.
                  </li>
                  <li>
                    🧠 Enhanced Remove Background AI model for more precise edits.
                  </li>
                </ul>
                For Pro Members:
                <ul>
                  <li>
                    🌐 Dive into Unlimited Access & Credits for boundless creativity.
                  </li>
                  <li>📸 Experience breathtaking Ultra-HD Image Generation.
                  </li>
                  <li>🎨 Simplify photos with Magic Object Removal.
                  </li>
                  <li>
                    🛡️ Guard your brand with Custom Branding & Enhanced Privacy features.
                  </li>
                  <li>
                    🔬 Exclusive! Our Advanced AI Studio Editor is now available in beta for Pro Members.
                  </li>
                </ul>
                Upgrade or join Pro to unlock these premium features!
              </p>
            </div>
          </div>
          <Ticker className="ticker" />
        </div>
      )}
    </>
  );
};

export default Notifications;
