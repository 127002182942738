import { CTextarea } from "components/atoms/CTextarea";

import { ReactComponent as CloseSvg } from "./images/close.svg";

import "./index.scss";

const StudioNegativePrompt = ({ isOpen, onClose, value, onChange, onEnter, ...props }) => {
  return isOpen ? (
    <div className="studio-negative-prompt">
      <h2 className="studio-negative-prompt__title">Negative Prompt</h2>
      <button onClick={onClose} className="studio-negative-prompt__close">
        <CloseSvg />
      </button>
      <CTextarea
        rows="2"
        value={value}
        onChange={onChange}
        onKeyDown={onEnter}
        name="negativeText"
        addClass="studio-negative-prompt__textarea"
        placeholder="Enter things you don’t want to see in your generation."
        {...props}
      />
    </div>
  ) : null;
};

export default StudioNegativePrompt;
