import { DRAW_OBJECT_TYPES } from "constants/studio";
import { calculateAngle, toVirtualX, toVirtualY } from "utils/studio/utils";
import DrawObject from "./DrawObject";

class EraserObject extends DrawObject {
  initialScaleX = 1;
  initialScaleY = 1;
  initialRotation = 0;

  constructor({ meta, position, canvas, points, width, height, x, y }) {
    super({
      type: DRAW_OBJECT_TYPES.eraser,
      meta,
      position,
      canvas,
      points,
      width,
      height,
      x,
      y,
    });
  }

  drawOnTempCanvas({
    offsetX = 0,
    offsetY = 0,
    ctx = null,
    scale = 1,
    originX = 0,
    originY = 0,
    width = 0,
    height = 0
  }) {
    const mainCtx = ctx || this.getContext();

    if (!mainCtx) return;

    const tmpCanvas = document.createElement("canvas");
    const tmpCtx = tmpCanvas.getContext("2d");

    tmpCanvas.width = this.parentItem.width;
    tmpCanvas.height = this.parentItem.height;
    const points = this.points;

    tmpCtx.save();
    tmpCtx.beginPath();

    tmpCtx.lineCap = "round";
    tmpCtx.lineJoin = "round";
    tmpCtx.lineWidth = this.meta.lineWidth * 1;
    tmpCtx.globalAlpha = 1;
    const formatOriginX = toVirtualX(originX, offsetX, scale);
    const formatOriginY = toVirtualY(originY, offsetY, scale);

    for (let i = 0; i < points.length; i++) {
      const currentPoint = points[i];
      const x = currentPoint.x + this.offsetX - originX;
      const y = currentPoint.y + this.offsetY - originY;

      if (i === 0) {
        tmpCtx.moveTo(x, y);
      }

      tmpCtx.lineTo(x, y);
    }

    tmpCtx.stroke();

    tmpCtx.restore();

    const newWidth = width * this.scaleX * scale;
    const newHeight = height * this.scaleY * scale;

    const newCenterX = formatOriginX + newWidth / 2;
    const newCenterY = formatOriginY + newHeight / 2;

    mainCtx.save();

    mainCtx.translate(newCenterX, newCenterY);
    mainCtx.rotate(this.rotation);
    mainCtx.translate(-newCenterX, -newCenterY);

    mainCtx.globalCompositeOperation = "destination-out";

    mainCtx.drawImage(
      tmpCanvas,
      formatOriginX,
      formatOriginY,
      newWidth,
      newHeight
    );
    mainCtx.globalCompositeOperation = "source-over";

    mainCtx.restore();
  }

  drawPoints({
    offsetX = 0,
    offsetY = 0,
    ctx = null,
    scale = 1,
    originX = 0,
    originY = 0,
    maskType
  }) {
    ctx = ctx || this.getContext();

    if (!ctx) return;

    const points = this.points;

    ctx.save();

    ctx.beginPath();

    ctx.translate(0, 0);

    if (maskType) {
      ctx.strokeStyle = maskType === "white" ? "black" : "white";
    } else {
      ctx.globalCompositeOperation = "destination-out";
    }

    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.lineWidth = this.meta.lineWidth * scale;
    ctx.globalAlpha = 1;

    const firstPoint = points[0];
    const x = toVirtualX(firstPoint.x + this.offsetX - originX, offsetX, scale);
    const y = toVirtualY(firstPoint.y + this.offsetY - originY, offsetY, scale);

    ctx.moveTo(x, y);

    for (let i = 0; i < points.length; i++) {
      const currentPoint = points[i];
      const x = toVirtualX(
        currentPoint.x + this.offsetX - originX,
        offsetX,
        scale
      );
      const y = toVirtualY(
        currentPoint.y + this.offsetY - originY,
        offsetY,
        scale
      );

      ctx.lineTo(x, y);
    }

    ctx.stroke();
    ctx.globalCompositeOperation = "source-over";
    ctx.restore();
  }

  drawRect({ scale, offsetX, offsetY, ctx = null, maskType }) {
    ctx = ctx || this.getContext();

    if (maskType) {
      ctx.fillStyle = maskType === "white" ? "black" : "white";
    } else {
      ctx.globalCompositeOperation = "destination-out";
    }
    ctx.fillRect(
      toVirtualX(this.x, offsetX, scale),
      toVirtualY(this.y, offsetY, scale),
      this.width * scale,
      this.height * scale
    );

    ctx.globalCompositeOperation = "source-over";
  }

  draw(props) {
    if (this.points?.length) {
      this.drawPoints(props);
    } else if (this.width && this.height) {
      this.drawRect(props);
    }
  }

  rotateItem(x, y, scale) {
    const itemX = this.x;
    const itemY = this.y;

    const width = this.width * scale;
    const middleW = width / 2;
    const topCenterX = itemX + middleW;
    const topCenterY = itemY - 20;

    const angle = calculateAngle(topCenterX, topCenterY, x, y);

    this.rotation = angle;

    if (Math.abs(this.rotation) > Math.PI / 2) {
      this.rotation += Math.PI;
    }
  }

  setRotation(rotation) {
    this.rotation = rotation;
  }
}

export default EraserObject;
