import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";

import { BRUSH_CANVAS } from "constants/studio";
import { setBrushCanvasRef, updateBrushCanvas } from "store/slices/studio";

const BrushCanvas = () => {
  const brushCanvasRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!brushCanvasRef.current) return;

    dispatch(setBrushCanvasRef(brushCanvasRef));
    dispatch(updateBrushCanvas(brushCanvasRef.current));
  }, [brushCanvasRef, dispatch]);

  return (
    <canvas
      ref={brushCanvasRef}
      id={BRUSH_CANVAS}
      className="studio-canvas__canvas studio-canvas__canvas_overlay"
    />
  );
};

export default BrushCanvas;
