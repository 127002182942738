import { CTextarea } from "components/atoms/CTextarea";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { DashboardLayout } from "components/layouts/DashboardLayout"
import { convertHeicImage } from "pages/Edit/utils";
import UploadView from "pages/MagicRemover/components/UploadView"
import { useContext, useEffect } from "react";
import { getApi } from "utils/customNetwork";
import { formatDataUrl } from "utils/getDataUrl";
import { useDispatch, useSelector } from "react-redux";
import { getImage, getResult } from "store/slices/imageToText/utils";
import { setImage, setResult } from "store/slices/imageToText/reducer";

import './index.scss'

const ImageToText = () => {
    const { setLoading, showNoti } = useContext(LayoutContext);
    const dispatch = useDispatch();
    const image = useSelector(getImage);
    const result = useSelector(getResult);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) return;
        const reader = new FileReader();

        if (file.type === "" || file.type === "image/heic") {
            const blob = await convertHeicImage(file);
            handleImageToText(reader);
            reader.readAsDataURL(blob);
            return;
        }
        handleImageToText(reader);
        reader.readAsDataURL(file);
    };

    const handleImageToText = (reader) => {
        reader.onload = async () => {
            try {
                setLoading(true)
                const dataUrl = formatDataUrl(reader.result);
                const result = await getApi('/v1/images/generate/blip2', 'POST', { image: dataUrl })

                dispatch(setResult(result.data?.caption));
                dispatch(setImage(reader.result));
                setLoading(false)
            } catch (e) {
                showNoti('error', e)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        dispatch(setImage(null));
        dispatch(setResult(null));
    }, []);

    return <DashboardLayout
        page="image-to-text"
        caption="Product Studio"
        message="Enhance Your product Photography with AI"
    >
        <main id="enhance" className="body-container image-to-text">
            <UploadView
                content={{
                    mainTitle: <><span>Image To Text</span> Generation Tool</>,
                    title: 'Upload an image to convert it into the text',
                    subtitle: 'upload an image'
                }}
                onChange={handleImageUpload}
            />
            <section className="image-section">
                {image && <img src={image} alt={result} className="image" />}
                {result && <div className="result">
                    <h3>
                        Result
                    </h3>
                    <CTextarea disabled addClass="textarea" value={result} />
                </div>}
            </section>
        </main>
    </DashboardLayout>
}

export default ImageToText