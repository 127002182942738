import { AdminLayout } from "components/layouts/AdminLayout";
import { useContext } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useEffect } from "react";
import { getApi } from "utils/customNetwork";
import { useState } from "react";
import { Slide } from "react-awesome-reveal";
import { ReactComponent as IcoUser } from "assets/imgs/admin/dashboard/user.svg";
import { ReactComponent as IcoDollar } from "assets/imgs/admin/dashboard/dollar.svg";
import { ReactComponent as IcoRevenue } from "assets/imgs/admin/dashboard/revenue.svg";
import { ReactComponent as IcoProUsers } from "assets/imgs/admin/dashboard/pro_users.svg";
import { ReactComponent as IcoAgency } from "assets/imgs/admin/dashboard/agency.svg";

import { CTextarea } from "components/atoms/CTextarea";
import { CInput } from "components/atoms/CInput";
import { CButton } from "components/atoms/CButton";
import useTable from "hooks/useTable";
import ConfirmationModal from 'components/atoms/Modal/ConfirmationModal';
import { UserModal } from "components/atoms/Modal/UserModal";
import UserTable from "./userTable";
import { CreditHistoryModal } from "components/atoms/Modal/CreditHistoryModal";

import "./styles.scss";
import InfoCard from "components/molecules/admin/InfoCard";

const rowsPerPage = 10;

export function AdminDashboard() {
  const { setLoading, showNoti, checkToken, setUser } =
    useContext(LayoutContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [creditConsumed, setCreditConsumed] = useState("");
  const [usersActivity, setUsersActivity] = useState("");
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(filteredUsers, page, rowsPerPage);
  // modal user area
  const [showUserModal, setShowUserModal] = useState(false);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [selUser, setSelUser] = useState();

  const getDashboardData = async (showLoading = true) => {
    if (showLoading) setLoading(true);

    try {
      checkToken();
      const data = await getApi("/admin/dashboard", "POST", {});
      const statistics = await getApi('/admin/users/statistic', "GET");

      setData({ ...data, ...statistics.data });
      setUsers(data.users);
      onSearch(data.users);
    } catch (err) {
      showNoti("error", err);
    }
    if (showLoading) setLoading(false);
  };
  // console.log(data)
  const onSearch = (orgUsers) => {
    setPage(1);
    const filtered = orgUsers.filter((user) => {
      const searchTerm = keyword.toLowerCase();
      const name = (user.first_name + " " + user.last_name).toLowerCase();
      const email = user.email.toLowerCase();
      const status = user.status.toLowerCase();
      const source = user.source.toLowerCase();

      if (Array.isArray(user.license)) {
        // Check if any license in the array matches the search term
        const licenseMatch = user.license.some(license => license.toLowerCase().includes(searchTerm));
        return name.includes(searchTerm) || email.includes(searchTerm) || licenseMatch || status.includes(searchTerm) || source.includes(searchTerm);
      } else {
        // If license is not an array, simply check if it includes the search term
        const license = user.license.toLowerCase();
        return name.includes(searchTerm) || email.includes(searchTerm) || license.includes(searchTerm) || status.includes(searchTerm) || source.includes(searchTerm);
      }

      /* Old logic */
      // const license = user.license.toLowerCase();
      // return (
      //   name.includes(searchTerm) ||
      //   email.includes(searchTerm) ||
      //   license.includes(searchTerm) ||
      //   status.includes(searchTerm)
      // );
    });

    setFilteredUsers(filtered);
  };
  const handleCreateUser = () => {
    setSelUser();
    setShowUserModal(true);
  };

  const onCloseUserModal = () => {
    setSelUser();
    setShowUserModal(false);
  };
  const handleTblEdit = (item) => {
    setSelUser(item);
    setShowUserModal(true);
  };
  const handleTblCredit = (item) => {
    setSelUser(item);
    setShowCreditModal(true);
  };
  const handleTblUserLogin = async (item) => {
    setLoading(true);
    try {
      checkToken();
      const data = await getApi("/admin/getUserToken", "POST", {
        user_id: item.id,
      });

      const expired = Math.floor(Date.now() / 1000) + 60 * 60; // 60 mins

      setUser({ user: data.user, token: data.token, expired });
      //redirect to home
      window.open("/dashboard", "_blank", "noreferrer");
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const confirmDeletion = async (item) => {
    setLoading(true);
    try {
      // Ensure that checkToken, getApi, showNoti, and getDashboardData are defined
      checkToken();
      await getApi("/admin/deleteUser", "POST", { user_id: item.id });
      showNoti("success", "Delete user successfully");
      getDashboardData(false);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const handleTblDelete = (item) => {
    setItemToDelete(item);
    setShowConfirmation(true);
  };

  const closeModal = () => {
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    onSearch(users);
  }, [keyword]);

  return (
    <AdminLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome back admin"
    >
      <div className="admin-dashboard body-container">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span>Dashboard</span> Statistics
            </h1>
          </Slide>
        </div>
        <div className="card-area mt-15">
          <InfoCard
            title="Total Revenue"
            Icon={IcoDollar}
            value='$5033'
          />
          <InfoCard
            title="Stability Balance"
            Icon={IcoDollar}
            value={data && data?.stability_balance?.toFixed(2)}
          />
          <InfoCard
            title="MRR Revenue"
            Icon={IcoRevenue}
            value='$12,467'
          />
          <InfoCard
            title="Total Users"
            Icon={IcoUser}
            value={data.totalUsers}
          />
          <InfoCard
            title="Pro Users"
            Icon={IcoProUsers}
            value={data.proUsers}
          />
          <InfoCard
            title="Agency Users"
            Icon={IcoAgency}
            value='234'
          />
        </div>
        <div className="middle-area mt-15">
          <CTextarea
            placeholder="Credits Consumed"
            onChange={(e) => setCreditConsumed(e.target.value)}
            rows={5}
            addClass="product mt-5"
            active={true}
            value={creditConsumed}
          ></CTextarea>
          <CTextarea
            placeholder="Users Activities"
            onChange={(e) => setUsersActivity(e.target.value)}
            rows={5}
            addClass="product mt-5"
            active={true}
            value={usersActivity}
          ></CTextarea>
        </div>

        <div className="btn-group mt-35">
          <div className="search-area">
            <CInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              addClass="full text-white"
            />
          </div>
          <div className="btn-mode">
            <CButton
              addClass="gradient"
              active={true}
              onClick={handleCreateUser}
            >
              Create User
            </CButton>
          </div>
        </div>

        <UserTable
          slice={slice}
          range={range}
          page={page}
          handleTblEdit={handleTblEdit}
          handleTblCredit={handleTblCredit}
          handleTblUserLogin={handleTblUserLogin}
          handleTblDelete={handleTblDelete}
          setPage={setPage}
        />

        <ConfirmationModal
          isOpen={showConfirmation}
          onClose={closeModal}
          itemModule="User"
          onConfirm={() => confirmDeletion(itemToDelete)}
          itemName={itemToDelete ? itemToDelete.first_name + ' ' + itemToDelete.last_name : ''}
        />
      </div>
      {showUserModal && (
        <UserModal
          show={showUserModal}
          user={selUser}
          onClose={onCloseUserModal}
          onHandleUpdate={() => getDashboardData(false)}
        />
      )}
      {showCreditModal && (
        <CreditHistoryModal
          show={showCreditModal}
          user={selUser}
          onClose={() => {
            setSelUser();
            setShowCreditModal(false);
          }}
        />
      )}
    </AdminLayout>
  );
}
