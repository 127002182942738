import {
  ACTION_MODES,
  CANVAS_GRID_COLOR,
  CELL_LINE_SIZE,
  CELL_SIZE,
  DRAW_OBJECT_TYPES,
  LOCK_BUTTON,
  MAIN_OBJECT_FILL_RECT,
  MAIN_OBJECT_SIZE,
  MAIN_OBJECT_SLIDER,
} from "constants/studio";
import useHistoryDispatch from "hooks/history/useHistoryStudio";
import EraserObject from "lib/drawings/EraserObject";
import GroupObject from "lib/shapes/GroupObject";
import ImageObject from "lib/shapes/ImageObject";
import MainRectObject from "lib/shapes/MainRectObject";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLayer,
  addOffsetX,
  addOffsetY,
  setActiveMode,
  setGeneratedImages,
  setIsUserChooseImage,
  setSelectedItem,
} from "store/slices/studio";
import {
  getBackgroundCanvasRef,
  getBrushCanvasRef,
  getCanvasRef,
  getControlsCanvasRef,
  getIsUserChooseImage,
  getIsUserInteract,
  getLayers,
  getMaskType,
  getOffsetX,
  getOffsetY,
  getScale,
} from "store/utils";
import { formatDataUrl } from "utils/getDataUrl";
// import getImageUrl from "utils/getImageUrl";
import isCanvasBlank from "utils/isCanvasBlank";
import { sortLayers } from "utils/studio/sortLayers";

const useBackground = () => {
  const canvasRef = useSelector(getCanvasRef);
  const backgroundCanvasRef = useSelector(getBackgroundCanvasRef);
  const { historyLayer } = useHistoryDispatch();
  const dispatch = useDispatch();
  const offsetX = useSelector(getOffsetX);
  const offsetY = useSelector(getOffsetY);
  const scale = useSelector(getScale);
  const layers = useSelector(getLayers);
  const maskType = useSelector(getMaskType);
  const brushCanvasRef = useSelector(getBrushCanvasRef);
  const isUserChooseImage = useSelector(getIsUserChooseImage);
  const isUserInteract = useSelector(getIsUserInteract);
  const controlsCanvas = useSelector(getControlsCanvasRef);

  const onDrawGrid = useCallback(() => {
    const canvas = backgroundCanvasRef?.current;

    if (!canvas) return;

    const context = canvas?.getContext("2d");

    const width = canvas?.clientWidth || 0;
    const height = canvas?.clientHeight || 0;

    if (!context) return;

    context.strokeStyle = CANVAS_GRID_COLOR;
    context.imageSmoothingEnabled = false;
    context.lineWidth = 0.5;
    context.beginPath();

    const cellZoom = CELL_SIZE * scale;
    const cellLineSize = CELL_LINE_SIZE * scale;

    for (let x = offsetX % cellZoom; x <= width; x += cellZoom) {
      for (let y = offsetY % cellZoom; y <= height; y += cellZoom) {
        context.moveTo(x - cellLineSize, y);
        context.lineTo(x + cellLineSize, y);
        context.moveTo(x, y - cellLineSize);
        context.lineTo(x, y + cellLineSize);
      }
    }

    context.stroke();
  }, [backgroundCanvasRef, offsetX, offsetY, scale]);

  const onClearBackground = () => {
    const canvas = backgroundCanvasRef?.current;

    const context = canvas?.getContext("2d");
    if (!canvas || !context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const getMainObject = useCallback(() => {
    const mainObject = layers.find(
      (item) => item.type === DRAW_OBJECT_TYPES.mainRect
    );
    return mainObject;
  }, [layers]);

  const drawMainSlider = useCallback(() => {
    const mainObject = getMainObject();
    if (!mainObject) return;

    mainObject.showMainSlider({ offsetX, offsetY, scale });
  }, [getMainObject, offsetX, offsetY, scale]);

  const onDrawMainObject = useCallback(() => {
    const canvas = canvasRef?.current;
    const mainObject = layers.find(
      (layer) => layer.type === DRAW_OBJECT_TYPES.mainRect
    );
    if (!canvas) {
      return;
    }

    if (mainObject) {
      mainObject.setCanvas(canvas);
      if (isUserChooseImage) {
        drawMainSlider();
      }
      mainObject.updateElements();
    }

    const width = MAIN_OBJECT_SIZE;
    const height = width;

    const x = (canvas.width - width) / 2;
    const y = (canvas.height - height) / 2;

    const lockButton = document.getElementById(LOCK_BUTTON);
    const imagesSlider = document.getElementById(MAIN_OBJECT_SLIDER);
    const fillRect = document.getElementById(MAIN_OBJECT_FILL_RECT);

    const drawingInfo = {
      x,
      y,
      width,
      height,
      canvas,
      lockButton,
      imagesSlider,
      fillRect,
    };

    const drawObject = new MainRectObject(drawingInfo);
    dispatch(addLayer(drawObject));
  }, [canvasRef, dispatch, drawMainSlider, isUserChooseImage, layers]);

  const onCancelChooseImage = () => {
    historyLayer();

    dispatch(setGeneratedImages([]));
    dispatch(setIsUserChooseImage(false));
    const mainObject = layers.find(
      (item) => item.type === DRAW_OBJECT_TYPES.mainRect
    );
    mainObject.hideMainSlider();
  };

  const onAcceptChooseImage = (image) => {
    historyLayer();

    dispatch(setGeneratedImages([]));
    dispatch(setIsUserChooseImage(false));

    const newGroup = new GroupObject();
    const img = new Image();
    img.crossOrigin = "anonymous";

    // TODO: Find a better solution of structuring the layers
    const mainObject = layers.find(
      (item) => item.type === DRAW_OBJECT_TYPES.mainRect
    );
    mainObject.hideMainSlider();

    img.onload = function () {
      const imgObject = new ImageObject({
        img,
        x: mainObject.x,
        y: mainObject.y,
        width: image.width,
        height: image.height,
        data: {
          url: image.imagePath,
        },
        imageType: "url",
        canvas: newGroup.getCanvas(),
      });

      newGroup.addObject(imgObject);
      dispatch(addLayer(newGroup));
      dispatch(setSelectedItem(newGroup));
      dispatch(setActiveMode(ACTION_MODES.select))
      newGroup.drawResizeRect({ scale, offsetX, offsetY });
    };
    img.src = image.imagePath;
  };

  const isEmptyInMainRect = (sourceCanvas = null) => {
    const mainObject = getMainObject();

    if (!mainObject || !sourceCanvas) return true;

    const canvas = document.createElement("canvas");
    canvas.width = mainObject.width;
    canvas.height = mainObject.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      sourceCanvas,
      mainObject.x,
      mainObject.y,
      mainObject.width,
      mainObject.height,
      0,
      0,
      mainObject.width,
      mainObject.height
    );

    return isCanvasBlank(canvas);
  };

  const getMaskedByMainObject = (sourceCanvas = null, maskType) => {
    try {
      const mainObject = getMainObject();

      if (!mainObject || !sourceCanvas) return null;

      const canvas = document.createElement("canvas");
      canvas.width = mainObject.width;
      canvas.height = mainObject.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        sourceCanvas,
        mainObject.x,
        mainObject.y,
        mainObject.width,
        mainObject.height,
        0,
        0,
        mainObject.width,
        mainObject.height
      );

      if (isCanvasBlank(canvas, maskType)) {
        return null;
      }

      return formatDataUrl(canvas.toDataURL());
    } catch (e) {
      return null;
    }
  };

  const getMaskedBrush = (brushLayers) => {
    const tmpBrushCanvas = document.createElement("canvas");
    const brushCanvasCtx = tmpBrushCanvas.getContext("2d");

    tmpBrushCanvas.width = canvasRef.current.width;
    tmpBrushCanvas.height = canvasRef.current.height;

    brushCanvasCtx.clearRect(0, 0, tmpBrushCanvas.width, tmpBrushCanvas.height);

    brushCanvasCtx.fillStyle = maskType === "white" ? "black" : "white";
    brushCanvasCtx.fillRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    for (let layer of brushLayers) {
      if (layer.type === DRAW_OBJECT_TYPES.eraser) {
        layer.draw({
          offsetX: 0,
          offsetY: 0,
          scale: 1,
          ctx: brushCanvasCtx,
          maskType,
        })
      } else {
        layer.drawOnTempCanvas({
          offsetX: 0,
          offsetY: 0,
          scale: 1,
          ctx: brushCanvasCtx,
          maskType,
        });
      }
    }

    if (
      isCanvasBlank(tmpBrushCanvas, maskType) ||
      isEmptyInMainRect(tmpBrushCanvas)
    ) {
      return null;
    }

    const maskDataUrl = getMaskedByMainObject(tmpBrushCanvas);

    return maskDataUrl;
  };

  const getMaskedContent = (contentLayers, hasBrush) => {
    const tmpContentCanvas = document.createElement("canvas");
    const contentCanvas = tmpContentCanvas.getContext("2d");

    let newMaskDataUrl = null;

    const canvasWidth = canvasRef.current.width;
    const canvasHeight = canvasRef.current.height;

    tmpContentCanvas.width = canvasRef.current.width;
    tmpContentCanvas.height = canvasRef.current.height;

    for (let layer of contentLayers) {
      layer.draw({ offsetX: 0, offsetY: 0, scale: 1, ctx: contentCanvas });
    }

    if (isCanvasBlank(tmpContentCanvas)) {
      return { imageDataUrl: null, newMaskDataUrl, };
    }


    if (!hasBrush) {
      const offscreenCanvas = document.createElement("canvas");
      offscreenCanvas.width = canvasWidth;
      offscreenCanvas.height = canvasHeight;
      const offscreenContext = offscreenCanvas.getContext("2d");

      offscreenContext.drawImage(tmpContentCanvas, 0, 0);
      const imageData = offscreenContext.getImageData(0, 0, offscreenCanvas.width, offscreenCanvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        if (data[i] !== 0 || data[i + 1] !== 0 || data[i + 2] !== 0) {
          data[i] = 0;
          data[i + 1] = 0;
          data[i + 2] = 0;
        }
      }

      offscreenContext.putImageData(imageData, 0, 0);
      if (!isCanvasBlank(offscreenCanvas, maskType)) {
        newMaskDataUrl = getMaskedByMainObject(offscreenCanvas, maskType);
      }
    }


    const imageDataUrl = getMaskedByMainObject(tmpContentCanvas);

    return { imageDataUrl, newMaskDataUrl, };
  };

  const onBrushOutpaint = () => {
    const brushLayers = [];
    const contentLayers = [];
    const sortedLayers = sortLayers(layers)
    for (let layer of sortedLayers) {
      if (layer.type === DRAW_OBJECT_TYPES.mainRect) continue;

      if (layer.type === DRAW_OBJECT_TYPES.brush || layer.type === DRAW_OBJECT_TYPES.eraser) {
        brushLayers.push(layer);
      } else {
        contentLayers.push(layer);
      }
    }

    const maskDataUrl = getMaskedBrush(brushLayers);
    const { imageDataUrl, newMaskDataUrl } = getMaskedContent(contentLayers, !!maskDataUrl);

    let mask = maskDataUrl || newMaskDataUrl;
    if (!mask || !imageDataUrl) return null;

    return {
      imageDataUrl,
      maskDataUrl: mask
    };
  };

  const onEraseBrushInRect = (mainObject) => {
    const newCurLayer = {
      width: mainObject.width,
      height: mainObject.height,
      x: mainObject.x,
      y: mainObject.y,
      canvas: brushCanvasRef.current,
    };

    const newEraseObject = new EraserObject(newCurLayer);
    dispatch(addLayer(newEraseObject));
    newEraseObject.draw({ scale, offsetX, offsetY });
  };

  const onGetBrushOutpaint = (image) => {
    const newGroup = new GroupObject();
    const img = new Image();
    img.crossOrigin = "anonymous";

    const mainObject = getMainObject();

    img.onload = function () {
      const imgObject = new ImageObject({
        img,
        x: mainObject.x,
        y: mainObject.y,
        width: image.width,
        height: image.height,
        data: {
          url: image.imagePath,
        },
        imageType: "url",
        canvas: newGroup.getCanvas(),
      });

      newGroup.addObject(imgObject);
      dispatch(addLayer(newGroup));
      dispatch(setSelectedItem(newGroup));
      newGroup.drawResizeRect({ scale, offsetX, offsetY });

      onEraseBrushInRect(mainObject);
    };

    img.src = image.imagePath;
  };

  const onPanning = (event) => {
    if (!isUserInteract) return;
    const movementX = "movementX" in event ? event.movementX : 0;
    const movementY = "movementY" in event ? event.movementY : 0;
    dispatch(addOffsetX(movementX));
    dispatch(addOffsetY(movementY));
  };

  const clearControls = () => {
    const canvas = controlsCanvas?.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };


  return {
    onDrawGrid,
    onDrawMainObject,
    onClearBackground,
    drawMainSlider,
    onCancelChooseImage,
    onAcceptChooseImage,
    onBrushOutpaint,
    onGetBrushOutpaint,
    onPanning,
    clearControls
  };
};

export default useBackground;
