import * as React from "react";
import "./styles.scss";

export function CInput({
  id = "",
  name = "",
  type = "text",
  addClass = "",
  onChange = () => {},
  onKeyUp = () => {},
  active = false,
  placeholder = "",
  value = "",
  min = null,
  max = null,
  required = false,
}) {
  return (
    <input
      className={`custom-input ${addClass} ${active ? "active" : ""}`}
      id={id !== "" ? id : null}
      name={name !== "" ? name : null}
      type={type}
      placeholder={placeholder}
      value={value}
      min={min}
      max={max}
      onChange={onChange}
      onKeyUp={onKeyUp}
      required={required}
    />
  );
}
