import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";

import { setCursorCanvasRef } from "store/slices/studio";
import { CURSOR_CANVAS } from "constants/studio";

const CursorCanvas = () => {
  const cursorCanvasRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!cursorCanvasRef.current) return;

    dispatch(setCursorCanvasRef(cursorCanvasRef));
  }, [cursorCanvasRef, dispatch]);

  return (
    <canvas
      ref={cursorCanvasRef}
      id={CURSOR_CANVAS}
      className="studio-canvas__canvas studio-canvas__canvas_overlay"
    />
  );
};

export default CursorCanvas;
