import Label, { LABEL_COLORS } from "components/atoms/Label";
import { ReactComponent as BetaSvg } from "./images/beta.svg";

const BetaLabel = () => {
  return (
    <Label Icon={<BetaSvg />} color={LABEL_COLORS.yellow}>
      Beta
    </Label>
  );
};

export default BetaLabel;
