export const getTrendImages = (state) => state.textRemover.trendImages;
export const getStep = (state) => state.textRemover.step;
export const getImageType = (state) => state.textRemover.imageType;
export const getCurrentPage = (state) => state.textRemover.currentPage;
export const getOrgImageSize = (state) => state.textRemover.orgImageSize;
export const getImageSize = (state) => state.textRemover.imageSize;
export const getIsLast = (state) => state.textRemover.isLast;
export const getSelectedImage = (state) => state.textRemover.selectedImage;
export const getActiveTrend = (state) => state.textRemover.activeTrend;
export const getActiveMode = (state) => state.textRemover.activeMode;
export const getKeyword = (state) => state.textRemover.keyword;
export const getResultImage = (state) => state.textRemover.resultImage;
export const getNewImage = (state) => state.textRemover.newImage;
export const getLayers = (state) => state.textRemover.layers;
export const getBackupLayers = (state) => state.textRemover.backupLayers;
export const getRedoStep = (state) => state.textRemover.redoStep;