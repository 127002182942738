export const download = (imageUrl, fileName) => {
    document.documentElement.style.cursor = 'wait';
    fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);
            document.documentElement.style.cursor = 'pointer';
        })
        .catch(error => {
            document.documentElement.style.cursor = 'pointer';
            console.error('Error downloading image:', error);
            alert('Error downloading image')
        });
}