import { DashboardLayout } from "components/layouts/DashboardLayout";
import "./styles.scss";
import { useContext } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import { useRef } from "react";
import BrushCanvas from "components/atoms/ImageEditor/BrushCanvas";
import { LICENSE } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getBackupLayers,
  getImageSize,
  getImageType,
  getLayers,
  getNewImage,
  getOrgImageSize,
  getRedoStep,
  getResultImage,
  getSelectedImage,
  getStep,
} from "store/slices/magicRemover/utils";
import {
  setBackupLayers,
  setImageSize,
  setImageType,
  setLayers,
  setRedoStep,
  setResultImage,
  setStep,
} from "store/slices/magicRemover/reducer";
import UploadStep from "./components/UploadStep";

export function MagicRemover() {
  const brushCanvasRef = useRef(null);
  const step = useSelector(getStep);
  const imageSize = useSelector(getImageSize);
  const orgImageSize = useSelector(getOrgImageSize);
  const selectedImage = useSelector(getSelectedImage);
  const resultImage = useSelector(getResultImage);
  const newImage = useSelector(getNewImage);
  const layers = useSelector(getLayers);
  const backupLayers = useSelector(getBackupLayers);
  const redoStep = useSelector(getRedoStep)

  const dispatch = useDispatch();
  const imageType = useSelector(getImageType);

  const { showNoti, setLoading, checkToken } = useContext(LayoutContext);

  const onEditImage = async () => {
    if (!brushCanvasRef.current) return resultImage;

    let result = resultImage

    const base64image = brushCanvasRef.current.getImage();
    // call remove background image
    const formData = new FormData();
    formData.append("type", imageType);
    if (imageType === "url") {
      formData.append("url", resultImage);
    } else {
      formData.append("image", newImage);
    }
    formData.append("width", imageSize.width);
    formData.append("height", imageSize.height);
    formData.append("mask_image", base64image);

    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/removeSubBkImg", "POST", formData);
      const img = data.url;

      dispatch(setImageType("url"));
      dispatch(setResultImage(img));

      dispatch(setImageSize({ width: data.width, height: data.height }));

      result = img;
    } catch (err) {
      showNoti("error", err);
    }

    setLoading(false);
    return result
  };

  const onExit = () => {
    dispatch(setStep('upload'));
    dispatch(setLayers([]));
    dispatch(setBackupLayers([]));
    dispatch(setRedoStep(0));
  }

  return (
    <DashboardLayout
      page="my-feed"
      caption="Personal Feed"
      license={LICENSE.PROFESSIONAL}
      message="Welcome to Creativio AI"
    >
      <div className="magic-remover">
        {step === "upload" && <UploadStep />}
        {step === "edit" && (
          <BrushCanvas
            maskType="white"
            ref={brushCanvasRef}
            mainCaption="Magic"
            subCaption="Remover Tool"
            image={{
              src: resultImage,
              width: imageSize.width,
              height: imageSize.height
            }}
            orgImage={{
              src: selectedImage,
              width: orgImageSize.width,
              height: orgImageSize.height
            }}
            onRemove={onEditImage}
            onExit={onExit}
            history={{
              redoStep,
              layers,
              backupLayers,
              setRedoStep: (step) => dispatch(setRedoStep(step)),
              setLayers: (layers) => dispatch(setLayers(layers)),
              setBackupLayers: (backupLayers) => dispatch(setBackupLayers(backupLayers))
            }}
          />
        )}
      </div>
    </DashboardLayout>
  );
}
