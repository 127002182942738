import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import { ReactComponent as IconRemix } from "assets/imgs/modal/remix.svg";
import { ReactComponent as IconCopy } from "assets/imgs/modal/copy.svg";
import { ReactComponent as IconCopyActive } from "assets/imgs/modal/copy_active.svg";
import { ReactComponent as IconEnhance } from "assets/imgs/modal/enhance.svg";
import { ReactComponent as IconDownload } from "assets/imgs/modal/download.svg";
import { CButton } from "components/atoms/CButton";

import {
    varRatioOptions,
    varColorToneOptions,
    varLightningOptions,
    varCompositionOptions,
} from "pages/Create/variable";
import { ReactComponent as ArrowForward } from "assets/imgs/editor/arrow_forward.svg";
import { ReactComponent as ArrowBack } from "assets/imgs/editor/arrow_back.svg";
import "./styles.scss";
import { CTextarea } from "components/atoms/CTextarea";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useContext, useEffect, useState } from "react";

export function ImageModal({
    show = false,
    image = {},
    images = [],
    onClose = () => { },
}) {
    const { showNoti } = useContext(LayoutContext);
    const [activeImg, setActiveImg] = useState({});
    const [isCopy, setIsCopy] = useState(false);
    const [isPrev, setIsPrev] = useState(false);
    const [isNext, setIsNext] = useState(false);

    const getPosition = (arrow, img, imgs) => {
        if (imgs.length === 0) return false;

        if (img) {
            const curIndex = imgs.findIndex((k) => k.id === img.id);
            if (curIndex + 1 === imgs.length) {
                // it is last item
                if (arrow === "prev") return true;
                else return false;
            } else if (curIndex === 0) {
                if (arrow === "prev") return false;
                else return true;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const convertDate = (date) => {
        const dateString = date;
        const dateObject = new Date(dateString);
        return dateObject.toLocaleDateString("en-US");
    };
    const getAspectRatioById = (id) => {
        const option = varRatioOptions.find((option) => option.id === id);
        return option ? option.label : "None";
    };
    const getColorToneById = (id) => {
        const option = varColorToneOptions.find((option) => option.label === id);
        return option ? option.label : "None";
    };
    const getLightningById = (id) => {
        const option = varLightningOptions.find((option) => option.label === id);
        return option ? option.label : "None";
    };
    const getcompositionById = (id) => {
        const option = varCompositionOptions.find((option) => option.label === id);
        return option ? option.label : "None";
    };
    const copyContent = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setIsCopy(true);
            setTimeout(() => {
                setIsCopy(false);
            }, 3000);
        } catch (err) {
            showNoti("error", "Failed to copy: ", err);
        }
    };
    const handleDownload = (imageUrl, fileName) => {
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error downloading image:", error);
                showNoti("error", "Error downloading image");
            });
    };

    const onReadMore = (arrow) => {
        if (arrow === "prev") {
            const curIndex = images.findIndex((k) => k.id === activeImg.id);
            const prevIndex = curIndex - 1;
            setActiveImg(images[prevIndex]);
            setIsPrev(getPosition("prev", images[prevIndex], images));
            setIsNext(getPosition("next", images[prevIndex], images));
        } else {
            const curIndex = images.findIndex((k) => k.id === activeImg.id);
            const nextIndex = curIndex + 1;
            setActiveImg(images[nextIndex]);
            setIsPrev(getPosition("prev", images[nextIndex], images));
            setIsNext(getPosition("next", images[nextIndex], images));
        }
    };
    useEffect(() => {
        if (image) {
            setActiveImg(image);
            setIsPrev(getPosition("prev", image, images));
            setIsNext(getPosition("next", image, images));
        }
    }, [image]);
    const handleCloseModal = () => {
        setActiveImg({});
        onClose();
    };

    return (
        <Modal
            isOpen={show}
            ariaHideApp={false}
            onClick={handleCloseModal}
            className="image-modal"
        >
            <IconClose className="cursor-pointer ico-close" onClick={handleCloseModal} />
            {isPrev && (
                <div
                    onClick={() => onReadMore("prev")}
                    className="arrow-action-btn back"
                >
                    <ArrowBack />
                </div>
            )}
            {isNext && (
                <div
                    onClick={() => onReadMore("next")}
                    className="arrow-action-btn forward"
                >
                    <ArrowForward />
                </div>
            )}
            {activeImg && (
                <div className="content-area">
                    <div className="image-area">
                        <img src={activeImg.image} alt="generation result" />
                    </div>
                    <div className="property-area">
                        <p className="txt-caption">Prompt Text:</p>
                        <CTextarea
                            addClass="mt-5 modal"
                            placeholder="Ex: Beautiful flowers and a blob object frozen in a block of ice, isolated on warm-grey background"
                            active={true}
                            readOnly={true}
                            value={activeImg.prompt_text + " " + activeImg.negative_text}
                        ></CTextarea>
                        <div className="action-area mt-10">
                            <CButton
                                addClass="gradient p-5I"
                                active={true}
                                onClick={() =>
                                    copyContent(
                                        activeImg.prompt_text + " " + activeImg.negative_text
                                    )
                                }
                            >
                                {isCopy ? <IconCopyActive /> : <IconCopy />}
                                &nbsp; Copy Prompt
                            </CButton>
                            {/* <div className='cursor-pointer btn'>
                                <IconEnhance />
                                &nbsp; Image to Image
                            </div>
                            <div className='cursor-pointer btn'>
                                <IconRemix />
                                &nbsp; Remix Prompt
                            </div> */}
                            <CButton
                                addClass="gradient p-5I"
                                active={true}
                                onClick={() => handleDownload(activeImg.image, "download.png")}
                            >
                                <IconDownload />
                                &nbsp; Download
                            </CButton>
                        </div>
                        <p className="txt-caption mt-15">Generated Image Properties:</p>
                        <div className="property-fields mt-5">
                            <div className="row">
                                <div className="key">Aspect Ratio:</div>
                                <div className="value">{getAspectRatioById(activeImg.aspect_ratio)}</div>
                            </div>
                            <div className="row">
                                <div className="key">Created:</div>
                                <div className="value">{convertDate(activeImg.created_at)}</div>
                            </div>
                            <div className="row">
                                <div className="key">Width & Height:</div>
                                <div className="value">{`${activeImg.width} x ${activeImg.height} px`}</div>
                            </div>
                            <div className="row">
                                <div className="key">Seed:</div>
                                <div className="value">{activeImg.seed}</div>
                            </div>
                            <div className="row">
                                <div className="key">Steps:</div>
                                <div className="value">{activeImg.steps}</div>
                            </div>
                            <div className="row">
                                <div className="key">CFG Scale:</div>
                                <div className="value">{activeImg.scale}</div>
                            </div>
                            <div className="row">
                                <div className="key">Content Type:</div>
                                <div className="value">{activeImg.content_type}</div>
                            </div>
                            <div className="row">
                                <div className="key">Style Effects:</div>
                                <div className="value">{activeImg.style}</div>
                            </div>
                            <div className="row">
                                <div className="key">Color & Tone:</div>
                                <div className="value">{getColorToneById(activeImg.color_tone)}</div>
                            </div>
                            <div className="row">
                                <div className="key">Lighting:</div>
                                <div className="value">{getLightningById(activeImg.lightning)}</div>
                            </div>
                            <div className="row">
                                <div className="key">Composition:</div>
                                <div className="value">{getcompositionById(activeImg.composition)}</div>
                            </div>
                            <div className="row">
                                <div className="key">Engine Model:</div>
                                <div className="value">{activeImg.model}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
}
