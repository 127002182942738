import { ReactComponent as PanSvg } from "assets/imgs/studioCreator/pan.svg";
import { ReactComponent as SelectSvg } from "assets/imgs/studioCreator/select.svg";
import { ReactComponent as BrushSvg } from "assets/imgs/studioCreator/paint.svg";
// import { ReactComponent as ZoomSvg } from "assets/imgs/studioCreator/zoom.svg";
import { ReactComponent as TextSvg } from "assets/imgs/studioCreator/text.svg";
import { ReactComponent as TextEraserSvg } from "assets/imgs/studioCreator/textEraser.svg";
import { ReactComponent as EraserSvg } from "assets/imgs/studioCreator/eraser.svg";
import { ReactComponent as BackgroundRemoveSvg } from "assets/imgs/studioCreator/removeBackground.svg";
// import { ReactComponent as LightSvg } from "assets/imgs/studioCreator/light.svg";
import { ReactComponent as UndoSvg } from "assets/imgs/studioCreator/undo.svg";
import { ReactComponent as RedoSvg } from "assets/imgs/studioCreator/redo.svg";
import { ReactComponent as MoveSvg } from "assets/imgs/studioCreator/move.svg";
// import { ReactComponent as DenoizeSvg } from "assets/imgs/studioCreator/noize.svg";
import { ReactComponent as UploadSvg } from "assets/imgs/studioCreator/upload.svg";
import { ReactComponent as DownloadSvg } from "assets/imgs/studioCreator/download.svg";

const ACTION_MODES = {
  pan: "pan",
  select: "select",
  brush: "brush",
  eraser: "eraser",
  denoize: "denoize",
  relight: "relight",
  text: "text",
};

const EDITOR_ACTION_TYPE = {
  ...ACTION_MODES,
  undo: "undo",
  redo: "redo",
  upload: "upload",
  download: "download",
  zoom: "zoom",
  backgroundRemove: "backgroundRemove",
  upscale: "upscale",
  removeText: "removeText",
};

const EDITOR_ACTIONS = [
  {
    type: EDITOR_ACTION_TYPE.pan,
    Icon: PanSvg,
    title: "Pan - Move canvas (H)",
  },
  {
    type: EDITOR_ACTION_TYPE.select,
    Icon: SelectSvg,
    title: "Click - Select Objects (V)",
  },
  {
    type: EDITOR_ACTION_TYPE.brush,
    Icon: BrushSvg,
    title: "Brush - Create Masks (B)",
  },
  {
    type: EDITOR_ACTION_TYPE.text,
    Icon: TextSvg,
    title: "Add Text Layer (T)",
  },
  {
    type: EDITOR_ACTION_TYPE.removeText,
    Icon: TextEraserSvg,
    title: "Remove Text Tool (R)",
  },
  // {
  //   type: EDITOR_ACTION_TYPE.zoom,
  //   Icon: ZoomSvg,
  //   title: "Zoom out",
  // },
  {
    type: EDITOR_ACTION_TYPE.eraser,
    Icon: EraserSvg,
    title: "Eraser (E)",
  },
  {
    type: EDITOR_ACTION_TYPE.backgroundRemove,
    Icon: BackgroundRemoveSvg,
    title: "Remove Background (Q)",
  },
  // {
  //   type: EDITOR_ACTION_TYPE.relight,
  //   Icon: LightSvg,
  //   title: "Relight - Manipulate lightnings",
  // },
  {
    type: EDITOR_ACTION_TYPE.undo,
    Icon: UndoSvg,
    title: "Undo Action (Ctrl + Z)",
  },
  {
    type: EDITOR_ACTION_TYPE.redo,
    Icon: RedoSvg,
    title: "Redo Action (Ctrl + X)",
  },
  {
    type: EDITOR_ACTION_TYPE.upscale,
    Icon: MoveSvg,
    title: "Upscale Image (U)",
  },
  // {
  //   type: EDITOR_ACTION_TYPE.denoize,
  //   Icon: DenoizeSvg,
  //   title: "Denoise Image",
  // },
  {
    type: EDITOR_ACTION_TYPE.upload,
    Icon: UploadSvg,
    title: "Upload Image (Ctrl + U)",
  },
  {
    type: EDITOR_ACTION_TYPE.download,
    Icon: DownloadSvg,
    title: "Download Image (Ctrl + D)",
  },
];

const HOTKEYS = {
  KeyH: EDITOR_ACTION_TYPE.pan,
  KeyV: EDITOR_ACTION_TYPE.select,
  KeyB: EDITOR_ACTION_TYPE.brush,
  KeyT: EDITOR_ACTION_TYPE.text,
  KeyR: EDITOR_ACTION_TYPE.removeText,
  KeyE: EDITOR_ACTION_TYPE.eraser,
  KeyQ: EDITOR_ACTION_TYPE.backgroundRemove,
  KeyU: EDITOR_ACTION_TYPE.upscale
}

const CTRL_HOTKEYS = {
  KeyU: EDITOR_ACTION_TYPE.upload,
  KeyZ: EDITOR_ACTION_TYPE.undo,
  KeyX: EDITOR_ACTION_TYPE.redo,
  KeyD: EDITOR_ACTION_TYPE.download,
}

// ZOOM
const DEFAULT_ZOOM = 1;
const MIN_ZOOM = 0.25;
const MAX_ZOOM = 3;
const ZOOM_STEP = 0.25;

// SIZES
const CELL_SIZE = 20;
const CELL_LINE_SIZE = 3;
const MAIN_OBJECT_SIZE = 512;
const RESIZE_RECT_LINE_SIZE = 2;
const ROTATION_LINE_WIDTH = 25;
const CORNER_CIRCLE_RADIUS = 6;
const TEXT_CORNER_CIRCLE_RADIUS = 4;
const MIN_BRUSH_SIZE = 5;
const MAX_BRUSH_SIZE = 40;
const DEFAULT_BRUSH_SIZE = 30;
const MIN_FONT_SIZE = 8;
const MAX_FONT_SIZE = 150;

// COLORS
const MAIN_OBJECT_FILL_COLOR = "rgba(217, 217, 217, 0.40)";
const MAIN_OBJECT_STROKE_COLOR = "#760CFE";
const RESIZE_RECT_STROKE_COLOR = "#7371fc";
const TEXT_RECT_STROKE_COLOR = "#413FC5";
const RESIZE_RECT_FILL_COLOR = "#fff";

// const CANVAS_BACKGROUND_COLOR = "#1b1b1b";
const CANVAS_GRID_COLOR = "#393939";

const UPLOAD_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
];

const DRAW_OBJECT_TYPES = {
  image: "image",
  rect: "rect",
  brush: "brush",
  eraser: "eraser",
  group: "group",
  text: "text",
  mainRect: "mainRect",
};

const MOVEMENT_TYPES = {
  move: "move",
  resize_A: "resize_A",
  resize_B: "resize_B",
  resize_C: "resize_C",
  resize_D: "resize_D",
  resize_AB: "resize_AB",
  resize_CD: "resize_CD",
  resize_BC: "resize_BC",
  resize_AD: "resize_AD",
  rotation: "rotation",
};

// CANVAS IDS
const MAIN_CANVAS = "main-canvas";
const BACKGROUND_CANVAS = "background-canvas";
const CONTENT_CANVAS = "content-canvas";
const CURSOR_CANVAS = "cursor-canvas";
const BRUSH_CANVAS = "brush-canvas";
const ERASER_CANVAS = "eraser-canvas";
const TEXT_INPUT = "text-input";
const LOCK_BUTTON = "lock-btn";
const MAIN_OBJECT_SLIDER = "main-object-slider";
const MAIN_OBJECT_FILL_RECT = "main-object-fill-rect";
const CONTROLS_CANVAS = "controls-canvas";
const FILE_INPUT_ID = 'file-input-id'

export {
  EDITOR_ACTION_TYPE,
  EDITOR_ACTIONS,
  ACTION_MODES,
  DEFAULT_ZOOM,
  MIN_ZOOM,
  MAX_ZOOM,
  ZOOM_STEP,
  CELL_SIZE,
  CELL_LINE_SIZE,
  MAIN_OBJECT_FILL_COLOR,
  MAIN_OBJECT_STROKE_COLOR,
  CANVAS_GRID_COLOR,
  MAIN_OBJECT_SIZE,
  UPLOAD_FILE_TYPES,
  DRAW_OBJECT_TYPES,
  MOVEMENT_TYPES,
  RESIZE_RECT_STROKE_COLOR,
  RESIZE_RECT_FILL_COLOR,
  RESIZE_RECT_LINE_SIZE,
  ROTATION_LINE_WIDTH,
  CORNER_CIRCLE_RADIUS,
  MIN_BRUSH_SIZE,
  MAX_BRUSH_SIZE,
  DEFAULT_BRUSH_SIZE,
  MAIN_CANVAS,
  BACKGROUND_CANVAS,
  CONTENT_CANVAS,
  CURSOR_CANVAS,
  BRUSH_CANVAS,
  ERASER_CANVAS,
  TEXT_INPUT,
  TEXT_RECT_STROKE_COLOR,
  TEXT_CORNER_CIRCLE_RADIUS,
  FILE_INPUT_ID,
  MIN_FONT_SIZE,
  LOCK_BUTTON,
  MAIN_OBJECT_SLIDER,
  MAIN_OBJECT_FILL_RECT,
  MAX_FONT_SIZE,
  CONTROLS_CANVAS,
  HOTKEYS,
  CTRL_HOTKEYS
};
