import { Link } from "react-router-dom";
import { useCallback } from "react";
import { Slide } from "react-awesome-reveal";
import { AI_TOOLS, OPTIONS } from "./contants";
import ProPlan from "./components/ProPlan";

import { DashboardLayout } from "components/layouts/DashboardLayout";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as ChartUp } from "assets/imgs/chartup.svg";
import { ReactComponent as Stars } from "assets/imgs/stars.svg";
import { CButton } from "components/atoms/CButton";
import { CDropdown } from "components/atoms/CDropdown";
import { CInput } from "components/atoms/CInput";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import { PhotoCard } from "components/atoms/PhotoCard";

import "./styles.scss";
import useContent from "hooks/studio/useContent";
import { LICENSE } from "utils/constants";

export function Dashboard() {
  const { showNoti, setLoading, showImageModal, checkToken, scaleImage, user } =
    useContext(LayoutContext);
  const { onImportImage } = useContent()
  const [activeTrend, setActiveTrend] = useState("trending"); // trending || new || top
  const [activeMode, setActiveMode] = useState("all"); // all | upscaled
  const [viewOptions, setViewOptions] = useState(OPTIONS);
  const [activeViewOpt, setActiveViewOpt] = useState("all"); // all | upscaled
  const [keyword, setKeyword] = useState("");
  const [trendImgs, setTrendImgs] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSearch = () => {
    getViewImages(activeTrend, activeViewOpt, keyword, 1);
  };

  const handleLike = async (image_id, liked) => {
    try {
      checkToken();
      await getApi("/like", "POST", {
        image_id,
        is_like: !liked ? "yes" : "no",
      });
      const tmp = [...trendImgs];
      tmp.forEach((obj) => {
        if (obj.id === image_id) {
          obj.liked = !liked;
          if (!liked) obj.like_ct = Number(obj.like_ct) + 1;
          else obj.like_ct = Number(obj.like_ct) - 1;
        }
      });
      setTrendImgs(tmp);
    } catch (err) {
      showNoti("error", err);
    }
  };
  const handleDownload = (imageUrl, fileName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        showNoti("error", "Error downloading image");
      });
  };
  const onClickImageMode = (tp) => {
    if (tp !== activeTrend) {
      getViewImages(tp, activeViewOpt, keyword, 1);
    }
    setActiveTrend(tp);
  };

  const getViewImages = useCallback(
    async (
      trend = "trending",
      viewOption = "all",
      searchString = keyword,
      curPage = 1
    ) => {
      try {
        setLoading(true);
        setIsLoading(true);
        checkToken();
        const data = await getApi("/getImages", "POST", {
          page_size: 20,
          feed_type: "all",
          view_category: trend,
          keyword: searchString,
          generate_type: viewOption,
          page: curPage,
        });
        // Store the fetched images data into local storage
        localStorage.setItem('view_category', trend);
        localStorage.setItem('keyword', searchString);
        localStorage.setItem('generate_type', viewOption);
        setCurrentPage(curPage);
        if (curPage > 1) {
          setTrendImgs([...trendImgs, ...data.images]);
        } else {
          setTrendImgs(data.images);
        }
        setIsLast(data.last);
        
      } catch (err) {
        showNoti("error", err);
      }
      setIsFetch(false);
      setLoading(false);
      setIsLoading(false);
    },
    [checkToken, keyword, setLoading, showNoti, trendImgs]
  );

  const onReadMore = useCallback(() => {
    let curPage = currentPage;
    curPage++;
    getViewImages(activeTrend, activeViewOpt, keyword, curPage);
  }, [activeTrend, activeViewOpt, currentPage, getViewImages, keyword]);

  const handleOptRatio = useCallback(
    (id, index, value) => {
      switch (id) {
        case "view_option":
          const customOptions = [...viewOptions];
          customOptions.forEach((el) => (el.checked = false));
          customOptions[index].checked = value;
          getViewImages(activeTrend, customOptions[index].id, keyword, 1);
          setActiveViewOpt(customOptions[index].id);
          setViewOptions(customOptions);
          break;
        default:
      }
    },
    [activeTrend, getViewImages, keyword, viewOptions]
  );

  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };

  useEffect(() => {
    getViewImages();
    // eslint-disable-next-line
  }, []);

  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      const scrollPercentage = (scrollTop / (scrollHeight - offsetHeight)) * 100;
      if (scrollPercentage > 95) {
        setIsFetch(true)
      }
    },
    [isLast, onReadMore]
    
  );

  useEffect(() => {
    let curPage = currentPage;
    curPage++;
    if(curPage > 1) {
      if (isLast && isFetch) {
        onReadMore(); 
      };
    }
  }, [ isFetch, isLast]);

  const handleEdit = (image) => {
    if (user?.user?.license && !user.user.license.includes(LICENSE.PROFESSIONAL)) {
      showNoti(
        "info",
        `You need to upgrade to a Pro license to unlock this feature.`
      ); return;
    }
    onImportImage(image);
  }


  return (
    <DashboardLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome to Creativio AI"
    >
      <div id="dashboard" className="body-container" onScroll={handleScroll}>
        <ProPlan user={user} />
        <div className="section1">
          <Slide>
            <h1>
              <span>Featured</span> AI Tools
            </h1>
          </Slide>
          <ul className="cards-container cards">
            {AI_TOOLS.map((tool, index) => (
              <li key={index} className="cards__list-item ">
                <Link className="card" to={tool.link}>
                  {tool.Image}

                  <div className="card__content">
                    <h3 className="card__title">{tool.title}</h3>
                    <h4 className="card__subtitle">{tool.subtitle}</h4>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="section2">
          <Slide>
            <h1>
              <span>Recent</span> Creations
            </h1>
          </Slide>
          <div className="btn-actions">
            <CButton
              addClass="gradient"
              active={activeTrend === "trending"}
              onClick={() => onClickImageMode("trending")}
            >
              Trending
            </CButton>
            <CButton
              addClass="gradient"
              active={activeTrend === "new"}
              onClick={() => onClickImageMode("new")}
            >
              <Stars className="btn-icon" />
              New
            </CButton>
            <CButton
              addClass="gradient"
              active={activeTrend === "top"}
              onClick={() => onClickImageMode("top")}
            >
              <ChartUp className="btn-icon" />
              Top
            </CButton>
            <CDropdown
              id="view_option"
              addClass="w-220"
              showIcon={false}
              action={handleOptRatio}
              activeMenu={getActiveMenu(viewOptions)}
              menu={viewOptions}
            />
          </div>
          <div className="btn-group mt-15">
            <div className="search-area">
              <CInput
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                addClass="full text-white"
              />
              <CButton addClass="gradient" active={true} onClick={onSearch}>
                Search
              </CButton>
            </div>
            <div className="btn-mode">
              <CButton
                addClass="gradient"
                active={activeMode === "all"}
                onClick={() => setActiveMode("all")}
              >
                All
              </CButton>
              <CButton
                addClass="gradient"
                active={activeMode === "upscaled"}
                onClick={() => setActiveMode("upscaled")}
              >
                Upscaled
              </CButton>
            </div>
          </div>
          <div className="trend-area mt-25">
            {trendImgs && trendImgs.length > 0 ? (
              trendImgs.map((k, index) => (
                <PhotoCard
                  key={`activeTrend_${index}`}
                  image={k}
                  images={trendImgs}
                  onView={showImageModal}
                  onUpScale={scaleImage}
                  onDownload={handleDownload}
                  onLike={handleLike}
                  onEdit={handleEdit}
                />
              ))
            ) : (
              <div className="no-records-message">
                <p>No records found.</p>
              </div>
            )}
          </div>
          {isLast && (trendImgs.length > 0) && <div className="text-left mt-20 mb-20"><CButton addClass="gradient" active={true} onClick={onReadMore}>Load More</CButton></div>}
        </div>
      </div>
    </DashboardLayout>
  );
}
