export const getScale = (state) => state.studio.present.scale;
export const getActiveMode = (state) => state.studio.present.activeMode;
export const getMainObjectLocked = (state) =>
  state.studio.present.mainObjectLocked;
export const getIsUserInteract = (state) => state.studio.present.isUserInteract;
export const getLayers = (state) => state.studio.present.layers;
export const getOffsetX = (state) => state.studio.present.offsetX;
export const getOffsetY = (state) => state.studio.present.offsetY;
export const getMovementType = (state) => state.studio.present.movementType;
export const getCanvasRef = (state) => state.studio.present.canvasRef;
export const getBrushCanvasRef = (state) => state.studio.present.brushCanvasRef;
export const getEraserCanvasRef = (state) =>
  state.studio.present.eraserCanvasRef;
export const getCursorCanvasRef = (state) =>
  state.studio.present.cursorCanvasRef;
export const getBackgroundCanvasRef = (state) =>
  state.studio.present.backgroundCanvasRef;
export const getSelectedItem = (state) => state.studio.present.selectedItem;
export const getBrushLayers = (state) => state.studio.present.brushLayers;
export const getBrushSize = (state) => state.studio.present.brushSize;
export const getEraserSize = (state) => state.studio.present.eraserSize;
export const getContentCanvasRef = (state) =>
  state.studio.present.contentCanvasRef;
export const getFont = (state) => state.studio.present.font;
export const getGeneratedImages = (state) =>
  state.studio.present.generatedImages;
export const getMaskType = (state) => state.studio.present.maskType;
export const getControlsCanvasRef = (state) =>
  state.studio.present.controlsCanvasRef;
export const getPositiveText = (state) => state.studio.present.positiveText;
export const getNegativeText = (state) => state.studio.present.negativeText;
export const getIsUserChooseImage = (state) =>
  state.studio.present.isUserChooseImage;
export const getPresentState = (state) => state.studio.present;
export const getPastState = (state) => state.studio.past;
export const getFutureState = (state) => state.studio.future;
export const getIsShownNotifications = (state) =>
  state.app.isShownNotifications;
export const getIsDownloading = (state) => state.studio.present.isDownloading