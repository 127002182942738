import { CButton } from "components/atoms/CButton";
import "./index.scss";
import { Slide } from "react-awesome-reveal";
import React from 'react';

const formatEntryValue = (value) => {
  if (typeof value === "object" && value) {
    return JSON.stringify(value);
  }

  return value;
};

export const getCodeStyles = (code) => {
  const formatCode = +code;

  if (formatCode >= 200 && formatCode <= 299) {
    return "code-success";
  }

  if (formatCode >= 400 && formatCode <= 499) {
    return "code-warning";
  }

  if (formatCode >= 500 && formatCode <= 599) {
    return "code-error";
  }
};

export const getCodeLabel = (code) => {
  const formatCode = +code;

  if (formatCode >= 200 && formatCode <= 299) {
    return "Success";
  }

  if (formatCode >= 400 && formatCode <= 499) {
    return "Failed";
  }

  if (formatCode >= 500 && formatCode <= 599) {
    return "Server error";
  }
};

const RealtimeTerminal = ({ data }) => {
  return (
    <>
      <Slide className="flex1">
        <h1>
          <span>Realtime</span> Terminal Logs
        </h1>
      </Slide>
      <div className="realtime-terminal">
        <div className="realtime-terminal__wrap">
          <ul className="realtime-terminal__list">
            {data.data.map(({ id, updatedAt, ...item }) => {
              const entries = Object.entries(item);
              return (
                <React.Fragment key={id}>
                  <li>
                    <ul className="realtime-terminal__inner-list">
                      {entries.map(([name, value], index) => {
                        return (
                          <li key={index}>
                            <span className="realtime-terminal__title">
                              {name}:
                            </span>
                            <p
                              className={`realtime-terminal__value ${name === "status" ? getCodeStyles(value) : ""
                                }`}
                            >
                              {formatEntryValue(value)}{" "}
                              {name === "status" && getCodeLabel(value)}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="realtime-terminal__divider">
                    ------------------- End Call --------------------
                  </li>
                </React.Fragment>
              );
            })}
            {data.hasMore && (
              <li>
                <CButton addClass="primary" active onClick={data.onLoad}>
                  Load more
                </CButton>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default RealtimeTerminal;
