/**
 * Converts a Y-coordinate from real-world to virtual space.
 * @param {number} y - The real-world Y-coordinate.
 * @param {number} offsetY - The Y offset.
 * @param {number} scale - The scale factor.
 * @returns {number} - The virtual Y-coordinate.
 */
export const toVirtualY = (y = 0, offsetY = 0, scale = 1) => {
  return (y + offsetY) * scale;
};

/**
 * Converts an X-coordinate from real-world to virtual space.
 * @param {number} x - The real-world X-coordinate.
 * @param {number} offsetX - The X offset.
 * @param {number} scale - The scale factor.
 * @returns {number} - The virtual X-coordinate.
 */
export const toVirtualX = (x = 0, offsetX = 0, scale = 1) => {
  return (x + offsetX) * scale;
};

/**
 * Converts a virtual X-coordinate to the real-world X-coordinate.
 * @param {number} xVirtual - The virtual X-coordinate.
 * @param {number} offsetX - The X offset.
 * @param {number} scale - The scale factor.
 * @returns {number} - The real-world X-coordinate.
 */
export const toRealX = (xVirtual = 0, offsetX = 0, scale = 1) => {
  return xVirtual / scale - offsetX;
};

/**
 * Converts a virtual Y-coordinate to the real-world Y-coordinate.
 * @param {number} yVirtual - The virtual Y-coordinate.
 * @param {number} offsetY - The Y offset.
 * @param {number} scale - The scale factor.
 * @returns {number} - The real-world Y-coordinate.
 */
export const toRealY = (yVirtual = 0, offsetY = 0, scale = 0) => {
  return yVirtual / scale - offsetY;
};

/**
 * Transforms a point using a 2x3 matrix.
 * @param {object} point - The point with 'x' and 'y' coordinates.
 * @param {number[]} m - The transformation matrix.
 * @returns {object} - The transformed point with 'x' and 'y' coordinates.
 */
export const getTransformedPoint = function ({ x = 0, y = 0 }, m = []) {
  return {
    x: x * m[0] + y * m[2] + m[4],
    y: x * m[1] + y * m[3] + m[5],
  };
};

/**
 * Calculates the angle between two points.
 * @param {number} x1 - The X-coordinate of the first point.
 * @param {number} y1 - The Y-coordinate of the first point.
 * @param {number} x2 - The X-coordinate of the second point.
 * @param {number} y2 - The Y-coordinate of the second point.
 * @returns {number} - The angle in radians.
 */
export function calculateAngle(x1 = 0, y1 = 0, x2 = 0, y2 = 0) {
  return Math.atan2(y2 - y1, x2 - x1);
}

/**
 * Converts an X-coordinate from real-world to rotated space.
 * @param {number} x - The real-world X-coordinate.
 * @param {number} y - The real-world Y-coordinate.
 * @param {number} rotation - The rotation angle in radians (optional, default is 0).
 * @returns {number} - The rotated X-coordinate.
 */
export const toRotatedX = (x = 0, y = 0, rotation = 0) => {
  const newX = x * Math.cos(rotation) - y * Math.sin(rotation);
  return newX;
};

/**
 * Converts a Y-coordinate from real-world to rotated space.
 * @param {number} x - The real-world X-coordinate.
 * @param {number} y - The real-world Y-coordinate.
 * @param {number} rotation - The rotation angle in radians (optional, default is 0).
 * @returns {number} - The rotated Y-coordinate.
 */
export const toRotatedY = (x = 0, y = 0, rotation = 0) => {
  const newY = x * Math.sin(rotation) + y * Math.cos(rotation);
  return newY;
};

/**
 * Checks if a point is inside a polygon defined by its vertices.
 * @param {object} point - The point with 'x' and 'y' coordinates.
 * @param {object} vertices - The polygon's vertices with 'topLeft', 'topRight', 'bottomRight', and 'bottomLeft' properties.
 * @returns {boolean} - True if the point is inside the polygon, false otherwise.
 */
export function pointInPolygon(point, vertices) {
  const { topLeft, topRight, bottomRight, bottomLeft } = vertices;

  const x0 = point.x;
  const y0 = point.y;

  const x1 = topLeft.x;
  const y1 = topLeft.y;
  const x2 = topRight.x;
  const y2 = topRight.y;
  const x3 = bottomRight.x;
  const y3 = bottomRight.y;
  const x4 = bottomLeft.x;
  const y4 = bottomLeft.y;

  const d1 = (x0 - x1) * (y2 - y1) - (x2 - x1) * (y0 - y1);
  const d2 = (x0 - x2) * (y3 - y2) - (x3 - x2) * (y0 - y2);
  const d3 = (x0 - x3) * (y4 - y3) - (x4 - x3) * (y0 - y3);
  const d4 = (x0 - x4) * (y1 - y4) - (x1 - x4) * (y0 - y4);

  return (
    (d1 >= 0 && d2 >= 0 && d3 >= 0 && d4 >= 0) ||
    (d1 <= 0 && d2 <= 0 && d3 <= 0 && d4 <= 0)
  );
}

export function toScreenCoordinates(
  canvasX,
  canvasY,
  centerX,
  centerY,
  rotation = 0
) {
  const x =
    (canvasX - centerX) * Math.cos(-rotation) -
    (canvasY - centerY) * Math.sin(-rotation) +
    centerX;
  const y =
    (canvasX - centerX) * Math.sin(-rotation) +
    (canvasY - centerY) * Math.cos(-rotation) +
    centerY;
  return { x, y };
}
