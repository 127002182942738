import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { getActiveMode, getScale } from "store/utils";

import { StudioCanvas } from "components/molecules/StudioCanvas";
import { ReactComponent as ExitSvg } from "assets/imgs/studioCreator/exit.svg";
import { ReactComponent as ZoomInSvg } from "assets/imgs/studioCreator/zoomIn.svg";
import { ReactComponent as ZoomOutSvg } from "assets/imgs/studioCreator/zoomOut.svg";

import { ACTION_MODES } from "constants/studio";

import TextPopup from "components/molecules/TextPopup";

import useStudioCreator from "hooks/studio/useStudioCreator";

import StudioCanvasPrompt from "../StudioCanvasPrompt";

import "./index.scss";

export function StudioCanvasWrapper() {
  const scale = useSelector(getScale);
  const { onZoomIn, onZoomOut } = useStudioCreator();
  const activeMode = useSelector(getActiveMode);

  const formatZoom = useMemo(() => {
    return `${Math.round(scale * 100)}%`;
  }, [scale]);

  return (
    <section className="studio-canvas-wrapper apply-font">
      {activeMode === ACTION_MODES.text && <TextPopup />}
      <Link to="/" className="studio-canvas-wrapper__back-btn">
        <ExitSvg /> Exit editor
      </Link>
      <div className="studio-canvas-wrapper__zoom zoom-wrap">
        <div className="zoom-wrap__zoom-btns zoom-btns">
          <button className="zoom-btns__item" onClick={onZoomIn}>
            <ZoomInSvg />
          </button>
          <button className="zoom-btns__item" onClick={onZoomOut}>
            <ZoomOutSvg />
          </button>
        </div>
        <p className="zoom-wrap__info">{formatZoom}</p>
      </div>
      <StudioCanvasPrompt />
      <StudioCanvas />
    </section>
  );
}
