import { DashboardLayout } from "components/layouts/DashboardLayout";
import { Slide } from "react-awesome-reveal";
import "./styles.scss";
import { useCallback } from "react";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as ChartUp } from "assets/imgs/chartup.svg";
import { ReactComponent as Stars } from "assets/imgs/stars.svg";
import { CButton } from "components/atoms/CButton";
import { CDropdown } from "components/atoms/CDropdown";
import { CInput } from "components/atoms/CInput";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import { VideoCard } from "components/atoms/VideoCard";
import useContent from "hooks/studio/useContent";
import { LICENSE } from "utils/constants";

export function AnimationFeed() {
  const { onImportImage } = useContent();
  const { showNoti, setLoading, showVideoModal, checkToken, scaleImage, user } =
    useContext(LayoutContext);
  const [activeTrend, setActiveTrend] = useState("trending"); // trending || new || top
  // const [activeMode, setActiveMode] = useState("all"); // all | upscaled
  const [viewOptions, setViewOptions] = useState([
    {
      key: 1,
      id: "all",
      icon: null,
      color: "white",
      checked: true,
      label: "All Animations",
    },
    {
      key: 2,
      id: "personal",
      icon: null,
      color: "white",
      checked: false,
      label: "Personal Animations",
    },
  ]);

  const [activeViewOpt, setActiveViewOpt] = useState("all"); // all | upscaled
  const [trendVideos, setTrendVideos] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [isDeletable, setIsDeletable] = useState(false);

  // const onSearch = () => {
  //   getViewAnimations(activeTrend, activeViewOpt, 1);
  // };

  const handleLike = async (video_id, liked) => {
    try {
      checkToken();
      await getApi("/animation/likeVideo", "POST", {
        video_id,
        is_like: !liked ? "yes" : "no",
      });
      const tmp = [...trendVideos];
      tmp.forEach((obj) => {
        if (obj.id === video_id) {
          obj.liked = !liked;
          if (!liked) obj.like_ct = Number(obj.like_ct) + 1;
          else obj.like_ct = Number(obj.like_ct) - 1;
        }
      });

      setTrendVideos(tmp);
    } catch (err) {
      showNoti("error", err);
    }
  };

  const handleDelete = async (video_id) => {
    try {
      setLoading(true);
      checkToken();
      await getApi("/animation/deleteVideo", "POST", { video_id });
      showNoti("success", "Animation Deleted Successfully!");
      getViewAnimations(activeTrend, activeViewOpt);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const handleDownload = (videoUrl, fileName) => {
    fetch(videoUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading animation:", error);
        showNoti("error", "Error downloading animation");
      });
  };

  const onClickImageMode = (tp) => {
    if (tp !== activeTrend) {
      getViewAnimations(tp, activeViewOpt, 1);
    }
    setActiveTrend(tp);
  };

  const onReadMore = () => {
    let curPage = currentPage;
    curPage++;
    getViewAnimations(activeTrend, activeViewOpt, curPage);
  };

  const getViewAnimations = async (
    trend = "trending",
    viewOption = "all",
    curPage = 1
  ) => {
    try {
      setLoading(true);
      setIsLoading(true);
      checkToken();
      const data = await getApi("/animation/getAnimations", "POST", {
        page_size: 20,
        feed_type: viewOption,
        view_category: trend,
        page: curPage,
      });
      setCurrentPage(curPage);
      if (curPage > 1) {
        setTrendVideos([...trendVideos, ...data.videos]);
      } else {
        setTrendVideos(data.videos);
      }
      setIsLast(data.last);
    } catch (err) {
      showNoti("error", err);
    }
    setIsFetch(false);
    setLoading(false);
    setIsLoading(false);
  };

  const handleOptRatio = (id, index, value) => {
    switch (id) {
      case "view_option":
        const customOptions = [...viewOptions];
        customOptions.forEach((el) => (el.checked = false));
        customOptions[index].checked = value;
        getViewAnimations(activeTrend, customOptions[index].id, 1);
        setActiveViewOpt(customOptions[index].id);
        setViewOptions(customOptions);
        break;
      default:
    }
  };

  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };

  useEffect(() => {
    const myFeedElement = document.getElementById("my_feed");
    if (myFeedElement) {
      myFeedElement.addEventListener("scroll", handleScroll, true);
      return () => myFeedElement.removeEventListener("scroll", handleScroll, true);
    }
  }, [isLoading]);

  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      const scrollPercentage = (scrollTop / (scrollHeight - offsetHeight)) * 100;
      if (scrollPercentage > 95) {
        setIsFetch(true)
      }
    },
    [isLast, onReadMore]
  );

  useEffect(() => {
    getViewAnimations();
  }, []);

  useEffect(() => {
    if (getActiveMenu(viewOptions).id == 'personal') {
      setIsDeletable(true);
    }
    else {
      setIsDeletable(false);
    }
  }, [getActiveMenu(viewOptions)]);

  useEffect(() => {
    let curPage = currentPage;
    curPage++;
    if (curPage > 1) {
      if (isLast && isFetch) {
        onReadMore();
      };
    }
  }, [isFetch, isLast]);

  return (
    <DashboardLayout
      page="animation-feed"
      caption="Animation Feed"
      message="Welcome to Creativio AI"
    >
      <div id="animation_feed" className="body-container" onScroll={handleScroll}>
        <div className="section2">
          <Slide>
            <h1>
              <span>Animation</span> Feed
            </h1>
          </Slide>
          <div className="btn-actions">
            <CButton
              addClass="gradient"
              active={activeTrend === "trending"}
              onClick={() => onClickImageMode("trending")}
            >
              Trending
            </CButton>
            <CButton
              addClass="gradient"
              active={activeTrend === "new"}
              onClick={() => onClickImageMode("new")}
            >
              <Stars className="btn-icon" />
              New
            </CButton>
            <CButton
              addClass="gradient"
              active={activeTrend === "top"}
              onClick={() => onClickImageMode("top")}
            >
              <ChartUp className="btn-icon" />
              Top
            </CButton>
            <CDropdown
              id="view_option"
              addClass="w-220"
              showIcon={false}
              action={handleOptRatio}
              activeMenu={getActiveMenu(viewOptions)}
              menu={viewOptions}
            />
          </div>
          <div className="trend-area mt-25">
            {trendVideos && trendVideos.length !== 0 ?
              (trendVideos.map((k, index) => (
                <VideoCard
                  key={index}
                  video={k}
                  videos={trendVideos}
                  onView={showVideoModal}
                  onDownload={handleDownload}
                  onLike={handleLike}
                  deleteVideo={isDeletable}
                  onDelete={handleDelete}
                />
              ))) : (
                <div className="no-records-message">
                  <p>No records found.</p>
                </div>
              )}
          </div>
          {isLast && (trendVideos && trendVideos.length > 0) && <div className="text-left mt-20 mb-20"><CButton addClass="gradient" active={true} onClick={onReadMore}>Load More</CButton></div>}
        </div>
      </div>
    </DashboardLayout>
  );
}
