import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { DashboardLayout } from "components/layouts/DashboardLayout"
import { convertHeicImage } from "pages/Edit/utils";
import UploadView from "components/atoms/UploadView";
import { useContext, useEffect, useState } from "react";
import { getApi } from "utils/customNetwork";
import { useDispatch, useSelector } from "react-redux";
import { getImage, getResult } from "store/slices/imageToVideo/utils";
import { setImage, setResult } from "store/slices/imageToVideo/reducer";
import ReactSlider from "react-slider";
import { CButton } from "components/atoms/CButton";
import { LICENSE } from "utils/constants";
import { ReactComponent as Info } from "assets/imgs/info.svg";
import { Tooltip } from "react-tooltip";

import './index.scss';

const ImageToVideo = () => {
    const [stage, setStage] = useState(null); // edit || result
    const { setLoading, showNoti } = useContext(LayoutContext);
    const dispatch = useDispatch();
    const image = useSelector(getImage);
    const result = useSelector(getResult);
    const [file, setFile] = useState(null);
    const [scale, setScale] = useState(1.8); // [ 0 .. 10 ]
    const [motion, setMotion] = useState(127); // [ 1 .. 255 ]
    const [width, setWidth] = useState(768);
    const [height, setHeight] = useState(768);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) return;
        setStage(null);
        const reader = new FileReader();

        if (file.type === "" || file.type === "image/heic") {
            const blob = await convertHeicImage(file);
            reader.readAsDataURL(blob);
        } else {
            reader.readAsDataURL(file);
        }

        reader.onload = (event) => {
            const imageElement = new Image();
            imageElement.src = event.target.result;
            imageElement.onload = function () {
                setWidth(imageElement.width);
                setHeight(imageElement.height);
            }
            setFile(file);
            dispatch(setImage(reader.result));
            setStage('edit');
        };
    };

    const handleImageToVideo = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("type", "upload");
            formData.append("image", file);
            formData.append("scale", scale);
            formData.append("motion", motion);
            formData.append("height", height);
            formData.append("width", width);
            const result = await getApi('/animation/generateAnimation', 'POST', formData);
            dispatch(setResult(result.data));
            setStage('result');
        } catch (e) {
            showNoti('error', e);
        }
        setLoading(false);
    }

    useEffect(() => {
        setStage(null);
        dispatch(setImage(null));
        dispatch(setResult(null));
        setHeight(768);
        setWidth(768);
    }, []);

    const renderEdit = () => {
        return (
            image && (
                <section className="edit-section">
                    <div className="edit-item">
                        {image && <img src={image} alt='initial' className="image-container" />}
                    </div>
                    <div className="edit-options">
                        <div className="option-container">
                            <h3>Generate Image to Animation</h3>
                            <div className="slider-wrap">
                                <div className="slider-caption">
                                    <p className="mb-5">Scale</p>
                                    <p>{scale}</p>
                                </div>
                                <ReactSlider
                                    className="slider"
                                    thumbClassName="slider-thumb"
                                    trackClassName="slider-track"
                                    onChange={(count) => setScale(count)}
                                    value={scale}
                                    step={0.1}
                                    min={0}
                                    max={10}
                                />
                            </div>
                            <div className="slider-wrap">
                                <div className="slider-caption">
                                    <p className="mb-5">Motion</p>
                                    <p>{motion}</p>
                                </div>
                                <ReactSlider
                                    className="slider"
                                    thumbClassName="slider-thumb"
                                    trackClassName="slider-track"
                                    onChange={(count) => setMotion(count)}
                                    value={motion}
                                    min={1}
                                    max={255}
                                />
                            </div>
                            <div className="row mt-15">
                                <CButton
                                    addClass="gradient2 active full p-10I"
                                    mainClass="w-full"
                                    active={true}
                                    onClick={() => handleImageToVideo()}
                                >
                                    Generate Animation
                                </CButton>
                            </div>
                        </div>
                    </div>
                </section >
            )
        );
    };

    return <DashboardLayout
        page="image-to-animation"
        caption="Product Studio"
        license={LICENSE.ANIMATION}
        message="Enhance Your product Photography with AI"
    >
        <main id="enhance" className="body-container image-to-animation">
            <UploadView
                content={{
                    mainTitle: <><span>Image To Animation</span> Generation Tool&nbsp;
                        <Info className="toolinfo" data-tooltip-id="tip-info"
                            data-tooltip-variant="dark"
                            data-tooltip-content="You can upload an image with dimensions of 768x768, 1024x576, or 576x1024, and the tool will generate an animation based on it."
                        />
                        <Tooltip className="toolinfo1" id="tip-info" />
                    </>,
                    title: 'Upload an image with 768x768, 1024x576 or 576x1024 dimensions to convert it into the animation.',
                    subtitle: 'upload an image'
                }}
                onInput={handleImageUpload}
            />
            {stage === "edit" && renderEdit()}
            {stage === "result" &&
                result?.video && image &&
                <div>
                    <section className="video-section">
                        <div className="item">
                            <p className="description">Image</p>
                            {image && <img src={image} alt='initial' className="container" />}
                        </div>
                        <div className="item">
                            <p className="description">Animation</p>
                            {result?.video && <div className="video-wrap">
                                <video className="container" controls>
                                    <source src={result.video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>}
                        </div>
                    </section>
                    <div className="row mt-5 regenerate-button">
                        <CButton
                            addClass="gradient2 active full p-10I"
                            mainClass=""
                            active={true}
                            onClick={() => handleImageToVideo()}
                        >
                            ReGenerate Animation
                        </CButton>
                    </div>
                </div>
            }
        </main>
    </DashboardLayout>
}

export default ImageToVideo