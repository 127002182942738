import { DRAW_OBJECT_TYPES } from "constants/studio";
import DrawObject from "./DrawObject";
import { toVirtualX, toVirtualY } from "utils/studio/utils";

class BrushObject extends DrawObject {
  tmpCanvas = null;

  constructor({ points, meta, position, canvas, tmpCanvas }) {
    super({ type: DRAW_OBJECT_TYPES.brush, points, meta, position, canvas });
    this.tmpCanvas = tmpCanvas || this.createTmpCanvas();
  }

  getTmpCanvas() {
    return this.tmpCanvas;
  }

  getTmpContext() {
    return this.tmpCanvas?.getContext("2d");
  }

  drawOnTempCanvas({ scale, offsetX, offsetY, maskType, ctx }) {
    const points = this.points;

    if (!points?.length) return;
    const tmpContext = ctx || this.getTmpContext();

    if (!ctx) {
      const tmpCanvas = this.tmpCanvas;
      tmpContext.clearRect(0, 0, tmpCanvas.width, tmpCanvas.height);
    }

    if (!tmpContext) return;

    tmpContext.save();
    tmpContext.beginPath();
    tmpContext.moveTo(
      toVirtualX(points[0].x + this.offsetX, offsetX, scale),
      toVirtualY(points[0].y + this.offsetY, offsetY, scale)
    );

    for (let i = 0; i < points.length; i++) {
      tmpContext.lineTo(
        toVirtualX(points[i].x + this.offsetX, offsetX, scale),
        toVirtualY(points[i].y + this.offsetY, offsetY, scale)
      );
    }

    tmpContext.globalCompositeOperation = "xor";
    tmpContext.strokeStyle = `rgb(115, 113, 252)`;

    if (!!maskType) {
      tmpContext.strokeStyle = maskType === "white" ? "#fff" : "#000";
    }

    tmpContext.lineWidth = this.meta.lineWidth * scale;

    tmpContext.lineCap = "round";
    tmpContext.lineJoin = "round";

    if (!!maskType) {
      tmpContext.globalAlpha = 1;
    } else {
      tmpContext.globalAlpha = 0.5;
    }

    tmpContext.stroke();
    tmpContext.globalCompositeOperation = "source-over";
  }

  draw({ offsetX, offsetY, scale, ctx }) {
    const tmpCanvas = this.getTmpCanvas();
    const canvasCtx = ctx || this.getContext();

    this.drawOnTempCanvas({ scale, offsetX, offsetY });

    const canvas = this.getCanvas();
    canvasCtx.globalCompositeOperation = "xor";

    canvasCtx.drawImage(
      tmpCanvas,
      this.offsetX,
      this.offsetY,
      canvas.width,
      canvas.height
    );

    canvasCtx.globalCompositeOperation = "source-over";
  }

  checkIsSelected() {
    if (!this.points?.length) return false;
    return true;

    // TODO: Update logic of selection for erasing
    // const minX = Math.min(...this.data.map((point) => point.x));
    // const maxX = Math.max(...this.data.map((point) => point.x));
    // const minY = Math.min(...this.data.map((point) => point.y));
    // const maxY = Math.max(...this.data.map((point) => point.y));

    // const minVirtualX = toVirtualX(minX, offsetX, scale);
    // const minVirtualY = toVirtualY(minY, offsetY, scale);
    // const maxVirtualX = toVirtualX(maxX, offsetX, scale);
    // const maxVirtualY = toVirtualY(maxY, offsetY, scale);

    // const vertices = {
    //   topLeft: { x: minVirtualX, y: minVirtualY },
    //   topRight: { x: maxVirtualX, y: minVirtualY },
    //   bottomRight: { x: maxVirtualX, y: maxVirtualY },
    //   bottomLeft: { x: minVirtualX, y: maxVirtualY },
    // };

    // const isSelected = pointInPolygon({ x, y }, vertices);

    // return isSelected;
  }
}

export default BrushObject;
