import { DRAW_OBJECT_TYPES } from "constants/studio";
import ShapeObject from "./ShapeObject";
import EraserObject from "lib/drawings/EraserObject";
import LayerObject from "lib/LayerObject";

class GroupObject extends ShapeObject {
  objects = [];

  constructor(info) {
    super({ type: DRAW_OBJECT_TYPES.group, ...info });
  }

  addObject(object) {
    if (!(object instanceof LayerObject)) return;

    this.objects.push(object);

    object.setParentItem(this);
    object.setPosition(this.objects.length + 1);

    if (object instanceof EraserObject) {
      object.initialScaleX = this.scaleX;
      object.initialScaleY = this.scaleY;
      object.initialRotation = this.rotation;
    }

    // TODO: finish logic of changing x and y
    if (!this.x && !this.y) {
      this.x = object.x;
      this.y = object.y;
      this.width = object.width;
      this.height = object.height;
      this.originalHeight = object.height;
      this.originalWidth = object.width;
    }
  }

  draw({ offsetX, offsetY, scale, ...rest }) {
    const ctx = rest.ctx || this.getContext();

    ctx.save();

    for (const object of this.objects) {
      if (object instanceof EraserObject) {
        object.drawOnTempCanvas({
          offsetX,
          offsetY,
          scale,
          width: this.width,
          height: this.height,
          ...rest,
          originX: this.x,
          originY: this.y,

        });
      } else {
        object.draw({
          offsetX,
          offsetY,
          scale,
          ...rest,
          originX: this.x,
          originY: this.y,
        });
      }
    }

    ctx.restore();
  }

  drawOriginal({ ctx, ...props }) {
    const formatCtx = ctx || this.getContext();

    for (const object of this.objects) {
      const info = {
        ctx: formatCtx,
        ...props
      }

      if (object instanceof EraserObject) {
        // object.drawOnTempCanvas({
        //   width: this.originalWidth,
        //   height: this.originalHeight,
        //   ...info,
        // });
      } else {
        object.drawOriginal(info);
      }
    }
  }

  getObjects() {
    return this.objects;
  }

  resizeItem(movementType, { dx, dy, isShift }) {
    const oldScaleX = this.scaleX;
    const oldScaleY = this.scaleY;

    super.resizeItem(movementType, { dx, dy, isShift });
    const dxScale = this.scaleX / oldScaleX;
    const dyScale = this.scaleY / oldScaleY;

    for (const object of this.objects) {
      if (object instanceof EraserObject) {
        object.addScaleX(dxScale);
        object.addScaleY(dyScale);
      } else {
        object.scaleX = this.scaleX;
        object.scaleY = this.scaleY;
      }
      object.resizeItem && object.resizeItem(movementType, { dx, dy });
    }
  }

  clone() {
    const clonedGroup = super.clone();

    const newObjects = this.objects.map((object) => object.clone());

    clonedGroup.objects = newObjects;

    newObjects.forEach((object) => {
      object.parentItem = clonedGroup;
    });

    return clonedGroup;
  }

  addNewCanvas() {
    super.addNewCanvas();

    if (this.objects) {
      this.objects.forEach((object) => {
        object.setCanvas(this.canvas);
      });
    }
  }
}

export default GroupObject;
