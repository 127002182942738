import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import { ReactComponent as IconDownload } from "assets/imgs/modal/download.svg";
import { CButton } from "components/atoms/CButton";
import { ReactComponent as ArrowForward } from "assets/imgs/editor/arrow_forward.svg";
import { ReactComponent as ArrowBack } from "assets/imgs/editor/arrow_back.svg";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useContext, useEffect, useState } from "react";
import "./styles.scss";

export function VideoModal({
    show = false,
    video = {},
    videos = [],
    onClose = () => { },
}) {
    const { showNoti } = useContext(LayoutContext);
    const [activeVdo, setActiveVdo] = useState({});
    const [isPrev, setIsPrev] = useState(false);
    const [isNext, setIsNext] = useState(false);

    const getPosition = (arrow, vdo, vdos) => {
        if (vdos.length === 0) return false;

        if (vdo) {
            const curIndex = vdos.findIndex((k) => k.id === vdo.id);
            if (curIndex + 1 === vdos.length) {
                // it is last item
                if (arrow === "prev") return true;
                else return false;
            } else if (curIndex === 0) {
                if (arrow === "prev") return false;
                else return true;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const convertDate = (date) => {
        const dateString = date;
        const dateObject = new Date(dateString);
        return dateObject.toLocaleDateString("en-US");
    };

    const handleDownload = (videoUrl, fileName) => {
        fetch(videoUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error downloading video:", error);
                showNoti("error", "Error downloading video");
            });
    };

    const onReadMore = (arrow) => {
        if (arrow === "prev") {
            const curIndex = videos.findIndex((k) => k.id === activeVdo.id);
            const prevIndex = curIndex - 1;
            setActiveVdo(videos[prevIndex]);
            setIsPrev(getPosition("prev", videos[prevIndex], videos));
            setIsNext(getPosition("next", videos[prevIndex], videos));
        } else {
            const curIndex = videos.findIndex((k) => k.id === activeVdo.id);
            const nextIndex = curIndex + 1;
            setActiveVdo(videos[nextIndex]);
            setIsPrev(getPosition("prev", videos[nextIndex], videos));
            setIsNext(getPosition("next", videos[nextIndex], videos));
        }
    };

    useEffect(() => {
        if (video) {
            setActiveVdo(video);
            setIsPrev(getPosition("prev", video, videos));
            setIsNext(getPosition("next", video, videos));
        }
    }, [video]);

    const handleCloseModal = () => {
        setActiveVdo({});
        onClose();
    };

    return (
        <Modal
            isOpen={show}
            ariaHideApp={false}
            onClick={handleCloseModal}
            className="video-modal"
        >
            <IconClose className="cursor-pointer ico-close" onClick={handleCloseModal} />
            {isPrev && (
                <div
                    onClick={() => onReadMore("prev")}
                    className="arrow-action-btn back"
                >
                    <ArrowBack />
                </div>
            )}
            {isNext && (
                <div
                    onClick={() => onReadMore("next")}
                    className="arrow-action-btn forward"
                >
                    <ArrowForward />
                </div>
            )}
            {activeVdo && (
                <div className="content-area">
                    <div className="video-area">
                        <video controls width="500px" height="500px" src={activeVdo.video}>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="property-area">
                        <div className="action-area mt-10">
                            <CButton
                                addClass="gradient p-5I"
                                active={true}
                                onClick={() => handleDownload(activeVdo.video, "animation.mp4")}
                            >
                                <IconDownload />
                                &nbsp; Download
                            </CButton>
                        </div>
                        <p className="txt-caption mt-15">Generated Video Properties:</p>
                        <div className="property-fields mt-5">
                            <div className="row">
                                <div className="key">Created:</div>
                                <div className="value">{convertDate(activeVdo.created_at)}</div>
                            </div>
                            <div className="row">
                                <div className="key">Motion:</div>
                                <div className="value">{activeVdo.motion}</div>
                            </div>
                            <div className="row">
                                <div className="key">Scale:</div>
                                <div className="value">{activeVdo.scale}</div>
                            </div>
                            <div className="row">
                                <div className="key">Seed:</div>
                                <div className="value">{activeVdo.seed}</div>
                            </div>
                            <div className="row">
                                <div className="key">Engine Model:</div>
                                <div className="value">{activeVdo.model}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
}
