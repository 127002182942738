import BackgroundCanvas from "./BackgroundCanvas";
import MainCanvas from "./MainCanvas";
import CursorCanvas from "./CursorCanvas";
import BrushCanvas from "./BrushCanvas";
import ContentCanvas from "./ContentCanvas";
import ControlsCanvas from "./ControlsCanvas";

import "./index.scss";

export function StudioCanvas() {
  return (
    <div id="studio-canvas">
      <BackgroundCanvas />
      <CursorCanvas />
      <ContentCanvas />
      <MainCanvas />
      <ControlsCanvas />
      <BrushCanvas />
    </div>
  );
}
