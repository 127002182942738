import { CButton } from "components/atoms/CButton";
import { ReactComponent as ChartUp } from 'assets/imgs/chartup.svg';
import { ReactComponent as Stars } from 'assets/imgs/stars.svg';
import IcoStyle from 'assets/imgs/icon_style.svg';
import { useState } from "react";
import './styleBtns.scss'
import { StylePopUp } from "./stylePopUp";

export function StyleBtns({
    // params
    onUpdateStyle = () => { }
}) {
    const [activeStyle, setActiveStyle] = useState('') // all || popular || effects
    const [allList, setAllList] = useState([
        { key: 1, id: "3d-model", icon: IcoStyle, checked: true, label: "3d model" },
        { key: 2, id: "analog-film", icon: IcoStyle, checked: false, label: "analog film" },
        { key: 3, id: "anime", icon: IcoStyle, checked: false, label: "anime" },
        { key: 4, id: "cinematic", icon: IcoStyle, checked: false, label: "cinematic" },
        { key: 5, id: "comic-book", icon: IcoStyle, checked: false, label: "comic book" },
        { key: 6, id: "digital-art", icon: IcoStyle, checked: false, label: "digital art" },
        { key: 7, id: "enhance", icon: IcoStyle, checked: false, label: "enhance" },
        { key: 8, id: "fantasy-art", icon: IcoStyle, checked: false, label: "fantasy art" },
        { key: 9, id: "isometric", icon: IcoStyle, checked: false, label: "isometric" },
        { key: 10, id: "line-art", icon: IcoStyle, checked: false, label: "line art" },
        { key: 11, id: "low-poly", icon: IcoStyle, checked: false, label: "low poly" },
        { key: 12, id: "modeling-compound", icon: IcoStyle, checked: false, label: "modeling compound" },
        { key: 13, id: "neon-punk", icon: IcoStyle, checked: false, label: "neon punk" },
        { key: 14, id: "origami", icon: IcoStyle, checked: false, label: "origami" },
        { key: 15, id: "photographic", icon: IcoStyle, checked: false, label: "photographic" },
        { key: 16, id: "pixel-art", icon: IcoStyle, checked: false, label: "pixel art" },
        { key: 17, id: "tile-texture", icon: IcoStyle, checked: false, label: "tile texture" },
    ])
    // const  [allList, setAllList] = useState([
    //     {key: 1, id: "digital_art", icon: IcoStyle, checked: false, label: "Digital Art"},
    //     {key: 2, id: "Neon", icon: IcoStyle, checked: false, label: "Neon"},
    //     {key: 3, id: "layered_paper", icon: IcoStyle, checked: false, label: "Layered Paper"},
    //     {key: 4, id: "pixel_art", icon: IcoStyle , checked: false, label: "Pixel Art"},
    //     {key: 5, id: "product_photo", icon: IcoStyle , checked: false, label: "Product Photo"},
    //     {key: 6, id: "three_d_art", icon: IcoStyle , checked: false, label: "3D Art"},
    //     {key: 7, id: "realistic", icon: IcoStyle , checked: false, label: "Realistic"},
    //     {key: 8, id: "graffiti", icon: IcoStyle , checked: false, label: "Graffiti"},
    //     {key: 9, id: "low_poly", icon: IcoStyle , checked: false, label: "Low Poly"},
    // ])
    const [popularList, setPopularList] = useState([
        { key: 1, id: "digital_art", icon: IcoStyle, checked: false, label: "Digital Art" },
        { key: 2, id: "Neon", icon: IcoStyle, checked: false, label: "Neon" },
        { key: 3, id: "layered_paper", icon: IcoStyle, checked: false, label: "Layered Paper" },
        { key: 4, id: "pixel_art", icon: IcoStyle, checked: false, label: "Pixel Art" },
        { key: 5, id: "product_photo", icon: IcoStyle, checked: false, label: "Product Photo" },
        { key: 6, id: "three_d_art", icon: IcoStyle, checked: false, label: "3D Art" },
        { key: 7, id: "realistic", icon: IcoStyle, checked: false, label: "Realistic" },
        { key: 8, id: "graffiti", icon: IcoStyle, checked: false, label: "Graffiti" },
        { key: 9, id: "low_poly", icon: IcoStyle, checked: false, label: "Low Poly" },
    ])
    const [effectList, setEffectList] = useState([
        { key: 1, id: "digital_art", icon: IcoStyle, checked: false, label: "Digital Art" },
        { key: 2, id: "Neon", icon: IcoStyle, checked: false, label: "Neon" },
        { key: 3, id: "layered_paper", icon: IcoStyle, checked: false, label: "Layered Paper" },
        { key: 4, id: "pixel_art", icon: IcoStyle, checked: false, label: "Pixel Art" },
        { key: 5, id: "product_photo", icon: IcoStyle, checked: false, label: "Product Photo" },
        { key: 6, id: "three_d_art", icon: IcoStyle, checked: false, label: "3D Art" },
        { key: 7, id: "realistic", icon: IcoStyle, checked: false, label: "Realistic" },
        { key: 8, id: "graffiti", icon: IcoStyle, checked: false, label: "Graffiti" },
        { key: 9, id: "low_poly", icon: IcoStyle, checked: false, label: "Low Poly" },
    ])

    const updateActiveStyle = (key) => {
        if (key === activeStyle) {
            setActiveStyle("")
        } else {
            setActiveStyle(key)
        }
    }
    const action_close = () => {
        setActiveStyle("")
    }
    const action_select = (id, param) => {
        let selEffect = ""
        switch (id) {
            case "all":
                const tempAll = [...allList]
                tempAll.forEach(e => {
                    if (e.key === param) {
                        e.checked = true;
                        selEffect = e.id
                    } else { e.checked = false; }
                })
                setAllList(tempAll)
                break;
            case "popular":
                const tempPopular = [...popularList]
                tempPopular.forEach(e => {
                    if (e.key === param) {
                        e.checked = true;
                        selEffect = e.id
                    } else { e.checked = false; }
                })
                setPopularList(tempPopular)
                break;
            case "effect":
                const tempEffect = [...effectList]
                tempEffect.forEach(e => {
                    if (e.key === param) {
                        e.checked = true;
                        selEffect = e.id
                    } else { e.checked = false; }
                })
                setEffectList(tempEffect)
                break;
            default:
                break;
        }
        onUpdateStyle("select", selEffect)
    }
    const popup_action = (id, action, param) => {
        switch (action) {
            case "close":
                action_close()
                break;
            case "select":
                action_select(id, param)
                break;
        }
    }

    return (
        <div id="style_btns" className="style-btns">
            <CButton addClass="gradient small" active={activeStyle === 'all'} onClick={() => updateActiveStyle('all')}>All</CButton>
            <CButton addClass="gradient small" active={activeStyle === 'popular'} onClick={() => updateActiveStyle('popular')}><Stars className='btn-icon' />Popular</CButton>
            <CButton addClass="gradient small" active={activeStyle === 'effect'} onClick={() => updateActiveStyle('effect')}><ChartUp className='btn-icon' />Effects</CButton>
            {activeStyle === 'all' && <StylePopUp id="all" addClass={activeStyle} list={allList} action={popup_action} />}
            {activeStyle === 'popular' && <StylePopUp id="popular" addClass={activeStyle} list={popularList} action={popup_action} />}
            {activeStyle === 'effect' && <StylePopUp id="effect" addClass={activeStyle} list={effectList} action={popup_action} />}
        </div>
    )
}