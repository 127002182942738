import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import { ACTION_MODES, TEXT_INPUT } from "constants/studio";
import { getActiveMode, getFont, getSelectedItem } from "store/utils";
import useText from "hooks/studio/useText";
import useStudioCreator from "hooks/studio/useStudioCreator";

export const formatRgbaColor = (color) =>
  color ? `rgba(${color.r},${color.g},${color.b},${color.a})` : "transparent";

const ContentCanvas = () => {
  // const contentCanvasRef = useRef(null);
  const {
    color,
    fontSize,
    background,
    style = "normal",
    fontWeight = 400,
    fontFamily = "Roboto",
    textDecoration = "none",
  } = useSelector(getFont);
  const textareaRef = useRef(null);
  const selectedItem = useSelector(getSelectedItem);
  const activeMode = useSelector(getActiveMode);
  const { onTypeText } = useText();
  const { updateCanvasLayout } = useStudioCreator();

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!contentCanvasRef.current) return;

  //   dispatch(setContentCanvasRef(contentCanvasRef));
  // }, [contentCanvasRef, dispatch]);

  const textareaStyle = useMemo(
    () => ({
      fontSize: fontSize + "px",
      color: formatRgbaColor(color),
      background: formatRgbaColor(background),
      fontStyle: style,
      fontWeight,
      fontFamily,
      textDecoration,
    }),
    [fontSize, color, background, style, fontFamily, textDecoration, fontWeight]
  );

  useEffect(() => {
    if (!textareaRef.current) return;
    if (activeMode !== ACTION_MODES.text || !selectedItem) return;

    textareaRef.current?.focus();
  }, [textareaRef, activeMode, selectedItem]);

  const onChange = (e) => {
    onTypeText(e);

    // TODO: find a solution to redraw each layer separately
    updateCanvasLayout();
  };

  return (
    <>
      {/* <canvas
        className="studio-canvas__canvas studio-canvas__canvas_overlay"
        ref={contentCanvasRef}
        id={CONTENT_CANVAS}
      /> */}
      <input
        style={textareaStyle}
        ref={textareaRef}
        id={TEXT_INPUT}
        onChange={onChange}
        onKeyDown={(e) => e.stopPropagation()}
        className="studio-canvas__canvas studio-canvas__canvas_textarea"
      />
    </>
  );
};

export default ContentCanvas;
