import { useRef, useState } from "react";
import { RgbaColorPicker } from "react-colorful";

import useClickOutside from "hooks/useClickOutside";

import "./index.scss";

const ColorPopup = ({ color, onChange, trigger }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <div className="color-popup" ref={ref}>
      <button
        className="color-popup__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {trigger}
      </button>
      {isOpen && (
        <div className="color-popup__picker">
          <RgbaColorPicker onChange={onChange} color={color} />
        </div>
      )}
    </div>
  );
};

export default ColorPopup;
