import IconBreadcrumb from "assets/imgs/icon_breadcrumb.svg";
import { ReactComponent as Profile } from "assets/imgs/menu/icon_personal.svg";
import { ReactComponent as Knowledge } from "assets/imgs/menu/icon_book.svg";
import { getApi } from "utils/customNetwork";
import { ReactComponent as Setting } from "assets/imgs/menu/icon_setting.svg";
import { ReactComponent as Brand } from "assets/imgs/menu/icon_brand.svg";
import { ReactComponent as Logout } from "assets/imgs/menu/icon_out.svg";
import { useContext, useState } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { CButton } from "../CButton";
import { useNavigate } from "react-router-dom";
import { isPossible, showLicense } from "utils/util";
import { LICENSE } from "utils/constants";

import "./styles.scss";
import Notifications from "./Notifications";

export function Header({
  page = "dashboard",
  caption = "Dashboard",
  message = "Welcome to Creativio AI",
  customHeader = null,
}) {
  const navigate = useNavigate();
  const {
    mobileSide,
    setUser,
    checkToken,
    setLoading,
    user,
    onUpgradeLicense,
    setContactModal,
    showNoti,
    upgradeLicense,
  } = useContext(LayoutContext);
  const [showMenu, setShowMenu] = useState(false);

  const showUserName = (user) => {
    if (user?.user?.first_name !== "") {
      return user?.user?.first_name;
    } else {
      return user?.user?.email.substring(0, 2);
    }
  };

  const shortName = (user) => {
    if (user?.user?.first_name !== "") {
      return user?.user?.first_name.substring(0, 2);
    } else {
      return user?.user?.email.substring(0, 2);
    }
  };

  const getLicense = () => {
    if (user?.user?.license) return user?.user?.license;
    else return [LICENSE.FREE_TRIAL];
  };

  const checkLicense = (license) => {
    const isValid = isPossible(getLicense(user?.user), LICENSE.PROFESSIONAL);

    if (!isValid) {
      const formatLicense = showLicense(license, true);

      showNoti(
        "info",
        `You need to upgrade to a ${formatLicense} license to unlock this feature.`
      );
    }

    return isValid;
  };

  const goto = (newPage) => {
    if (newPage === page) return;
    switch (newPage) {
      case "profile":
        navigate("/setting");
        break;
      case "knowledge":
        setShowMenu(false);
        setContactModal(true);
        break;
      case "settings":
        navigate("/setting");
        break;
      case "brand":
        if (checkLicense(LICENSE.PROFESSIONAL)) {
          navigate("/brand");
        }
        break;
      case "logout":
        setUser(null);
        signOut();
        navigate("/");
        break;
      default:
    }
  };

  const signOut = async () => {
    setLoading(true);
    try {
      const data = await getApi("/logout", "POST", {});
      checkToken();
      showNoti("success", data.message);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  return (
    <header className={`header ${mobileSide ? "mobile" : ""}`}>
      <div className="content">
        {customHeader || (
          <div className="left-content flex">
            <span>{caption}</span>
            <img src={IconBreadcrumb} alt="breadcrumb" />
            <span>{message},</span>
            <span className="text-graditent text-capitalize">
              &nbsp;{showUserName(user)}
            </span>
            !
          </div>
        )}

        <div className="right-content">
          {upgradeLicense && (
            <CButton
              addClass="primary active text-size-18"
              onClick={onUpgradeLicense}
            >
              Upgrade To {showLicense(upgradeLicense, true)}
            </CButton>
          )}
          <span className="button-right-push flex">

            <Notifications />
          </span>
          <div
            className="user-image cursor-pointer"
            onClick={() => setShowMenu(!showMenu)}
          >
            {user?.user?.profile_img ? (
              <img src={user?.user?.profile_img} alt="profile" />
            ) : (
              shortName(user)
            )}
          </div>
          {showMenu && (
            <div className="menu-area">
              <div className="cover-area" onClick={() => setShowMenu(false)} />
              <div className="dropdown">
                <div
                  className={`dropdown-menu ${page === "profile" ? "active" : ""
                    }`}
                  onClick={() => goto("profile")}
                >
                  <div className="menu">
                    <div className="img-wrap">
                      <Profile />
                    </div>
                    Profile
                  </div>
                </div>
                <div
                  className={`dropdown-menu ${page === "knowledge" ? "active" : ""
                    }`}
                  onClick={() => goto("knowledge")}
                >
                  <div className="menu">
                    <div className="img-wrap">
                      <Knowledge />
                    </div>
                    Support
                  </div>
                </div>
                <div
                  className={`dropdown-menu ${page === "setting" ? "active" : ""
                    }`}
                  onClick={() => goto("settings")}
                >
                  <div className="menu">
                    <div className="img-wrap">
                      <Setting />
                    </div>
                    Settings
                  </div>
                </div>
                <div
                  className={`dropdown-menu ${page === "brand" ? "active" : ""
                    }`}
                  onClick={() => goto("brand")}
                >
                  <div className="menu">
                    <div className="img-wrap">
                      <Brand />
                    </div>
                    Brand Settings
                  </div>
                </div>
                <div
                  className={`dropdown-menu ${page === "Logout" ? "active" : ""
                    }`}
                  onClick={() => goto("logout")}
                >
                  <div className="menu">
                    <div className="img-wrap">
                      <Logout />
                    </div>
                    Logout
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
