import Modal from "react-modal";
import { useContext, useState } from "react";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { CButton } from "components/atoms/CButton";

import "./index.scss";

export function InviteMemberModal({
  show = false,
  onClose = () => { },
  onHandleInvite = () => { },
}) {
  const { showNoti, setLoading, checkToken } = useContext(LayoutContext);
  const [invitationEmail, setInvitationEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (invitationEmail == '') {
      showNoti("info", "Please enter an email");
      return;
    }
    formData.append("email", invitationEmail);
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/agency/sendInvitation", "POST", formData);
      showNoti("success", "Invitation Send Successfully!");
      onHandleInvite(data.member);
      onClose();
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={show}
      ariaHideApp={false}
      onClick={onClose}
      className="invite-member-modal"
    >
      <IconClose className="cursor-pointer ico-close" onClick={onClose} />
      <div className="invite-area">
        <h2 className="text-center">Invite Member</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-10">
            <div className="invitation-email">
              <p>Email</p>
              <div className="input-field mt-10">
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={invitationEmail}
                  onChange={(e) => setInvitationEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="text-right">
              <CButton
                mainClass="mt-10"
                addClass="gradient p-5I"
                active={true}
                type="submit"
              >
                Send
              </CButton>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
