import { Doughnut } from "react-chartjs-2"
import './index.scss'

const Chart = ({ data, title }) => {
    return <div className="chart-card">
        <h3>{title}</h3>
        <div style={{ height: '130px', width: '130px' }}>
            <Doughnut
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }}
                data={data}
            />
        </div>
    </div>
}

export default Chart