import { EDITOR_ACTIONS } from "constants/studio"

import './index.scss'

const KeyboardPopup = () => {
    return <div className="keyboard-popup">
        <h3>
            Keyboard Shortcuts
        </h3>
        <ul className="keyboard-popup__list">
            {EDITOR_ACTIONS.map((item) =>
                <li>
                    <item.Icon />
                    {item.title}
                </li>
            )}
        </ul>
    </div>
}

export default KeyboardPopup