import { useRef, useEffect } from "react";
import { CONTROLS_CANVAS } from "constants/studio";
import { useDispatch } from "react-redux";
import {
  setControlsCanvasRef,
  updateControlsCanvas,
} from "store/slices/studio";

const ControlsCanvas = () => {
  const controlsCanvasRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!controlsCanvasRef.current) return;

    dispatch(setControlsCanvasRef(controlsCanvasRef));
    dispatch(updateControlsCanvas(controlsCanvasRef.current));
  }, [controlsCanvasRef, dispatch]);

  return (
    <canvas
      className="studio-canvas__canvas studio-canvas__canvas_overlay"
      id={CONTROLS_CANVAS}
      ref={controlsCanvasRef}
    />
  );
};

export default ControlsCanvas;
