import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_API_URL;

const setToken = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const getApi = async (url, method, params) => {
  try {
    const { data } = await axios({
      method,
      url: BASE_API_URL + url,
      data: params,
      responseType: "json",
    });

    return data;
  } catch (error) {
    console.log("login response error : ", error);
    let message = "";
    if (error.response) {
      message = error.response.data.message;
      console.log(error.response.status, message, url);
    } else {
      message = error.message;
      console.log("unknown status", message, url);
    }
    throw message;
  }
};

export { getApi, setToken };
