import "./index.scss";

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

const Video = ({ video }) => {
  const videoId = getId(video.url);

  return (
    <div className="video">
      <iframe
        width="560"
        height="315"
        src={"//www.youtube.com/embed/" + videoId}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
      <div className="video__bottom">
        <span className="video__title">{video.title}</span>
        <button
          onClick={() => {
            window.open(video.url, "_blank");
          }}
          className="video__button"
        >
          Watch now
        </button>
      </div>
    </div>
  );
};

export default Video;
