import React, { useState } from 'react';
import './index.scss';

export function CToggle({
    mainClass = "",
    disabled = false,
    onClick = () => { },
    isOn = false,
    // trueLabel = "",
    // falseLabel = "",
}) {
    return (
        <div className={`toggle-container ${mainClass}`} style={{ display: 'inline-block' }}>
            <button className={`${isOn ? 'active' : ''}`}
                disabled={disabled}
                onClick={onClick}>
                <span className="pin" />
                {/* {isOn ? trueLabel : falseLabel} */}
            </button>
        </div>
    )
}