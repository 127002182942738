import { createSlice } from "@reduxjs/toolkit";
import {
  ACTION_MODES,
  DEFAULT_BRUSH_SIZE,
  DEFAULT_ZOOM,
  DRAW_OBJECT_TYPES,
  MAX_ZOOM,
  MIN_ZOOM,
  ZOOM_STEP,
} from "constants/studio";
import BrushObject from "lib/drawings/BrushObject";
import EraserObject from "lib/drawings/EraserObject";
import ShapeObject from "lib/shapes/ShapeObject";
import { onCloneStudio } from "utils/onCloneState";

const initialPresent = {
  canvasRef: null,
  brushCanvasRef: null,
  cursorCanvasRef: null,
  contentCanvasRef: null,
  backgroundCanvasRef: null,
  controlsCanvasRef: null,
  scale: DEFAULT_ZOOM,
  offsetX: 0,
  offsetY: 0,
  activeMode: ACTION_MODES.pan,
  mainObjectLocked: false,
  isUserInteract: false,
  selectedItem: null,
  movementType: null,
  layers: [],
  brushSize: DEFAULT_BRUSH_SIZE,
  eraserSize: DEFAULT_BRUSH_SIZE,
  isDownloading: false,
  font: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: { r: 255, g: 255, b: 255, a: 1 },
    background: { r: 0, g: 0, b: 0, a: 0 },
  },
  generatedImages: [],
  isUserChooseImage: false,
  maskType: "white",
  positiveText: "",
  negativeText: "",
};

const initialState = {
  present: initialPresent,
  future: [],
  past: [],
};

export const studioSlice = createSlice({
  name: "studio",
  initialState,
  reducers: {
    setFont: (state, action) => {
      state.present.font = { ...state.present.font, ...action.payload };
    },
    zoomOut: (state) => {
      let newZoom = state.present.scale - ZOOM_STEP;
      if (newZoom < MIN_ZOOM) {
        newZoom = MIN_ZOOM;
      }

      state.present.scale = newZoom;
    },
    zoomIn: (state) => {
      let newZoom = state.present.scale + ZOOM_STEP;
      if (newZoom > MAX_ZOOM) {
        newZoom = MAX_ZOOM;
      }

      state.present.scale = newZoom;
    },
    setActiveMode: (state, action) => {
      state.present.activeMode = action.payload;
    },
    setCanvasRef: (state, action) => {
      state.present.canvasRef = Object.assign({}, action.payload);
    },
    setBrushCanvasRef: (state, action) => {
      state.present.brushCanvasRef = Object.assign({}, action.payload);
    },
    setCursorCanvasRef: (state, action) => {
      state.present.cursorCanvasRef = Object.assign({}, action.payload);
    },
    setContentCanvasRef: (state, action) => {
      state.present.contentCanvasRef = Object.assign({}, action.payload);
    },
    setBackgroundCanvasRef: (state, action) => {
      state.present.backgroundCanvasRef = Object.assign({}, action.payload);
    },
    setControlsCanvasRef: (state, action) => {
      state.present.controlsCanvasRef = Object.assign({}, action.payload);
    },
    addLayer: (state, action) => {
      const layer = action.payload;
      const layers = state.present.layers;
      const hasMainObject = layers.some(
        (layer) => layer?.type === DRAW_OBJECT_TYPES.mainRect
      );
      if (
        !layer ||
        (layer?.type === DRAW_OBJECT_TYPES.mainRect && hasMainObject)
      ) {
        return;
      }

      layer?.setPosition(state.present.layers.length + 1);
      state.present.layers = [...state.present.layers, layer];
    },
    setLayers: (state, action) => {
      state.present.layers = [...state.present.layers, action.payload];
    },
    setBrushLayers: (state, action) => {
      state.present.brushLayers = action.payload;
    },
    onResize: () => {
      let canvas = null;
      var canvases = document.getElementsByTagName("canvas");
      for (var i = 0; i < canvases.length; i++) {
        canvas = canvases[i];
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
      }
    },
    setMainObjectLocked: (state, action) => {
      state.present.mainObjectLocked = action.payload;
    },
    addOffsetX: (state, action) => {
      state.present.offsetX += action.payload / state.present.scale;
    },
    addOffsetY: (state, action) => {
      state.present.offsetY += action.payload / state.present.scale;
    },
    setUserInteract: (state, action) => {
      state.present.isUserInteract = action.payload;
    },
    clearLayers: (state) => {
      state.present.layers = [];
    },
    setOffsetX: (state, action) => {
      state.present.offsetX = action.payload;
    },
    setOffsetY: (state, action) => {
      state.present.offsetY = action.payload;
    },
    setScale: (state, action) => {
      state.present.scale = action.payload;
    },
    setMovementType: (state, action) => {
      state.present.movementType = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.present.selectedItem = action.payload;
    },
    setBrushSize: (state, action) => {
      state.present.brushSize = +action.payload;
    },
    setEraserSize: (state, action) => {
      state.present.eraserSize = +action.payload;
    },
    deleteLayer: (state, action) => {
      state.present.layers = state.present.layers.filter(
        (item) => item !== action.payload
      );
    },
    setGeneratedImages: (state, action) => {
      state.present.generatedImages = action.payload;
    },
    setIsUserChooseImage: (state, action) => {
      state.present.isUserChooseImage = action.payload;
    },
    setMaskType: (state, action) => {
      state.present.maskType = action.payload;
    },
    updateControlsCanvas: (state, action) => {
      if (!state.present.layers.length) return;

      for (let layer of state.present.layers) {
        if (layer instanceof ShapeObject) {
          layer.setControlsCanvas(action.payload);
          layer.addNewCanvas();
        }
      }
    },
    updateBrushCanvas: (state, action) => {
      if (!state.present.layers.length) return;

      for (let layer of state.present.layers) {
        if (layer instanceof BrushObject || layer instanceof EraserObject) {
          layer.setCanvas(action.payload);
        }
      }
    },
    addHistory: (state) => {
      state.past.push(onCloneStudio(state.present));
      state.future = [];
    },
    setPositiveText: (state, action) => {
      state.present.positiveText = action.payload;
    },
    setNegativeText: (state, action) => {
      state.present.negativeText = action.payload;
    },
    setPast: (state, action) => {
      state.past = action.payload;
    },
    setPresent: (state, action) => {
      state.present = action.payload;
    },
    setFuture: (state, action) => {
      state.future = action.payload;
    },
    setIsDownloading: (state, action) => {
      state.present.isDownloading = action.payload
    },
    resetPresent: (state) => {
      state.present = initialPresent
    }
  },
});

export const {
  zoomOut,
  zoomIn,
  setActiveMode,
  setCanvasRef,
  setBrushCanvasRef,
  setCursorCanvasRef,
  setContentCanvasRef,
  setBackgroundCanvasRef,
  setLayers,
  addLayer,
  setBrushLayers,
  onResize,
  setMainObjectLocked,
  addOffsetX,
  addOffsetY,
  setUserInteract,
  redrawLayers,
  setMovementType,
  setSelectedItem,
  setBrushSize,
  setEraserSize,
  setFont,
  deleteLayer,
  clearLayers,
  setOffsetX,
  setOffsetY,
  setScale,
  setGeneratedImages,
  setIsUserChooseImage,
  setMaskType,
  setControlsCanvasRef,
  updateControlsCanvas,
  updateBrushCanvas,
  addHistory,
  setPositiveText,
  setNegativeText,
  setFuture,
  setPast,
  setPresent,
  setIsDownloading,
  resetPresent
} = studioSlice.actions;

export default studioSlice.reducer;
