export const ENGINE_TYPE = {
  stability: "stability",
  creativio: "creativio",
  creativionova: "creativionova",
};

export const ENGINES = [
  {
    value: ENGINE_TYPE.stability,
    label: "Stability AI SDXL Engine (Default)",
  },
  {
    value: ENGINE_TYPE.creativio,
    label: "Creativio AI SDXL Engine (Beta)",
  },
  {
    value: ENGINE_TYPE.creativionova,
    label: "Creativio AI Engine (Nova)",
  }
];
