import { useContext, useEffect, useState, useCallback, useMemo } from "react";

import { LayoutContext } from "components/contexts/LayoutContextContainer";
import InfoCard from "components/molecules/admin/InfoCard";
import RealtimeTerminal from "components/molecules/admin/RealtimeTerminal";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import { AdminLayout } from "components/layouts/AdminLayout";

import { ReactComponent as ImageSvg } from 'assets/imgs/admin/logs/image.svg'
import ImageGenerations from "components/molecules/admin/ImageGenerations";
import LogsCharts from "components/molecules/admin/LogsCharts";


const LIMIT = 15;

const getTotalNumber = (data = { success: 0, failure: 0 }) => {
  return +data.success + +data.failure
}

const Logs = () => {
  const { checkToken, showNoti } = useContext(LayoutContext);
  const [terminal, setTerminal] = useState({
    page: 1,
    total: 0,
    data: []
  });

  const [imageLogs, setImageLogs] = useState({
    page: 1,
    total: 0,
    data: []
  })

  const [statistics, setStatistics] = useState({})

  const onLoadImageLogs = async () => {
    await onGetData(imageLogs.page + 1).then((result) => {
      if (!result) return;
      setImageLogs(p => ({
        data: [...p.data, ...result.data],
        total: result.meta?.total,
        page: p.page + 1
      }))
    });
  };

  const onLoadTerminalLogs = async () => {
    await onGetData(terminal.page + 1).then((result) => {
      if (!result) return;
      setTerminal(p => ({
        data: [...p.data, ...result.data],
        total: result.meta?.total,
        page: p.page + 1
      }))
    });
  };

  const hasMoreImageLogs = useMemo(() => {
    return imageLogs.total > imageLogs.data.length;
  }, [imageLogs]);
  const hasMoreTerminalLogs = useMemo(() => {
    return terminal.total > terminal.data.length;
  }, [terminal]);

  const onGetData = useCallback(
    async (page) => {
      let result = null;
      try {
        checkToken();
        const data = await getApi(
          `/admin/service/logs/list?perPage=${LIMIT}&page=${page}`,
          "GET"
        );
        result = data;
      } catch (e) {
        showNoti("error", e);
      }
      return result;
    },
    [checkToken, showNoti]
  );

  const onGetStatistics = useCallback(async () => {
    try {
      checkToken();
      const data = await getApi('/admin/service/logs/statistic', 'GET');
      setStatistics(data.data);
    } catch (e) {
      showNoti('error', e)
    }
  }, [checkToken, showNoti])

  useEffect(() => {
    onGetData(1).then((result) => {
      if (!result) return;

      setImageLogs(p => ({ ...p, data: result.data, total: result.meta.total }))
    });

    onGetData(1).then((result) => {
      if (!result) return;

      setTerminal(p => ({ ...p, data: result.data, total: result.meta.total }))
    });

    onGetStatistics()
  }, [onGetData, onGetStatistics]);

  return (
    <AdminLayout page="logs" message="API Logs page">
      <div className="admin-dashboard body-container">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span>API</span> Logs
            </h1>
          </Slide>
        </div>
        <div className="card-area mt-15 mb-15">
          <InfoCard
            title="API Calls"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.totalApiCalls)}
          />
          <InfoCard
            title="Photos Generated"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.photosGenerated)}
          />
          <InfoCard
            title="Product Photos"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.productPhotos)}
          />
          <InfoCard
            title="Text to Image"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.testToImage)}
          />
          <InfoCard
            title="AI Studio Editor"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.aiStudioEditor)}
          />
        </div>
        <LogsCharts statistics={statistics} />
        <ImageGenerations data={{ data: imageLogs.data, hasMore: hasMoreImageLogs, onLoad: onLoadImageLogs }} />
        <RealtimeTerminal data={{ data: terminal.data, hasMore: hasMoreTerminalLogs, onLoad: onLoadTerminalLogs }} />
      </div>
    </AdminLayout>
  );
};

export default Logs;
