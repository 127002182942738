
const varColors = [
  { key: 1, id: "Red", checked: true, label: "Red" },
  { key: 2, id: "Pink", checked: false, label: "Pink" },
  { key: 3, id: "Orange", checked: false, label: "Orange" },
  { key: 4, id: "Yellow", checked: false, label: "Yellow" },
  { key: 5, id: "Violet", checked: false, label: "Violet" },
  { key: 6, id: "Green", checked: false, label: "Green" },
  { key: 7, id: "Aqua", checked: false, label: "Aqua" },
  { key: 8, id: "Blue", checked: false, label: "Blue" },
  { key: 9, id: "Brown", checked: false, label: "Brown" },
  { key: 10, id: "White", checked: false, label: "White" },
  { key: 11, id: "Gray", checked: false, label: "Gray" },
  { key: 12, id: "Black", checked: false, label: "Black" },
];

export {
  varColors,
};
