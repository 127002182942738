import { LayoutContext } from "components/contexts/LayoutContextContainer";
// import useTable from "hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { getApi } from "utils/customNetwork";
import Accordion from "components/molecules/Accordion";

// const rowsPerPage = 10;

export function Billings() {
  const { setLoading, showNoti, checkToken, user } = useContext(LayoutContext);
  const [data, setData] = useState([]);
  // const [page, setPage] = useState(1);
  // const { slice, range } = useTable(data, page, rowsPerPage);

  const getBillings = async () => {
    setLoading(true);
    try {
      checkToken();
      const data = await getApi("/getBillings", "POST", {});
      setData(data.data);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };
  useEffect(() => {
    getBillings();
  }, []);
  return (
    <div className="connect-area mt-15">
      <Accordion 
        data={data} 
        user={user}
      />
      {/* <div className="custom-table mt-15">
        {range.length !== 0 && (
          <div className="pagination mt-20">
            <button
              onClick={() => setPage(1)}
              disabled={page === 1 || range.length === 1}
            >
              {"<<"}
            </button>
            <button
              onClick={() => setPage(page - 1)}
              disabled={page === 1 || range.length === 1}
            >
              {"<"}
            </button>
            <span>
              Page{" "}
              <strong>
                {page} of {range.length}
              </strong>{" "}
            </span>
            <button
              onClick={() => setPage(page + 1)}
              disabled={range.length === 1 || page === range.length}
            >
              {">"}
            </button>
            <button
              onClick={() => setPage(range.length)}
              disabled={page === range.length}
            >
              {">>"}
            </button>
          </div>
        )}
      </div> */}
    </div>
  );
}
