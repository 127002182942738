import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    image: null,
    result: null
}

export const enhanceSlice = createSlice({
    name: "enhance",
    initialState,
    reducers: {
        setImage: (state, action) => {
            state.image = action.payload
        },
        setResult: (state, action) => {
            state.result = action.payload
        }
    }
})

export const {
    setImage,
    setResult
} = enhanceSlice.actions;

export default enhanceSlice.reducer