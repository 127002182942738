import { CButton } from "components/atoms/CButton"
import { ReactComponent as Shopify } from 'assets/imgs/auth/shopify.svg';
import { ReactComponent as Wordpress } from 'assets/imgs/auth/wordpress.svg';

export function Connects({
    profile = null,
}) {
    if (profile.role !== 'admin') return <div className="coming-soon-area">Coming soon...</div>
    return (
        <div className="connect-area">
            <div className="form-group mt-15">
                <p>Shopify Account:</p>
                <div className="connect-item-area">
                    <div className="input-with-icon">
                        <Shopify className="icon-img" />
                        <input
                            type="text"
                            id="shopify"
                            placeholder="shopify"
                        />
                    </div>
                    <CButton addClass="primary" active={true}>Connect</CButton>
                    <CButton addClass="primary" active={true}>Connected</CButton>
                    <CButton addClass="primary" active={true}>View Assets</CButton>
                </div>
            </div>
            <div className="form-group mt-15">
                <p>Wordpress Account:</p>
                <div className="connect-item-area">
                    <div className="input-with-icon">
                        <Wordpress className="icon-img" />
                        <input
                            type="text"
                            id="wordpress"
                            placeholder="wordpress"
                        />
                    </div>
                    <CButton addClass="primary" active={true}>Connect</CButton>
                    <CButton addClass="primary" active={true}>Connected</CButton>
                    <CButton addClass="primary" active={true}>View Assets</CButton>
                </div>
            </div>
            <div className="form-group mt-25 text-left">
                <CButton addClass="primary" active={true}>Save Integrations</CButton>
            </div>
        </div>
    )
}