import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addHistory,
  setFuture,
  setPast,
  setPresent,
} from "store/slices/studio";
import { getFutureState, getPastState, getPresentState } from "store/utils";

const useHistoryDispatch = () => {
  const dispatch = useDispatch();
  const present = useSelector(getPresentState);
  const past = useSelector(getPastState);
  const future = useSelector(getFutureState);

  const historyDispatch = (action = () => {}) => {
    dispatch(action);
    dispatch(addHistory());
  };

  const historyLayer = (action = () => {}) => {
    dispatch(addHistory());
    return action;
  };

  const undo = () => {
    if (past.length === 0) return;

    let previous = past[past.length - 1];
    const newPast = past.slice(0, past.length - 1);
    const newFuture = [present, ...future];

    dispatch(setPast(newPast));
    dispatch(setPresent(previous));
    dispatch(setFuture(newFuture));
  };

  const redo = () => {
    if (future.length === 0) return;

    const next = future[0];

    dispatch(setPast([...past, present]));
    dispatch(setPresent(next));
    dispatch(setFuture(future.slice(1)));
  };

  const hasFuture = useMemo(() => !!future.length, [future]);
  const hasPast = useMemo(() => !!past.length, [past]);

  return {
    historyDispatch,
    historyLayer,
    undo,
    redo,
    hasFuture,
    hasPast,
  };
};

export default useHistoryDispatch;
