export const getTrendImages = (state) => state.magicRemover.trendImages;
export const getStep = (state) => state.magicRemover.step;
export const getImageType = (state) => state.magicRemover.imageType;
export const getCurrentPage = (state) => state.magicRemover.currentPage;
export const getOrgImageSize = (state) => state.magicRemover.orgImageSize;
export const getImageSize = (state) => state.magicRemover.imageSize;
export const getIsLast = (state) => state.magicRemover.isLast;
export const getSelectedImage = (state) => state.magicRemover.selectedImage;
export const getActiveTrend = (state) => state.magicRemover.activeTrend;
export const getActiveMode = (state) => state.magicRemover.activeMode;
export const getKeyword = (state) => state.magicRemover.keyword;
export const getResultImage = (state) => state.magicRemover.resultImage;
export const getNewImage = (state) => state.magicRemover.newImage;
export const getLayers = (state) => state.magicRemover.layers;
export const getBackupLayers = (state) => state.magicRemover.backupLayers;
export const getRedoStep = (state) => state.magicRemover.redoStep;