import { DashboardLayout } from "components/layouts/DashboardLayout";
import { StudioHeader } from "components/molecules/StudioHeader";
import { StudioCanvasWrapper } from "components/organisms/StudioCanvasWrapper";
import useStudioCreator from "hooks/studio/useStudioCreator";
import { useEffect } from "react";
import { LICENSE } from "utils/constants";

export function StudioCreator() {
  const { handleResize, onKeyDown } = useStudioCreator();

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);


  return (
    <DashboardLayout
      license={LICENSE.PROFESSIONAL}
      page="studio-creator"
      customHeader={<StudioHeader />}
    >
      <StudioCanvasWrapper />
    </DashboardLayout>
  );
}
