import { useRef } from "react";
import { Slide } from "react-awesome-reveal";
import Upload from "assets/imgs/upload.svg";

const UploadView = ({ onChange, content = { title: '', mainTitle: '', subtitle: 'To Get Started, Select a Sample asset or upload an image' } }) => {
  const fileInputRef = useRef(null);

  const onImageUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="section-upload">
      <div className="head">
        <Slide className="flex1">
          <h1>{content.mainTitle}</h1>
        </Slide>
      </div>
      <div className="image-upload-area mt-10">
        <input
          type="file"
          accept=".png, .webp, .jpg, .jpeg"
          onChange={onChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <div className="custom-upload" onClick={onImageUpload}>
          <p className="lbl-bold">{content.title}</p>
          <img src={Upload} alt="upload" />
          <p>{content.subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default UploadView;
