import { DashboardLayout } from "components/layouts/DashboardLayout";
import "./styles.scss";
import { useContext } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import { useRef } from "react";
import BrushCanvas from "components/atoms/ImageEditor/BrushCanvas";
import { LICENSE } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getBackupLayers,
  getImageSize,
  getImageType,
  getLayers,
  getNewImage,
  getOrgImageSize,
  getRedoStep,
  getResultImage,
  getSelectedImage,
  getStep,
} from "store/slices/textRemover/utils";
import {
  setBackupLayers,
  setImageSize,
  setImageType,
  setLayers,
  setRedoStep,
  setResultImage,
  setStep,
} from "store/slices/textRemover/reducer";
import UploadStep from "./components/UploadStep";
import { fileToDataUrl, formatDataUrl } from "utils/getDataUrl";

export function TextRemover() {
  const brushCanvasRef = useRef(null);
  const step = useSelector(getStep);
  const imageSize = useSelector(getImageSize);
  const orgImageSize = useSelector(getOrgImageSize);
  const selectedImage = useSelector(getSelectedImage);
  const resultImage = useSelector(getResultImage);
  const newImage = useSelector(getNewImage);
  const layers = useSelector(getLayers);
  const backupLayers = useSelector(getBackupLayers);
  const redoStep = useSelector(getRedoStep)

  const dispatch = useDispatch();
  const imageType = useSelector(getImageType);

  const { showNoti, setLoading, checkToken } = useContext(LayoutContext);

  const getNewCoordinates = (canvas, img) => {
    const widthScale = canvas.width / img.width;
    const heightScale = canvas.height / img.height;

    const scale = Math.min(widthScale, heightScale);

    const newWidth = img.width * scale;
    const newHeight = img.height * scale;

    const centerX = (768 - newWidth) / 2;
    const centerY = (768 - newHeight) / 2;

    return {
      newHeight, newWidth, centerX, centerY
    }
  }

  const drawOnTempCanvas = (img) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext("2d");
    canvas.width = 768;
    canvas.height = 768;

    const { centerX, centerY, newWidth, newHeight } = getNewCoordinates(canvas, img)

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(img, centerX, centerY, newWidth, newHeight);


    const tempCanvas = document.createElement("canvas"); // Create a temporary canvas
    tempCanvas.width = 1024;
    tempCanvas.height = 1024;

    const tempCtx = tempCanvas.getContext("2d");

    tempCtx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      tempCanvas.width,
      tempCanvas.height
    );

    const dataURL = tempCanvas.toDataURL();

    return dataURL
  }

  const getImageBase64 = async () => {
    return new Promise((resolve, reject) => {
      if (imageType === 'url') {

        const img = new Image();
        img.crossOrigin = "anonymous";

        img.onload = () => {
          const dataURL = drawOnTempCanvas(img)
          resolve(dataURL)
        };

        img.onerror = (error) => {
          reject(error);
        };
        img.src = resultImage;
      } else {
        fileToDataUrl(newImage).then((src) => {
          const img = new Image();

          img.onload = () => {
            const dataURL = drawOnTempCanvas(img)
            resolve(dataURL)
          };

          img.onerror = (error) => {
            reject(error);
          };
          img.src = src;
        })
      };
    })
  }

  const onEditImage = async () => {
    let result = resultImage;
    if (!brushCanvasRef.current) return result
    const base64image = brushCanvasRef.current.getImage();
    const image = await getImageBase64()

    const payload = {
      image: formatDataUrl(image),
      mask: formatDataUrl(base64image)
    }

    try {
      setLoading(true);
      checkToken();

      const data = await getApi("/v1/images/generate/remove/text/select/area", "POST", payload);

      const img = data.data.imagePath;
      dispatch(setImageType("url"));
      dispatch(setResultImage(img))
      dispatch(setImageSize({ width: data.data.width, height: data.data.height }));
      result = img
    } catch (err) {
      showNoti("error", err);
    }

    setLoading(false);
    return result;
  };

  const onExit = () => {
    dispatch(setStep('upload'));
    dispatch(setLayers([]));
    dispatch(setBackupLayers([]));
    dispatch(setRedoStep(0));
  }

  return (
    <DashboardLayout
      page="my-feed"
      caption="Personal Feed"
      license={LICENSE.PROFESSIONAL}
      message="Welcome to Creativio AI"
    >
      <div className="magic-remover">
        {step === "upload" && <UploadStep />}
        {step === "edit" && (
          <BrushCanvas
            maskType="white"
            ref={brushCanvasRef}
            mainCaption="Text"
            subCaption="Remover Tool"
            onRemove={onEditImage}
            image={{
              src: resultImage,
              width: imageSize.width,
              height: imageSize.height
            }}
            orgImage={{
              src: selectedImage,
              width: orgImageSize.width,
              height: orgImageSize.height
            }}
            history={{
              redoStep,
              layers,
              backupLayers,
              setRedoStep: (step) => dispatch(setRedoStep(step)),
              setLayers: (layers) => dispatch(setLayers(layers)),
              setBackupLayers: (backupLayers) => dispatch(setBackupLayers(backupLayers))
            }}
            onExit={onExit}
          />
        )}
      </div>
    </DashboardLayout>
  );
}
