import { useDispatch, useSelector } from "react-redux";
import { toRealX, toRealY, toVirtualX, toVirtualY } from "utils/studio/utils";
import {
  getActiveMode,
  getCanvasRef,
  getFont,
  getLayers,
  getMainObjectLocked,
  getMovementType,
  getOffsetX,
  getOffsetY,
  getScale,
  getSelectedItem,
} from "store/utils";
import {
  addLayer,
  deleteLayer,
  setActiveMode,
  setFont,
  setMovementType,
  setSelectedItem,
} from "store/slices/studio";
import LayerObject from "lib/LayerObject";
import TextObject from "lib/shapes/TextObject";
import { getMousePos } from "components/atoms/ImageEditor/canvasUtil";
import { ACTION_MODES, DRAW_OBJECT_TYPES, TEXT_INPUT } from "constants/studio";
import useHistoryDispatch from "hooks/history/useHistoryStudio";

const useText = () => {
  const layers = useSelector(getLayers);
  const mainObjectLocked = useSelector(getMainObjectLocked);
  const movementType = useSelector(getMovementType);
  const font = useSelector(getFont);
  const canvas = useSelector(getCanvasRef);
  const activeMode = useSelector(getActiveMode);
  const dispatch = useDispatch();
  const selectedItem = useSelector(getSelectedItem);
  const scale = useSelector(getScale);
  const offsetX = useSelector(getOffsetX);
  const offsetY = useSelector(getOffsetY);
  const { historyDispatch, historyLayer } = useHistoryDispatch();

  const onStopText = () => {
    const input = document.getElementById(TEXT_INPUT);

    if (!input || !selectedItem) return;

    const meta = {
      ...font,
    };

    if (!input.value) {
      if (!movementType && selectedItem.type === DRAW_OBJECT_TYPES.text) {
        historyDispatch(deleteLayer(selectedItem));
      }
    } else {
      selectedItem.setData(input.value);
      selectedItem.setMeta(meta);
      historyLayer();
    }

    setSelectedItem(null);
    dispatch(setMovementType(null));

    input.value = "";
    input.style.display = "none";
  };

  const getSelectedLayer = ({ clientX, clientY }) => {
    const newSelectedItem = LayerObject.getSelectedItem(layers, {
      clientX,
      clientY,
      canvasRef: canvas,
      offsetX,
      offsetY,
      scale,
      mainObjectLocked,
    });

    return newSelectedItem;
  };

  const handleEditTextLayer = (layer) => {
    dispatch(setFont(layer.meta));

    const input = document.getElementById(TEXT_INPUT);

    if (!input) return;

    const meta = {
      ...font,
    };

    input.style.display = "block";

    input.style.top = toVirtualY(selectedItem.y, offsetY, scale) + "px";
    input.style.left = toVirtualX(selectedItem.x, offsetX, scale) + "px";
    input.style.width = selectedItem.width + "px";
    input.value = selectedItem.data;

    selectedItem.setMeta(meta);
    selectedItem.setData("");
  };

  const onEditText = (event) => {
    const newSelectedItem = getSelectedLayer(event);

    if (!newSelectedItem || newSelectedItem?.type !== DRAW_OBJECT_TYPES.text)
      return;

    dispatch(setSelectedItem(newSelectedItem));
    dispatch(setActiveMode(DRAW_OBJECT_TYPES.text));
    handleEditTextLayer(newSelectedItem);
  };

  const onStartText = (event) => {
    const { clientX, clientY } = event;

    if (activeMode !== ACTION_MODES.text) return;

    const newSelectedItem = getSelectedLayer(event);

    if (selectedItem !== newSelectedItem) {
      onStopText();
    }

    if (newSelectedItem && newSelectedItem?.type === DRAW_OBJECT_TYPES.text) {
      dispatch(setSelectedItem(newSelectedItem));

      const movementType =
        newSelectedItem?.checkMovement &&
        newSelectedItem.checkMovement({
          clientX,
          clientY,
          offsetX,
          offsetY,
          scale,
        });

      dispatch(setMovementType(movementType));
      return;
    }

    const { x, y } = getMousePos(canvas.current, { clientX, clientY });

    const meta = {
      ...font,
    };

    const input = document.getElementById(TEXT_INPUT);

    if (!input) return;

    const textObject = new TextObject({
      data: "",
      meta,
      input,
      x: toRealX(x, offsetX, scale),
      y: toRealY(y, offsetY, scale),
      width: 300,
      height: input.offsetHeight,
    });

    dispatch(addLayer(textObject));
    dispatch(setSelectedItem(textObject));

    input.style.display = "block";
    input.style.top = y + "px";
    input.style.left = x + "px";
    input.style.width = 300 + "px";
  };

  const onTypeText = ({ target: input }) => {
    if (!input) return;

    const size = TextObject.calculateSize({
      ...font,
      scale,
      text: input.value,
    });

    input.style.width = size.width + "px";
    if (!selectedItem) return;
    selectedItem.setWidth(input.offsetWidth);
  };

  const onResizeText = () => {
    if (!selectedItem) return;
    historyDispatch(setFont(selectedItem.meta));

    selectedItem.updateSize(scale);
    const input = document.getElementById(TEXT_INPUT);

    if (input) {
      const size = TextObject.calculateSize({
        ...font,
        scale,
        text: input.value,
      });

      input.style.width = size.width + "px";

      selectedItem.setWidth(input.offsetWidth);
    }
  };

  return {
    onEditText,
    onStartText,
    onStopText,
    onTypeText,
    onResizeText,
  };
};

export default useText;
