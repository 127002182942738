import { useNavigate } from "react-router-dom";
import { CButton } from "components/atoms/CButton";
import { useContext, useState } from "react";
import { getApi, setToken } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import "./styles.scss";
import logo from "assets/imgs/logo.svg";
import { ReactComponent as Man } from "assets/imgs/auth/people.svg";
import { ReactComponent as Lock } from "assets/imgs/auth/key.svg";
import { ReactComponent as Eye } from "assets/imgs/auth/eye.svg";
import { ReactComponent as EyeClose } from "assets/imgs/auth/eye_close.svg";
import { ReactComponent as Checked } from "assets/imgs/auth/checked.svg";
import { ReactComponent as UnChecked } from "assets/imgs/auth/unchecked.svg";
import { useEffect } from "react";

export function AdminLogin() {
  const navigate = useNavigate();
  const { setLoading, adminUser, setAdminUser, showNoti } =
    useContext(LayoutContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;
    setLoading(true);
    try {
      const data = await getApi("/admin/login", "POST", { email, password });
      setToken(data.token);
      const expired = Math.floor(Date.now() / 1000) + 60 * 60; // 60 mins

      setAdminUser({
        user: data.user,
        token: data.token,
        expired,
        type: "admin",
      });
      navigate("/admin/dashboard");
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const checkAdminUser = async () => {
    try {
      setLoading(true);
      setToken(adminUser.token);
      const data = await getApi("/checkAdminUser", "POST", {
        user_id: adminUser.user.id,
      });
      const expired = Math.floor(Date.now() / 1000) + 10 * 60; // 10 mins
      setAdminUser({
        user: data.user,
        token: data.token,
        expired,
        type: "admin",
      });
      navigate("/admin/dashboard");
    } catch (error) {
      // showNoti('error', error)
      // setIsOpen(true)
      navigate("/admin/login");
    }
    setLoading(false);
  };

  useEffect(() => {
    // if user exists, call checkUser api
    if (adminUser) {
      const expired = setAdminUser.expired;
      const current = Math.floor(Date.now() / 1000);
      if (current - Number(expired) < 0) {
        navigate("/admin/dashboard");
      } else {
        checkAdminUser();
      }
    }
  }, []);

  return (
    <div id="#login" className="auth-area">
      <div className="login">
        <div className="logo mt-15">
          <img src={logo} alt="logo" />
        </div>
        <div className="active-area">
          <form onSubmit={handleSubmit}>
            <div className="login-card mt-35">
              <div className="form-group">
                <div className="input-with-icon">
                  <Man className="icon-img" />
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-25">
                <div className="input-with-icon">
                  <Lock className="icon-img" />
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {!showPassword ? (
                    <Eye
                      className="last-icon-img"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <EyeClose
                      className="last-icon-img"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </div>
              </div>
              <div className="form-group mt-15 remember-field">
                <div
                  className="chk-remember flex txt-lbl cursor-pointer"
                  onClick={() => setRemember(!remember)}
                >
                  {remember ? <Checked /> : <UnChecked />}&nbsp;Remember Me
                </div>
              </div>
              <div className="mt-20 mb-20">
                <CButton
                  addClass="primary text-size-20 py-10 full large"
                  mainClass="w-full"
                  type="submit"
                >
                  Sign In
                </CButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
