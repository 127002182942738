import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    image: null,
    result: ''
}

export const textToImageSlice = createSlice({
    name: "textToImage",
    initialState,
    reducers: {
        setImage: (state, action) => {
            state.image = action.payload
        },
        setResult: (state, action) => {
            state.result = action.payload
        }
    }
})

export const {
    setImage,
    setResult
} = textToImageSlice.actions;

export default textToImageSlice.reducer