import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import { useState, memo } from "react";
import { CButton } from "components/atoms/CButton";

import "./index.scss";
import { CInput } from "components/atoms/CInput";

function WebinarModal({
  show = false,
  webinar = {},
  onClose = () => {},
  onHandleUpdate = () => {},
}) {
  const [values, setValues] = useState({
    url: webinar?.url || "",
    title: webinar?.title || "",
  });

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClose = () => {
    onClose();

    setValues({
      url: "",
      title: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (webinar) {
      onHandleUpdate({ ...webinar, ...values });
    } else {
      onHandleUpdate(values);
    }

    handleClose();
  };

  return (
    <Modal
      isOpen={show}
      ariaHideApp={false}
      onClick={handleClose}
      className="webinar-modal"
    >
      <IconClose className="cursor-pointer ico-close" onClick={onClose} />
      <div className="webinar-area">
        <h3 className="text-center">
          Webinar {webinar?.id ? "Edit" : "Create"}
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="webinar-card mt-35">
            <label>
              Webinar URL:
              <CInput
                type="url"
                placeholder="Webinar URL"
                value={values.url}
                name="url"
                onChange={handleChange}
                addClass="product"
                required
              />
            </label>
            <label>
              Title:
              <CInput
                type="text"
                placeholder="Title"
                value={values.title}
                name="title"
                onChange={handleChange}
                required
                addClass="product"
              />
            </label>
            <CButton
              addClass="primary light text-size-20 py-10 full large"
              mainClass="w-full"
              type="submit"
            >
              {webinar?.id ? "Update" : "Save"}
            </CButton>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default memo(WebinarModal);
