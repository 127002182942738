import React from "react";
import { ReactComponent as IconEdit } from "assets/imgs/action/icon_edit.svg";
import { ReactComponent as IconRemove } from "assets/imgs/action/icon_delete.svg";
import { ReactComponent as IconLogin } from "assets/imgs/admin/dashboard/login_black.svg";
import { ReactComponent as IconPig } from "assets/imgs/modal/pig.svg";
import { Tooltip } from "react-tooltip";
import { format } from "date-fns";
import { LICENSE } from "utils/constants";
import getFullName from "utils/getFullName";

export default function UserTable({
  slice = [],
  range = [],
  page = 0,
  handleTblEdit = () => { },
  handleTblCredit = () => { },
  handleTblUserLogin = () => { },
  handleTblDelete = () => { },
  setPage = () => { },
}) {
  const shortName = (userProfile) => {
    if (userProfile.first_name !== "") {
      return userProfile.first_name.substring(0, 2);
    } else {
      return userProfile.email.substring(0, 2);
    }
  };

  return (
    <div className="custom-table mt-25 mb-60">
      <table>
        <thead>
          <tr>
            <th className="tableHeader">Role</th>
            <th className="tableHeader">Photo</th>
            <th className="tableHeader">Name</th>
            <th className="tableHeader">Email</th>
            <th className="tableHeader">License Type</th>
            <th className="tableHeader">Source</th>
            <th className="tableHeader">Credits Usage</th>
            <th className="tableHeader">Status</th>
            <th className="tableHeader">Created At</th>
            <th className="tableHeader">Action</th>
          </tr>
        </thead>
        <tbody>
          {slice.length === 0 ? (
            <tr>
              <td colSpan="9" className="tableCell text-center">
                No records found
              </td>
            </tr>
          ) : (
            slice.map((el) => (
              <tr key={el.id}>
                <td className="tableCell">{el.role}</td>
                <td className="tableCell">
                  <div className="user-initial">
                    {el.profile_img ? (
                      <div className="user-avatar">
                        <img src={el.profile_img} alt="profile" />
                      </div>
                    ) : (
                      <div className="user-avatar">{shortName(el)}</div>
                    )}
                  </div>
                </td>
                <td className="tableCell">
                  {getFullName(el)}
                </td>
                <td className="tableCell">{el.email}</td>
                <td className="tableCell">{el.license.join(', ')}</td>
                <td className="tableCell">{el.source === 'paddle' ? 'Paddle' : 'JvZoo'}</td>
                <td className="tableCell">
                  {el.license.includes(LICENSE.PROFESSIONAL) ? "Unlimited" : el.credits}
                  {/* {el.license === LICENSE.PROFESSIONAL ? "Unlimited" : el.credits} */}
                </td>
                <td className="tableCell">{el.status}</td>
                <td className="tableCell">{format(new Date(el.created_at), 'dd MMM, yyyy')}</td>
                <td className="tableCell text-center">
                  <IconEdit
                    data-tooltip-id="tip-edit"
                    data-tooltip-variant="light"
                    data-tooltip-content="Edit"
                    onClick={() => handleTblEdit(el)}
                    className="mr-10"
                  />
                  <IconPig
                    data-tooltip-id="tip-credit"
                    data-tooltip-variant="light"
                    data-tooltip-content="Credit Histories"
                    onClick={() => handleTblCredit(el)}
                    className="mr-10"
                  />
                  <IconLogin
                    data-tooltip-id="tip-direct-login"
                    data-tooltip-variant="light"
                    data-tooltip-content="Direct Login"
                    onClick={() => handleTblUserLogin(el)}
                    className="mr-10"
                  />
                  <IconRemove
                    data-tooltip-id="tip-delete"
                    data-tooltip-variant="light"
                    data-tooltip-content="Delete"
                    onClick={() => handleTblDelete(el)}
                  />
                </td>
              </tr>
            )))}
        </tbody>
      </table>
      {range.length > 0 && (
        <div className="pagination mb-60 mt-10">
          <button
            onClick={() => setPage(1)}
            disabled={page === 1 || range.length === 1}
          >
            {"<<"}
          </button>
          <button
            onClick={() => setPage(page - 1)}
            disabled={page === 1 || range.length === 1}
          >
            {"<"}
          </button>
          <span>
            Page{" "}
            <strong>
              {page} of {range.length}
            </strong>{" "}
          </span>
          <button
            onClick={() => setPage(page + 1)}
            disabled={range.length === 1 || page === range.length}
          >
            {">"}
          </button>
          <button
            onClick={() => setPage(range.length)}
            disabled={page === range.length}
          >
            {">>"}
          </button>
        </div>
      )}
      <Tooltip id="tip-edit" />
      <Tooltip id="tip-direct-login" />
      <Tooltip id="tip-delete" />
      <Tooltip id="tip-credit" />
    </div>
  );
}
