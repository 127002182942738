import { LICENSE } from "./constants";

export const isPossible = (license, permission) => {
  /* Old Logic */
  // const licenses = [
  //   LICENSE.FREE_TRIAL,
  //   LICENSE.STARTER,
  //   LICENSE.BUSINESS,
  //   LICENSE.PROFESSIONAL,
  //   LICENSE.ANIMATION,
  //   LICENSE.AGENCY
  // ];
  // const curIndex = licenses.findIndex((ls) => ls === license);
  // const permissionIndex = licenses.findIndex((ls) => ls === permission);

  // if (permissionIndex <= curIndex) return true;
  // else return false;

  const licenseIncludesPermission = Array.isArray(license) ? license.includes(permission) : license === permission;
  const permissionExists = Object.values(LICENSE).includes(permission);

  return licenseIncludesPermission && permissionExists;
};

export const getLicense = (user) => {
  /* Old Logic */
  // if (user?.license !== "" && Object.values(LICENSE).includes(user?.license)) {
  //   return user?.license;
  // } else {
  //   return LICENSE.FREE_TRIAL;
  // }

  if (Array.isArray(user?.license) && user?.license.length > 0) {
    const validLicenses = user.license.filter(license => license !== "" && Object.values(LICENSE).includes(license));
    if (validLicenses.length > 0) {
      // Check if FREE_TRIAL license is already included
      const hasFreeTrial = validLicenses.includes(LICENSE.FREE_TRIAL);
      // If FREE_TRIAL is not included, add it to the valid licenses array
      if (!hasFreeTrial) {
        validLicenses.push(LICENSE.FREE_TRIAL);
      }
      return validLicenses;
    } else {
      // If no valid licenses found, return FREE_TRIAL license
      return [LICENSE.FREE_TRIAL];
    }
  }
};

export const showLicense = (license, simple = false) => {
  if (simple && license === LICENSE.AGENCY) return "Agency";
  if (simple && license === LICENSE.ANIMATION) return "Animation";
  if (license === LICENSE.FREE_TRIAL) return "Free Trial";
  if (simple && license === LICENSE.PROFESSIONAL) return "Pro";

  return license;
};

export const checkPermission = (user) => {
  if (user?.user.status === "inactive") return false;
  return true;
};
