import { useEffect } from "react";
import { useMemo, useState } from "react";

// import getImageUrl from "utils/getImageUrl";
import { ReactComponent as ArrowLeftSvg } from "./images/arrowLeft.svg";
import { ReactComponent as ArrowRightSvg } from "./images/arrowRight.svg";

import { MAIN_OBJECT_SLIDER } from "constants/studio";

import "./index.scss";

const GeneratedImagesSlider = ({ images, onAccept, onCancel }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalImages, setTotalImages] = useState(images.length);

  useEffect(() => {
    setTotalImages(images.length);
    setActiveIndex(0);
  }, [images]);

  const activeSlide = useMemo(
    () => images?.[activeIndex],
    [activeIndex, images]
  );

  const onIncrement = () => {
    if (activeIndex < totalImages - 1) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
  };

  const onDecrement = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div className="generated-images" id={MAIN_OBJECT_SLIDER}>
      {totalImages > 0 && activeSlide ? (
        <>
          <img
            key={activeSlide.id}
            src={activeSlide.imagePath}
            alt={`${activeSlide.promptText}${activeSlide.id}`}
          />
          <div className="generated-images__actions">
            <button onClick={onDecrement} className="actions__control">
              <ArrowLeftSvg />
            </button>
            <p className="actions__title">
              {activeIndex + 1}/{totalImages}
            </p>
            <button onClick={onIncrement} className="actions__control">
              <ArrowRightSvg />
            </button>
            <div className="actions__buttons">
              <button onClick={onCancel} className="actions__button">
                Cancel
              </button>
              <span>|</span>
              <button
                onClick={() => onAccept(activeSlide)}
                className="actions__button"
              >
                Accept
              </button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default GeneratedImagesSlider;
