import { Tooltip } from "react-tooltip";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ACTION_MODES,
  EDITOR_ACTIONS,
  EDITOR_ACTION_TYPE,
  FILE_INPUT_ID,
  UPLOAD_FILE_TYPES,
} from "constants/studio";

import { EditorControlButton } from "components/atoms/EditorControlButton";
import { getActiveMode, getBrushSize, getEraserSize, getIsDownloading, getIsUserChooseImage } from "store/utils";
import {
  setBrushSize,
  setEraserSize,
} from "store/slices/studio";
import { ReactComponent as KeyboardSvg } from './images/keyboard.svg';
import useStudioCreator from "hooks/studio/useStudioCreator";
import useClickOutside from "hooks/useClickOutside";
import useContent from "hooks/studio/useContent";
import useHistoryDispatch from "hooks/history/useHistoryStudio";

import "./index.scss";
import KeyboardPopup from "./components/KeyboardPopup";
import { useContext } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";

export function StudioHeader() {
  const dispatch = useDispatch();
  const activeMode = useSelector(getActiveMode);
  const brushSize = useSelector(getBrushSize);
  const eraserSize = useSelector(getEraserSize);
  const isDownloading = useSelector(getIsDownloading)
  const ref = useRef()
  const isUserChoosing = useSelector(getIsUserChooseImage)
  const { updateCanvasLayout, onChangeMode } = useStudioCreator();
  const { onUploadImage } = useContent();
  const { hasFuture, hasPast } = useHistoryDispatch();
  const { loading } = useContext(LayoutContext)

  const [brushSettingsOpen, setBrushSettingsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const settingsRef = useRef(null);
  const fileInputRef = useRef(null);
  const dropdownActions = [ACTION_MODES.brush, ACTION_MODES.eraser];

  const onItemClick = (type) => {
    const callback = () => {
      if (type in ACTION_MODES) {
        if (dropdownActions.includes(type)) {
          setBrushSettingsOpen(type);
        }

        return;
      }
    }
    onChangeMode(type, callback)
  };

  const handleImageUpload = async (event) => {
    const filteredFiles = [];
    const files = event.target.files;
    const imgPromises = [];

    for (let file of files) {
      if (UPLOAD_FILE_TYPES.includes(file.type)) {
        filteredFiles.push(file);

        const imgPromise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              resolve({ img, file });
            };
            img.onerror = reject;
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
        });
        imgPromises.push(imgPromise);
      }
    }

    await Promise.all(
      imgPromises.map(async (res, i) => {
        const { img, file } = await res;

        const isLast = i === imgPromises.length - 1;
        onUploadImage({ image: img, file, isLast });
      })
    );

    updateCanvasLayout();
  };

  const getTooltipContent = (action) => {
    return action.title;
  };

  useClickOutside(settingsRef, () => setBrushSettingsOpen(null));

  const onChangeSize = (type, value) => {
    switch (type) {
      case ACTION_MODES.brush:
        dispatch(setBrushSize(value));
        break;
      case ACTION_MODES.eraser:
        dispatch(setEraserSize(value));
        break;
      default:
    }
  };

  const isDisabled = (action) => {
    if (loading) return true;

    switch (action.type) {
      case EDITOR_ACTION_TYPE.undo:
        return !hasPast || isUserChoosing;
      case EDITOR_ACTION_TYPE.redo:
        return !hasFuture || isUserChoosing;
      case EDITOR_ACTION_TYPE.download:
        return isDownloading || isUserChoosing;
      case EDITOR_ACTION_TYPE.brush:
      case EDITOR_ACTION_TYPE.backgroundRemove:
      case EDITOR_ACTION_TYPE.text:
      case EDITOR_ACTION_TYPE.removeText:
      case EDITOR_ACTION_TYPE.upload:
      case EDITOR_ACTION_TYPE.upscale:
      case EDITOR_ACTION_TYPE.eraser:
        return isUserChoosing;
      default:
        return false;
    }
  };

  useClickOutside(ref, () => setIsPopupOpen(false))

  return (
    <div className="studio-header-wrap">
      <ul className="studio-header-list header-list">
        {EDITOR_ACTIONS.map((action) => (
          <Fragment key={action.type}>
            <li className="header-list__item " data-tooltip-id={action.type}>
              <EditorControlButton
                isActive={action.type === activeMode}
                onClick={() => onItemClick(action.type)}
                disabled={isDisabled(action)}
              >
                {action.Icon && <action.Icon />}
              </EditorControlButton>
              {brushSettingsOpen === action.type && (
                <div className="header-list__dropdown" ref={settingsRef}>
                  <input
                    type="range"
                    value={
                      action.type === ACTION_MODES.eraser
                        ? eraserSize
                        : brushSize
                    }
                    onChange={(e) => onChangeSize(action.type, e.target.value)}
                  />
                </div>
              )}
            </li>
            {brushSettingsOpen !== action.type && (
              <Tooltip
                id={action.type}
                content={getTooltipContent(action)}
                className="header-list__tooltip"
              />
            )}
          </Fragment>
        ))}
      </ul>
      <div className="header-list__popup-wrap" ref={ref}>
        <button type='button' className="header-list__item" onClick={() => setIsPopupOpen(p => !p)}>
          <KeyboardSvg />
        </button>
        {isPopupOpen && <KeyboardPopup />}
      </div>

      <input
        type="file"
        hidden
        ref={fileInputRef}
        id={FILE_INPUT_ID}
        multiple
        onChange={handleImageUpload}
        onClick={() => {
          fileInputRef.current.value = null;
        }}
        accept=".png, .webp, .jpg, .jpeg"
      />
    </div>
  );
}
