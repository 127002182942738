import heic2any from "heic2any";

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? "#333333" : "#333333",
      color: "#FFF",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

export const convertHeicImage = async (file) => {
  const blobURL = URL.createObjectURL(file);
  let res = file;

  await fetch(blobURL)
    .then((res) => res.blob())
    .then((blob) => heic2any({ blob }))
    .then((conversionResult) => {
      res = conversionResult;
    })
    .catch(() => {});

  return res;
};

export const STAGES = {
  upload: "upload",
  edit: "edit",
  result: "result",
};
