import Label, { LABEL_COLORS } from "components/atoms/Label";
import { ReactComponent as LockSvg } from "assets/imgs/lock.svg";
import { ReactComponent as UnlockSvg } from "assets/imgs/unlock.svg";

const ProLabel = ({ isHover, onClick = () => { } }) => {
  const color = isHover ? LABEL_COLORS.green : LABEL_COLORS.purple;
  const Icon = isHover ? UnlockSvg : LockSvg;

  return (
    <Label color={color} Icon={<Icon />} onClick={onClick}>
      {isHover ? "Unlock" : "Pro"}
    </Label>
  );
};

export default ProLabel;
