import logo from "assets/imgs/logo.svg";
import "./styles.scss";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { MenuItem } from "../MenuItem";
import Youtube from "assets/imgs/social/youtube.svg";
import Twitter from "assets/imgs/social/twitter.svg";
import Discord from "assets/imgs/social/discord.svg";
import Facebook from "assets/imgs/social/facebook.svg";

import IconHome from "assets/imgs/menu/icon_home.svg";
import IconBrand from "assets/imgs/menu/icon_brand.svg";
import IconCreate from "assets/imgs/menu/icon_text_img.svg";
import IconEdit from "assets/imgs/menu/icon_photoshoot.svg";
import IconRemover from "assets/imgs/menu/icon_remover.svg";
import IconAssets from "assets/imgs/menu/icon_assets.svg";
import IconCommunity from "assets/imgs/menu/icon_community.svg";
import IconPersonal from "assets/imgs/menu/icon_personal.svg";
import IconEditor from "assets/imgs/menu/icon_editor.svg";
import IconAIRemover from "assets/imgs/modal/ai_remover.svg";
import TextEraserSvg from "assets/imgs/studioCreator/textEraser.svg";
import IconPlay from "assets/imgs/menu/icon_play.svg";
import IconUser from "assets/imgs/admin/dashboard/user.svg";

import IconSetting from "assets/imgs/menu/icon_setting.svg";
import IconVideo from "assets/imgs/menu/icon_video.svg";
import ViewImg from "assets/imgs/create/view.svg";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as ArrowDown } from "assets/imgs/dropdown/arrow_down.svg";
import { LICENSE } from "utils/constants";
import { useCallback } from "react";
import UserIcon from "./UserIcon";

export function Sidebar() {
  const { mobileSide, user } = useContext(LayoutContext);
  const [openMenu, setOpenMenu] = useState({
    create: false,
    magictool: false,
    animation: false,
    agency: false,
  });
  const { pathname } = useLocation();

  const onClickDropdownMenu = (menuName) => {
    setOpenMenu(prevState => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };
  const isDropdownMenuActive = (link) => {
    return pathname.indexOf("/" + link) !== -1 ? "active" : "";
  };
  const getActiveMenu = useCallback(() => {
    const activeMenus = Object.keys(openMenu).filter(menu => pathname.indexOf(`/${menu}`) !== -1);
    setOpenMenu(prevState => ({
      ...prevState,
      ...Object.fromEntries(activeMenus.map(menu => [menu, true])),
    }));
  }, [pathname]);

  useEffect(() => {
    getActiveMenu();
  }, [getActiveMenu]);

  return (
    <div className={`sidebar-container ${mobileSide ? "mobile" : ""}`}>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="menu">
        <MenuItem user={user} icon={IconHome} link="dashboard" label="Home" />
        {/* <MenuItem
          user={user}
          permission={LICENSE.PROFESSIONAL}
          icon={IconBrand}
          link="newbrand"
          label="Brand"
        /> */}
        <MenuItem
          user={user}
          icon={IconEditor}
          link="studio-creator"
          beta
          label="AI Studio Creator"
          permission={LICENSE.PROFESSIONAL}
        />
        <div className={`menu-dropdown ${openMenu.create ? "active" : ""}`}>
          <div
            className={`dropdown-title ${isDropdownMenuActive("create")}`}
            onClick={() => onClickDropdownMenu("create")}
          >
            <img src={ViewImg} className="menu-icon" alt="menu" />
            <span className="menu-text pl-10">Create</span>
            <ArrowDown className="arrow-down" />
          </div>
          <div className="dropdown-area">
            <MenuItem
              user={user}
              icon={IconCreate}
              link="create/text-to-image"
              label="Text to Image"
            />
            <MenuItem
              user={user}
              icon={IconEdit}
              link="create/photoshoot"
              label="Product Photoshoot"
            />
            {/* <MenuItem
              user={user}
              icon={IconEdit}
              link="create/edit"
              label="Product Photoshoot"
            /> */}
          </div>
        </div>
        <div className={`menu-dropdown ${openMenu.magictool ? "active" : ""}`}>
          <div
            className={`dropdown-title ${isDropdownMenuActive("magictool")}`}
            onClick={() => onClickDropdownMenu("magictool")}
          >
            <img src={ViewImg} className="menu-icon" alt="menu" />
            <span className="menu-text pl-10">AI Magic Tools</span>
            <ArrowDown className="arrow-down" />
          </div>
          <div className="dropdown-area">
            <MenuItem
              user={user}
              icon={IconAIRemover}
              link="magictool/ai-background-remover"
              label="Remove Background"
            />
            <MenuItem
              user={user}
              icon={IconRemover}
              link="magictool/magic-remover"
              label="Magic Remover"
              permission={LICENSE.PROFESSIONAL}
            />
            <MenuItem
              user={user}
              icon={TextEraserSvg}
              link="magictool/text-remover"
              label="Text Remover"
              permission={LICENSE.PROFESSIONAL}
            />
            <MenuItem
              user={user}
              icon={IconEdit}
              link="magictool/image-enhancer"
              label="Image Enhancer"
              permission={LICENSE.PROFESSIONAL}
            />
            <MenuItem
              user={user}
              icon={TextEraserSvg}
              link="magictool/image-to-text"
              label="Image to Text"
              permission={LICENSE.PROFESSIONAL}
            />
            <MenuItem
              user={user}
              icon={IconRemover}
              link="magictool/search-replace-object"
              label="Search & Replace"
              permission={LICENSE.PROFESSIONAL}
            />
            <MenuItem
              user={user}
              icon={IconEdit}
              link="magictool/color-changer"
              label="Color Changer"
              permission={LICENSE.PROFESSIONAL}
            />
          </div>
        </div>
        <div className={`menu-dropdown ${openMenu.animation ? "active" : ""}`}>
          <div
            className={`dropdown-title ${isDropdownMenuActive("animation")}`}
            onClick={() => onClickDropdownMenu("animation")}
          >
            <img src={ViewImg} className="menu-icon" alt="menu" />
            <span className="menu-text pl-10">Animation Studio</span>
            <ArrowDown className="arrow-down" />
          </div>
          <div className="dropdown-area">
            <MenuItem
              user={user}
              icon={IconPlay}
              link="animation/image-to-animation"
              label="Image to Animation"
              permission={LICENSE.ANIMATION}
            />
          </div>
          <div className="dropdown-area">
            <MenuItem
              user={user}
              icon={IconAssets}
              link="animation/animation-feed"
              label="Animation Feed"
              permission={LICENSE.ANIMATION}
            />
          </div>
        </div>
        <div className={`menu-dropdown ${openMenu.agency ? "active" : ""}`}>
          <div
            className={`dropdown-title ${isDropdownMenuActive("agency")}`}
            onClick={() => onClickDropdownMenu("agency")}
          >
            <img src={ViewImg} className="menu-icon" alt="menu" />
            <span className="menu-text pl-10">Agency</span>
            <ArrowDown className="arrow-down" />
          </div>
          <div className="dropdown-area">
            <MenuItem
              user={user}
              icon={IconUser}
              link="agency/clients"
              label="Manage Clients"
              permission={LICENSE.AGENCY}
            />
            <MenuItem
              user={user}
              icon={IconUser}
              link="agency/members"
              label="Team Members"
              permission={LICENSE.AGENCY}
            />
          </div>
        </div>
        <MenuItem
          user={user}
          icon={IconAssets}
          link="assets"
          label="Your Assets"
        />
        <MenuItem
          user={user}
          permission={LICENSE.PROFESSIONAL}
          icon={IconBrand}
          link="brand"
          label="Brand Kit"
        />
        <MenuItem
          user={user}
          icon={IconCommunity}
          link="community"
          label="Community Feed"
        />
        <MenuItem
          user={user}
          icon={IconPersonal}
          link="my-feed"
          label="Personal Feed"
        />
        <MenuItem
          user={user}
          link="video-training"
          icon={IconVideo}
          label="Video Training"
        />
        <MenuItem
          user={user}
          icon={IconSetting}
          link="setting"
          label="Settings"
        />
      </div>
      <div className="footer">
        <UserIcon user={user} />
      </div>
      <div className="social">
        <a
          href="https://www.youtube.com/channel/UC_aV_vW4KhVPjRxCLOK7iyg"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Youtube} alt="youtube" />
        </a>
        <a
          href="https://twitter.com/creativioai"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Twitter} alt="twitter" />
        </a>
        <a
          href="https://discord.gg/bvASP5TKkB"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Discord} alt="discord" />
        </a>
        <a
          href="https://www.facebook.com/creativioaiofficial"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Facebook} alt="facebook" />
        </a>
      </div>
    </div>
  );
}
