import { useState, useRef, useEffect } from 'react';
import { ReactComponent as ArrowDown } from 'assets/imgs/dropdown/arrow_down.svg';
import './styles.scss'

export function StylePopUp({
    id, // "all" || popular || effects
    addClass = "",
    action,
    list = [],
}) {
    const popUpRef = useRef(null);

    const handleClose = () => {
        action(id, "close");
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target)) {
                handleClose();
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);

    return (
        <div className={`style-popup ${addClass}`} ref={popUpRef}>
            <ArrowDown className={`arrow-down ${addClass}`} />
            <div className="style-area">
                {list.map((item, index) => (
                    <div key={item.key} className={`popup-item ${item.checked ? 'active' : ''}`}>
                        <div className="item" onClick={() => action(id, "select", item.key)}>
                            <div className='img-wrap'><img src={item.icon} alt={item.label} /></div>
                            {item.label}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}