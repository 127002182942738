import { ReactComponent as StudioSvg } from "../../images/studio.svg";
import { ReactComponent as CloseSvg } from "../../images/close.svg";

import "./index.scss";
import { Link } from "react-router-dom";

const EnhancedModel = ({ onClose, name }) => {
  return (
    <div className="enhanced-model">
      <button className="pro-plan__close-btn" onClick={onClose}>
        <CloseSvg />
      </button>
      <div>
        <h2 className="enhanced-model__announcement">Update Announcement:</h2>
        <h2 className="enhanced-model__title">
          Introducing Our Enhanced Product AI Model!
        </h2>
        <p className="enhanced-model__subtitle">
          Experience the power of our newly optimized AI model, meticulously
          fine-tuned to transform and elevate your product images. Dive in and
          see the difference for yourself.
        </p>
      </div>
      <div className="enhanced-model__upgrade">
        <StudioSvg className="enhanced-model__icon" />
        <Link to="/setting">
          <span className="enhanced-model__button">Switch To New Model</span>
        </Link>
      </div>
    </div>
  );
};

export default EnhancedModel;
