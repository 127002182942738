import React from 'react';
import Modal from 'react-modal';
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import { CButton } from "components/atoms/CButton";
import "./index.scss";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, itemModule, itemName }) => {
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onClick={onClose}
      className="delete-modal"
    >
      <IconClose className="cursor-pointer ico-close" onClick={onClose} />
      <div className="delete-area">
        <h2>Delete {itemModule}</h2>
        <p>{`Are you sure you want to delete ${itemName}?`}</p><br />
        <CButton
          mainClass="mt-5"
          addClass="gradient p-5I"
          active={true}
          onClick={onClose}
        >
          Cancel
        </CButton>
        <CButton
          mainClass="mt-5"
          addClass="gradient p-5I"
          active={true}
          onClick={onConfirm}
        >
          Confirm
        </CButton>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
