import { configureStore } from "@reduxjs/toolkit";
import studioSlice from "./slices/studio";
import magicRemover from "./slices/magicRemover/reducer";
import textRemover from "./slices/textRemover/reducer";
import appSlice from "./slices/app";
import enhance from "./slices/enhance/reducer";
import imageToText from "./slices/imageToText/reducer";
import imageToVideo from "./slices/imageToVideo/reducer";
import replaceObject from "./slices/replaceObject/reducer";
import colorChanger from "./slices/colorChanger/reducer";

export const store = configureStore({
  reducer: {
    studio: studioSlice,
    magicRemover,
    textRemover,
    enhance,
    imageToText,
    imageToVideo,
    replaceObject,
    colorChanger,
    app: appSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
