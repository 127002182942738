import {
  ACTION_MODES,
  CTRL_HOTKEYS,
  DRAW_OBJECT_TYPES,
  EDITOR_ACTION_TYPE,
  FILE_INPUT_ID,
  HOTKEYS,
  MOVEMENT_TYPES,
} from "constants/studio";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onResize,
  setSelectedItem,
  setUserInteract,
  zoomIn,
  zoomOut,
  setMainObjectLocked,
  setOffsetX,
  setOffsetY,
  setScale,
  deleteLayer,
  setActiveMode,
  setIsDownloading,
} from "store/slices/studio";
import {
  getActiveMode,
  getIsDownloading,
  getIsUserInteract,
  getLayers,
  getMainObjectLocked,
  getMovementType,
  getOffsetX,
  getOffsetY,
  getScale,
  getSelectedItem as getSelectedItemAction,
} from "store/utils";

import useBackground from "./useBackground";
import useCursor from "./useCursor";
import useBrush from "./useBrush";
import LayerObject from "lib/LayerObject";
import useText from "./useText";
import onDownload, { onDownloadLayer } from "utils/studio/onDownload";
import useHistoryDispatch from "hooks/history/useHistoryStudio";
import useContent from "./useContent";

const useStudioCreator = () => {
  const dispatch = useDispatch();

  const { historyLayer, undo, redo } = useHistoryDispatch();
  const { onDrawMainObject, onDrawGrid, onClearBackground, onPanning, clearControls } = useBackground();
  const { onClearCursor } = useCursor();
  const { onClearBrush, onBrush, onStartDraw, onErase } = useBrush();
  const { onStartText, onEditText, onStopText } = useText();
  const { onSelect, onStartSelect, onStopSelect, onRemoveBackground, onRemoveText, onUpscale } = useContent();

  const mainObjectLocked = useSelector(getMainObjectLocked);
  const activeMode = useSelector(getActiveMode);
  const isUserInteract = useSelector(getIsUserInteract);
  const movementType = useSelector(getMovementType);
  const layers = useSelector(getLayers);
  const scale = useSelector(getScale);
  const offsetX = useSelector(getOffsetX);
  const offsetY = useSelector(getOffsetY);
  const selectedItem = useSelector(getSelectedItemAction);
  const isDownloading = useSelector(getIsDownloading)

  const onClearCanvas = useCallback(() => {
    onClearBackground();
    onClearCursor();
    onClearBrush();
  }, [onClearBackground, onClearBrush, onClearCursor]);

  const onRedrawLayers = useCallback(() => {
    LayerObject.drawLayers(layers, {
      scale,
      offsetX,
      offsetY,
    });

    if (selectedItem) {
      selectedItem.drawResizeRect({ offsetX, offsetY, scale });
    }
  }, [layers, offsetX, offsetY, scale, selectedItem]);


  const updateCanvasLayout = useCallback(() => {
    onClearCanvas();
    onDrawGrid();
    onDrawMainObject();
    onRedrawLayers();
  }, [onClearCanvas, onDrawGrid, onDrawMainObject, onRedrawLayers]);

  const onSetActiveMode = (oldMode) => {
    if (oldMode === ACTION_MODES.text) {
      onStopText();
    }
    dispatch(setSelectedItem(null));
  };

  const handleResize = useCallback(() => {
    dispatch(onResize());
    updateCanvasLayout();
  }, [dispatch, updateCanvasLayout]);



  const onZoomIn = () => {
    historyLayer();
    dispatch(zoomIn());
    updateCanvasLayout();
  };

  const onZoomOut = () => {
    historyLayer();
    dispatch(zoomOut());
    updateCanvasLayout();
  };

  const onLockClick = () => {
    historyLayer();
    dispatch(setMainObjectLocked(!mainObjectLocked));
  };

  const onMouseDown = (event) => {
    historyLayer();
    dispatch(setUserInteract(true));

    switch (activeMode) {
      case ACTION_MODES.brush:
      case ACTION_MODES.eraser:
        onStartDraw(event);
        break;
      case ACTION_MODES.select:
        onStartSelect(event);
        break;
      case ACTION_MODES.text: {
        onStartText(event);
        break;
      }
      default:
    }
  };

  const onMouseUp = () => {
    dispatch(setUserInteract(false));

    switch (activeMode) {
      case ACTION_MODES.select:
        onStopSelect();
        break;
      default:
    }
  };

  const onMouseMove = (e) => {
    switch (activeMode) {
      case ACTION_MODES.pan:
        onPanning(e);
        updateCanvasLayout();
        break;
      case ACTION_MODES.select:
      case ACTION_MODES.text:
        onSelect(e);
        break;
      case ACTION_MODES.brush:
        onBrush(e);
        break;
      case ACTION_MODES.eraser:
        onErase(e);
        break;
      default:
    }
  };

  const resizeCursor = useMemo(() => {
    switch (movementType) {
      case MOVEMENT_TYPES.resize_A:
        return "nwse-resize";
      case MOVEMENT_TYPES.resize_B:
        return "nesw-resize";
      case MOVEMENT_TYPES.resize_C:
        return "nwse-resize";
      case MOVEMENT_TYPES.resize_D:
        return "nesw-resize";
      case MOVEMENT_TYPES.resize_AB:
      case MOVEMENT_TYPES.resize_CD:
        return "ns-resize";
      case MOVEMENT_TYPES.resize_BC:
      case MOVEMENT_TYPES.resize_AD:
        return "ew-resize";
      default:
        return "default";
    }
  }, [movementType]);

  const cursorStyle = useMemo(() => {
    switch (activeMode) {
      case ACTION_MODES.pan:
        return isUserInteract ? "grabbing" : "grab";
      case ACTION_MODES.select:
      case ACTION_MODES.text:
        return resizeCursor;
      default:
        return "default";
    }
  }, [activeMode, isUserInteract, resizeCursor]);

  const onClearState = useCallback(() => {
    dispatch(setOffsetX(0));
    dispatch(setOffsetY(0));
    dispatch(setScale(1));
    dispatch(setSelectedItem(null));
  }, [dispatch]);


  const onChangeMode = (type, callback = () => { }) => {
    dispatch(setUserInteract(false));

    if (type in ACTION_MODES) {
      onSetActiveMode(activeMode);
      dispatch(setActiveMode(type));

      callback()

      return;
    }

    switch (type) {
      case EDITOR_ACTION_TYPE.upload:
        const fileInput = document.getElementById(FILE_INPUT_ID);
        fileInput?.click();
        break;
      case EDITOR_ACTION_TYPE.download:
        handleDownload();
        break;
      case EDITOR_ACTION_TYPE.backgroundRemove:
        onRemoveBackground();
        break;
      case EDITOR_ACTION_TYPE.removeText:
        onRemoveText();
        break;
      case EDITOR_ACTION_TYPE.undo:
        undo();
        break;
      case EDITOR_ACTION_TYPE.redo:
        redo();
        break;
      case EDITOR_ACTION_TYPE.upscale:
        onUpscale();
        break
      default:
    }
  }

  const onKeyDown = (e) => {
    if (e.ctrlKey && e.code in CTRL_HOTKEYS) {
      e.preventDefault()
      onChangeMode(CTRL_HOTKEYS[e.code]);
      return
    }

    if (!e.ctrlKey && e.code in HOTKEYS) {
      e.preventDefault()
      onChangeMode(HOTKEYS[e.code]);
      return
    }

    if (
      (e.key !== "Delete" && e.key !== "Backspace") ||
      !selectedItem ||
      selectedItem.type === DRAW_OBJECT_TYPES.mainRect
    )
      return;

    dispatch(deleteLayer(selectedItem));

    dispatch(setSelectedItem(null));
    clearControls();
  };

  const onDoubleClick = (event) => {
    if (activeMode !== ACTION_MODES.select && activeMode !== ACTION_MODES.text)
      return;

    onEditText(event);
  };

  const handleDownload = () => {
    if (isDownloading) return;
    dispatch(setIsDownloading(true));

    const callback = () => {
      dispatch(setIsDownloading(false));
    }

    if (selectedItem && selectedItem.type !== DRAW_OBJECT_TYPES.mainRect) {
      onDownloadLayer(selectedItem, callback)
    } else {
      onDownload(layers, callback);
    }
  };

  const onMouseLeave = (e) => {
    if (activeMode === ACTION_MODES.brush || activeMode === ACTION_MODES.eraser)
      return;
    onMouseUp(e);
  };

  return {
    onZoomIn,
    onZoomOut,
    onLockClick,
    onMouseMove,
    onMouseDown,
    onMouseUp,
    updateCanvasLayout,
    handleResize,
    onClearState,
    onDoubleClick,
    onKeyDown,
    handleDownload,
    onMouseLeave,
    onChangeMode,
    cursorStyle,
  };
};

export default useStudioCreator;
