import * as React from "react";
import { useContext, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { LICENSE } from "utils/constants";

import {
  checkPermission,
  getLicense,
  isPossible,
  showLicense,
} from "utils/util";

import "./styles.scss";

import ProLabel from "components/molecules/ProLabel";
import BetaLabel from "components/molecules/BetaLabel";
import AnimationLabel from "components/molecules/AnimationLabel";
import AgencyLabel from "components/molecules/AgencyLabel";

export function MenuItem({
  icon,
  label = "",
  link = "#",
  permission = LICENSE.FREE_TRIAL,
  user,
  beta,
}) {
  const { showNoti, showMembershipModal, showAnimationMembershipModal, showAgencyMembershipModal } = useContext(LayoutContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isHover, setIsHover] = useState(false);
  const userLicense = useMemo(() => getLicense(user?.user), [user]);

  const isActive = () => {
    if (pathname.indexOf("/" + link) !== -1) {
      return "active";
    } else {
      return "";
    }
  };

  const goTo = () => {
    const ActiveUserLinks = [
      "create/text-to-image",
      "create/photoshoot",
      "magictool/image-to-text",
      "magictool/image-enhancer",
      "magictool/magic-remover",
      "magictool/text-remover",
      "magictool/ai-background-remover",
      "magictool/search-replace-object",
      "magictool/color-changer",
      "animation/image-to-animation",
      "animation/animation-feed",
      "agency/clients",
      "agency/members"
    ];

    if (ActiveUserLinks.includes(link)) {
      if (!checkPermission(user)) {
        showNoti(
          "error",
          "Permission denied. Please contact the administrator."
        );
        return;
      }
    }
    if (isPossible(userLicense, permission)) {
      navigate("/" + link);
    } else {
      const formatLicense = showLicense(permission, true);

      showNoti(
        "info",
        `You need to upgrade to a ${formatLicense} license to unlock this feature.`
      );
    }
  };

  const proLabelProps = useMemo(
    () =>
      (!Array.isArray(userLicense) && userLicense === LICENSE.FREE_TRIAL) ||
        (Array.isArray(userLicense) && userLicense.length === 1 && userLicense[0] === LICENSE.FREE_TRIAL)
        ? {}
        : {
          isHover,
          onClick: (e) => {
            e.stopPropagation();
            showMembershipModal();
          },
        },
    [isHover, showMembershipModal, userLicense]
  );

  const animationLabelProps = useMemo(
    () =>
      (!Array.isArray(userLicense) && userLicense === LICENSE.FREE_TRIAL) ||
        (Array.isArray(userLicense) && userLicense.length === 1 && userLicense[0] === LICENSE.FREE_TRIAL)
        ? {}
        : {
          isHover,
          onClick: (e) => {
            e.stopPropagation();
            showAnimationMembershipModal();
          },
        },
    [isHover, showAnimationMembershipModal, userLicense]
  );

  const agencyLabelProps = useMemo(
    () =>
      (!Array.isArray(userLicense) && userLicense === LICENSE.FREE_TRIAL) ||
        (Array.isArray(userLicense) && userLicense.length === 1 && userLicense[0] === LICENSE.FREE_TRIAL)
        ? {}
        : {
          isHover,
          onClick: (e) => {
            e.stopPropagation();
            showAgencyMembershipModal();
          },
        },
    [isHover, showAgencyMembershipModal, userLicense]
  );

  // const showProLabel =
  //   permission === LICENSE.PROFESSIONAL && userLicense !== LICENSE.PROFESSIONAL;

  const showProLabel =
    permission === LICENSE.PROFESSIONAL && (!Array.isArray(userLicense) || !userLicense.includes(LICENSE.PROFESSIONAL));

  const showAnimationLabel =
    permission === LICENSE.ANIMATION && (!Array.isArray(userLicense) || !userLicense.includes(LICENSE.ANIMATION));

  const showAgencyLabel =
    permission === LICENSE.AGENCY && (!Array.isArray(userLicense) || !userLicense.includes(LICENSE.AGENCY));

  return (
    <div
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={`menu-item ${isActive()}`}
      onClick={goTo}
    >
      <div className="menu-item__content">
        <img src={icon} className="menu-icon" alt="icon" />
        <span className="menu-text">{label}</span>
      </div>
      {showProLabel && <ProLabel {...proLabelProps} />}
      {showAnimationLabel && <AnimationLabel {...animationLabelProps} />}
      {showAgencyLabel && <AgencyLabel {...agencyLabelProps} />}
      {!showProLabel && beta && <BetaLabel />}
    </div>
  );
}
