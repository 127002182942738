import { getFormatData } from "./consts"
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import Chart from "./Chart";

import './index.scss';

ChartJS.register(ArcElement, Tooltip);


const LogsCharts = ({ statistics = {} }) => {
    return <div className="chart-list">
        <Chart data={getFormatData(statistics.totalApiCalls)} title='API Calls Chart' />
        <Chart data={getFormatData(statistics.photosGenerated)} title='Photos Chart' />
        <Chart data={getFormatData(statistics.productPhotos)} title='Product Chart' />
        <Chart data={getFormatData(statistics.testToImage)} title='Text to Image Chart' />
        <Chart data={getFormatData(statistics.aiStudioEditor)} title='Studio Chart' />
    </div>
}

export default LogsCharts