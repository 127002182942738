import { CButton } from "components/atoms/CButton";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import "./styles.scss"
import logo from 'assets/imgs/logo.svg';
import SocialLinks from "./SocialLinks";

export function AcceptInvitation() {
    const { token } = useParams();
    const navigate = useNavigate();
    const { setLoading, showNoti } = useContext(LayoutContext)
    const [isAccepted, setIsAccepted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            await getApi('/agency/accept-invitation', 'POST', { token });
            setIsAccepted(true);
            showNoti('success', 'Invitation accepted. Please log in!');
            setTimeout(() => {
                navigate('/login')
            }, 5000);
        } catch (err) {
            showNoti('error', err)
        }
        setLoading(false)
    }

    return (
        <div id="#login" className="auth-area">
            <div className="login">
                <div className='logo mt-15'>
                    <img src={logo} alt='logo' />
                </div>
                <div className="active-area">
                    {!isAccepted && <form onSubmit={handleSubmit}>
                        <div className="login-card mt-35">
                            <div className="my-20 text-center">
                                Welcome to Creativio.io – where creativity thrives!<br />
                                Accept invitation to dive into a world of limitless possibilities.<br />
                                With our diverse tools and vibrant community,<br />
                                Unleash your imagination and let your creativity soar.<br />
                                Join us today and be part of something extraordinary.<br />
                                Welcome to Creativio.io!
                            </div>
                            <div className="mt-20 mb-60">
                                <CButton addClass='primary text-size-20 py-10 full large' mainClass="w-full" type="submit">Accept Invitation</CButton>
                            </div>
                        </div>
                    </form>}
                    {isAccepted && <div className="my-60 text-center">
                        Login credentials sent to your email. Please check your inbox.
                    </div>}
                </div>
                <SocialLinks />
            </div>
        </div>
    )
}