const cornerCircleRadius = 6;

export const createNewCanvas = (width, height) => {
  const tempCanvas1 = document.createElement("canvas"); // Create a temporary canvas1
  tempCanvas1.width = width;
  tempCanvas1.height = height;
  return tempCanvas1;
};

const getLayerContext = (cvs) => {
  if (cvs) {
    return cvs.getContext("2d");
  } else {
    throw new Error("Canvas is not defined");
  }
};

export const drawBrush = (layer, maskType = "") => {
  const canvas = layer.canvas;

  const ctx = getLayerContext(canvas);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  const points = layer.data;
  ctx.beginPath();

  if (points.width && points.height) {
    if (maskType !== "") {
      ctx.fillStyle = maskType === "white" ? "#fff" : "#000";
    }
    ctx.fillRect(0, 0, points.width, points.height)
  } else {
    ctx.moveTo(points[0].x, points[0].y);
    for (let i = 0; i < points.length; i++) ctx.lineTo(points[i].x, points[i].y);

    ctx.lineWidth = layer.brushSize;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.strokeStyle = "#7371fc80"; // Set your desired color and transparency
    if (maskType !== "") {
      ctx.strokeStyle = maskType === "white" ? "#fff" : "#000";
    }
    ctx.stroke();
  }
  ctx.globalCompositeOperation = "source-out";
};

export const erase = (ctxOrigin, data, size, maskType = "") => {
  const ctx = ctxOrigin;
  ctx.globalCompositeOperation = "destination-out";
  ctx.beginPath();

  if (data.width && data.height) {
    ctx.fillRect(0, 0, data.width, data.height)
  } else if (data.length) {
    ctx.moveTo(data[0].x, data[0].y);
    for (let i = 0; i < data.length; i++) ctx.lineTo(data[i].x, data[i].y);

    ctx.lineWidth = size;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    ctx.stroke();
  }
  ctx.globalCompositeOperation = "source-over";
};

export const drawRect = (layer, maskType = "") => {
  if (layer.data.length < 2) return;
  const canvas = layer.canvas;
  const ctx = getLayerContext(canvas);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = "#7371fc80";
  if (maskType !== "") {
    ctx.fillStyle = maskType === "white" ? "#fff" : "#000";
  }
  const { x, y } = layer.data[0];
  const { x: x1, y: y1 } = layer.data[1];
  const width = x1 - x;
  const height = y1 - y;
  ctx.fillRect(x, y, width, height);
};

export const getMousePos = (canvas, evt) => {
  const rect = canvas.getBoundingClientRect();
  const x = evt.clientX - rect.left;
  const y = evt.clientY - rect.top;
  return { x, y };
};

export const drawCorners = (ctxOrigin, x, y, x1, y1) => {
  const w = x1 - x;
  const h = y1 - y;
  const ctx = ctxOrigin;
  ctx.beginPath();
  const dashPattern = [5, 10]; // Adjust the dash pattern (alternating line and gap lengths)
  ctx.setLineDash(dashPattern);
  ctx.strokeStyle = "white";
  ctx.lineWidth = 2;
  ctx.strokeRect(x, y, w, h);

  // Draw 4 small filled circles at the corners of the rectangle
  const cornerCirclePositions = [
    { x: x, y: y }, // Top-left corner
    { x: x + w, y: y }, // Top-right corner
    { x: x, y: y + h }, // Bottom-left corner
    { x: x + w, y: y + h }, // Bottom-right corner
  ];
  ctx.fillStyle = "white"; // Fill color for the circles
  cornerCirclePositions.forEach((corner) => {
    ctx.beginPath();
    ctx.arc(corner.x, corner.y, cornerCircleRadius, 0, 2 * Math.PI);
    ctx.fill();
  });

  // Draw 4 small filled circles in the middle of the rectangle
  const dtX = w / 2;
  const dtY = h / 2;
  // const dtX = Math.floor(w / 2)
  // const dtY = Math.floor(h / 2)
  const middleCirclePositions = [
    { x: x + dtX, y: y }, // Top-middle
    { x: x + dtX, y: y + h }, // Bottom-middle
    { x: x, y: y + dtY }, // Left-middle
    { x: x + w, y: y + dtY }, // Right-middle
  ];
  ctx.fillStyle = "white"; // Fill color for the circles
  middleCirclePositions.forEach((middle) => {
    ctx.beginPath();
    ctx.arc(middle.x, middle.y, cornerCircleRadius, 0, 2 * Math.PI);
    ctx.fill();
  });
};

/**
 *
 * @param {*} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} size
 * @param {"circle" | "ring"} type
 */
export const drawCursor = (ctx, x, y, size, type = "circle") => {
  ctx.beginPath();
  if (type === "ring") {
    ctx.arc(x, y, size / 2, 0, 2 * Math.PI, false);
    ctx.lineWidth = 1;
    ctx.strokeStyle = "white"; // Set the outline color to white
    ctx.stroke();
  } else {
    ctx.arc(x, y, size / 2, 0, 2 * Math.PI);
    ctx.fillStyle = "#7371fc80";
    ctx.fill();
  }
};

const checkIfInsideCircle = (mouseX, mouseY, cx, cy) => {
  const radius = cornerCircleRadius;
  const distanceSquared = (mouseX - cx) ** 2 + (mouseY - cy) ** 2;
  return distanceSquared <= radius ** 2;
};
const CURSOR_TYPE_INSIDE_RECT = {
  TOP_LEFT: { cursor: "nwse-resize", move: "TOP_LEFT" },
  TOP_RIGHT: { cursor: "nesw-resize", move: "TOP_RIGHT" },
  BOTTOM_RIGHT: { cursor: "nwse-resize", move: "BOTTOM_RIGHT" },
  BOTTOM_LEFT: { cursor: "nesw-resize", move: "BOTTOM_LEFT" },
  TOP_MIDDLE: { cursor: "ns-resize", move: "TOP_MIDDLE" },
  BOTTOM_MIDDLE: { cursor: "ns-resize", move: "BOTTOM_MIDDLE" },
  LEFT_MIDDLE: { cursor: "ew-resize", move: "LEFT_MIDDLE" },
  RIGHT_MIDDLE: { cursor: "ew-resize", move: "RIGHT_MIDDLE" },
  INSIDE: { cursor: "move", move: "MOVE" },
};
export const getCursorTypeInsideLastRect = (mouseX, mouseY, rect) => {
  let middleW = rect.w / 2;
  let middleH = rect.h / 2;
  if (checkIfInsideCircle(mouseX, mouseY, rect.x, rect.y)) {
    return CURSOR_TYPE_INSIDE_RECT.TOP_LEFT;
  }
  // corner B
  if (checkIfInsideCircle(mouseX, mouseY, rect.x + rect.w, rect.y)) {
    return CURSOR_TYPE_INSIDE_RECT.TOP_RIGHT;
  }
  // corner C
  if (checkIfInsideCircle(mouseX, mouseY, rect.x + rect.w, rect.y + rect.h)) {
    return CURSOR_TYPE_INSIDE_RECT.BOTTOM_RIGHT;
  }
  // corner D
  if (checkIfInsideCircle(mouseX, mouseY, rect.x, rect.y + rect.h)) {
    return CURSOR_TYPE_INSIDE_RECT.BOTTOM_LEFT;
  }
  // corner AB
  if (checkIfInsideCircle(mouseX, mouseY, rect.x + middleW, rect.y)) {
    return CURSOR_TYPE_INSIDE_RECT.TOP_MIDDLE;
  }
  // corner CD
  if (checkIfInsideCircle(mouseX, mouseY, rect.x + middleW, rect.y + rect.h)) {
    return CURSOR_TYPE_INSIDE_RECT.BOTTOM_MIDDLE;
  }
  // corner BC
  if (checkIfInsideCircle(mouseX, mouseY, rect.x + rect.w, rect.y + middleH)) {
    return CURSOR_TYPE_INSIDE_RECT.RIGHT_MIDDLE;
  }
  // corner AD
  if (checkIfInsideCircle(mouseX, mouseY, rect.x, rect.y + middleH)) {
    return CURSOR_TYPE_INSIDE_RECT.LEFT_MIDDLE;
  }
  // inside the rectangle
  if (
    mouseX >= rect.x && // Check if x is greater than or equal to the left boundary
    mouseX <= rect.x + rect.w && // Check if x is less than or equal to the right boundary
    mouseY >= rect.y && // Check if y is greater than or equal to the top boundary
    mouseY <= rect.y + rect.h // Check if y is less than or equal to the bottom boundary
  ) {
    return CURSOR_TYPE_INSIDE_RECT.INSIDE; // Mouse click is inside the rectangle
  }
  return null;
};
export const resizeOrMoveRect = (movement, dx, dy, rect) => {
  let newX = rect.x;
  let newY = rect.y;
  let newW = rect.w;
  let newH = rect.h;
  switch (movement) {
    case CURSOR_TYPE_INSIDE_RECT.INSIDE.move:
      newX += dx;
      newY += dy;
      break;
    case CURSOR_TYPE_INSIDE_RECT.TOP_LEFT.move:
      newX += dx;
      newY += dy;
      newW -= dx;
      newH -= dy;
      break;
    case CURSOR_TYPE_INSIDE_RECT.TOP_RIGHT.move:
      newY += dy;
      newW += dx;
      newH -= dy;
      break;
    case CURSOR_TYPE_INSIDE_RECT.BOTTOM_RIGHT.move:
      newW += dx;
      newH += dy;
      break;
    case CURSOR_TYPE_INSIDE_RECT.BOTTOM_LEFT.move:
      newX += dx;
      newW -= dx;
      newH += dy;
      break;
    case CURSOR_TYPE_INSIDE_RECT.TOP_MIDDLE.move:
      newY += dy;
      newH -= dy;
      break;
    case CURSOR_TYPE_INSIDE_RECT.RIGHT_MIDDLE.move:
      newW += dx;
      break;
    case CURSOR_TYPE_INSIDE_RECT.BOTTOM_MIDDLE.move:
      newH += dy;
      break;
    case CURSOR_TYPE_INSIDE_RECT.LEFT_MIDDLE.move:
      newX += dx;
      newW -= dx;
      break;
    default:
      break;
  }
  return [
    { x: newX, y: newY },
    { x: newX + newW, y: newY + newH },
  ];
};
