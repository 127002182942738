import {
  DRAW_OBJECT_TYPES,
  MAX_FONT_SIZE,
  MIN_FONT_SIZE,
  MOVEMENT_TYPES,
  RESIZE_RECT_LINE_SIZE,
  TEXT_CORNER_CIRCLE_RADIUS,
  TEXT_RECT_STROKE_COLOR,
} from "constants/studio";
import { toVirtualX, toVirtualY } from "utils/studio/utils";
import ShapeObject from "./ShapeObject";
import { formatRgbaColor } from "components/molecules/StudioCanvas/ContentCanvas";

class TextObject extends ShapeObject {
  data = null;

  constructor({ data, meta, x, y, width, height, canvas = null }) {
    super({ type: DRAW_OBJECT_TYPES.text, meta, canvas, x, y, width, height });
    this.data = data;
  }

  applyFont(scale) {
    const ctx = this.getContext();
    const {
      fontSize = 14,
      style = "normal",
      fontFamily = "sans-serif",
      fontWeight = 400,
    } = this.meta;

    const formatFontSize = `${parseInt(fontSize) * scale}px`;
    const formatFontWeight = `${fontWeight === 700 ? "bold" : ""}`;
    ctx.font = `${formatFontWeight} ${style} ${formatFontSize} ${fontFamily}`;
  }

  draw({ scale = 1, offsetX = 0, offsetY = 0, ctx = null, originX, originY }) {
    const mainCtx = ctx || this.getContext();

    const {
      color = { r: 255, b: 255, g: 255, a: 1 },
      textDecoration = "none",
      background = { r: 0, b: 0, g: 0, a: 0 },
    } = this.meta;

    if (!mainCtx) return;

    this.applyFont(scale);

    mainCtx.textBaseline = "top";

    const textX = toVirtualX(originX ?? this.x, offsetX, scale);
    const textY = toVirtualY(originY ?? this.y, offsetY, scale);

    this.updateSize(scale);

    mainCtx.fillStyle = formatRgbaColor(background);
    mainCtx.fillRect(textX, textY, this.width, this.height);

    if (textDecoration === "line-through") {
      mainCtx.strokeStyle = formatRgbaColor(color);
      mainCtx.lineWidth = 2;
      mainCtx.beginPath();
      const lineY = textY + this.height / 2;
      mainCtx.moveTo(textX, lineY);
      mainCtx.lineTo(textX + this.width, lineY);
      mainCtx.stroke();
    }

    if (textDecoration === "underline") {
      mainCtx.strokeStyle = formatRgbaColor(color);
      mainCtx.lineWidth = 2;
      mainCtx.beginPath();
      const underlineY = textY + this.height;
      mainCtx.moveTo(textX, underlineY);
      mainCtx.lineTo(textX + this.width, underlineY);
      mainCtx.stroke();
    }

    mainCtx.fillStyle = formatRgbaColor(color);
    mainCtx.fillText(this.data, textX, textY);
  }

  getResizePoints({ x, y, width, height }) {
    const coordinates = {
      [MOVEMENT_TYPES.resize_A]: { x, y },
      [MOVEMENT_TYPES.resize_B]: { x: x + width, y },
      [MOVEMENT_TYPES.resize_C]: { x: x + width, y: y + height },
      [MOVEMENT_TYPES.resize_D]: { x, y: y + height },
    };

    return coordinates;
  }

  updateSize(scale) {
    const calculatedSize = TextObject.calculateSize({
      ...this.meta,
      text: this.data,
      scale,
    });

    if (calculatedSize.width && this.data) {
      this.width = calculatedSize.width;
    }

    this.height = calculatedSize.height;
  }

  drawResizeRect({ offsetX, offsetY, scale }) {
    const canvas = this.controlsCanvas;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx) return;

    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const x = toVirtualX(this.x, offsetX, scale);
    const y = toVirtualY(this.y, offsetY, scale);

    const width = this.width;
    const height = this.height;

    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.strokeStyle = TEXT_RECT_STROKE_COLOR;
    ctx.lineWidth = 2;
    ctx.stroke();

    const resizePoints = this.getResizePoints({
      x,
      y,
      width,
      height,
    });

    ctx.lineWidth = RESIZE_RECT_LINE_SIZE;
    ctx.stroke();

    ctx.fillStyle = TEXT_RECT_STROKE_COLOR;

    for (let coords of Object.values(resizePoints)) {
      ctx.beginPath();
      ctx.arc(coords.x, coords.y, TEXT_CORNER_CIRCLE_RADIUS, 0, 2 * Math.PI);
      ctx.fill();
    }

    ctx.restore();
  }

  resizeItem(movementType, { dx, dy }) {
    let newX = this.x || 0;
    let newY = this.y || 0;
    let newW = this.width || 0;
    let newH = this.height || 0;
    let fontSize = this.meta.fontSize;

    switch (movementType) {
      case MOVEMENT_TYPES.move:
        newX += dx;
        newY += dy;
        break;
      case MOVEMENT_TYPES.resize_A:
        newW -= dx;
        newH -= dy;
        break;
      case MOVEMENT_TYPES.resize_B:
        newW += dx;
        newH -= dy;
        break;
      case MOVEMENT_TYPES.resize_C:
        newW += dx;
        newH += dy;
        break;
      case MOVEMENT_TYPES.resize_D:
        newW -= dx;
        newH += dy;
        break;

      default:
        break;
    }

    const fontScaleFactor = (newW / this.width + newH / this.height) / 2;

    this.x = newX;
    this.y = newY;

    const newFontSize = fontSize * fontScaleFactor;
    let formatFontSize = newFontSize;

    if (newFontSize <= MIN_FONT_SIZE) {
      formatFontSize = MIN_FONT_SIZE;
    } else if (newFontSize >= MAX_FONT_SIZE) {
      formatFontSize = MAX_FONT_SIZE;
    }

    this.width = newW;
    this.height = newH;

    this.meta = {
      ...this.meta,
      fontSize: formatFontSize,
    };
  }

  static calculateSize({
    fontSize = 14,
    style = "normal",
    fontFamily = "sans-serif",
    fontWeight = 400,
    scale = 1,
    text = "",
    padding = 0,
    lineHeight = 1.2,
  }) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const formatFontSize = `${parseInt(fontSize) * scale}px`;
    const formatFontWeight = `${fontWeight === 700 ? "bold" : ""}`;

    ctx.font = `${formatFontWeight} ${style} ${formatFontSize} ${fontFamily}`;

    const metrics = ctx.measureText(text);

    const width = metrics.width;
    const height =
      metrics.fontBoundingBoxAscent +
      metrics.fontBoundingBoxDescent +
      padding * 2 +
      (lineHeight - 1) * fontSize;

    return {
      width,
      height,
    };
  }

  setData(data) {
    this.data = data;
  }
}

export default TextObject;
