export async function fileToDataUrl(file) {
  return new Promise((resolve,) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
}

export async function imageToDataUrl(url, width, height,) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = width || img.width;
      canvas.height = height || img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL();
      resolve(dataUrl);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
}

export function formatDataUrl(dataUrl) {
  return dataUrl.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
}
