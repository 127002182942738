import { useNavigate, useParams } from "react-router-dom";
import { CButton } from "components/atoms/CButton";
import { useContext, useState } from "react";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import "./styles.scss"
import logo from 'assets/imgs/logo.svg';
import {ReactComponent as Lock} from 'assets/imgs/auth/key.svg';
import {ReactComponent as Eye} from 'assets/imgs/auth/eye.svg';
import {ReactComponent as EyeClose} from 'assets/imgs/auth/eye_close.svg';
import SocialLinks from "./SocialLinks";


export function ResetPassword() {
    const { token } = useParams();
    const navigate = useNavigate();
    const { setLoading, showNoti } = useContext(LayoutContext)
    const [password, setPassword] = useState("");
    const [cpassword, setCPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const str_password = event.target.password.value;
        const str_cpassword = event.target.cpassword.value;
        if(str_cpassword !== str_password) {
            showNoti('error', 'Confirm password does not match your password.')
            return false;
        }
        setLoading(true)
        try {
            await getApi('/password-reset', 'POST', { token, password })
            showNoti('success', 'Your password has been reset')
            setTimeout(() => {
                navigate('/login')
            }, 3000)
        } catch (err) {
            showNoti('error', err)
        }
        setLoading(false)
    }

    return (
        <div id="#login" className="auth-area">
            <div className="login">
                <div className='logo mt-15'>
                    <img src={logo} alt='logo' />
                </div>
                <div className="active-area">
                    <form onSubmit={handleSubmit}>
                        <div className="login-card mt-35">
                            <div className="form-group mt-25">
                                <div className="input-with-icon">
                                    <Lock className="icon-img" />
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}
                                        required
                                    />
                                    {!showPassword ? <Eye className="last-icon-img" onClick={()=>setShowPassword(!showPassword)} />
                                     : <EyeClose className="last-icon-img" onClick={()=>setShowPassword(!showPassword)} />}
                                </div>
                            </div>
                            <div className="form-group mt-25">
                                <div className="input-with-icon">
                                    <Lock className="icon-img" />
                                    <input
                                        type={showCPassword ? 'text' : 'password'}
                                        id="cpassword"
                                        placeholder="Confirm Password"
                                        value={cpassword}
                                        onChange={(e)=>setCPassword(e.target.value)}
                                        required
                                    />
                                    {!showCPassword ? <Eye className="last-icon-img" onClick={()=>setShowCPassword(!showCPassword)} />
                                     : <EyeClose className="last-icon-img" onClick={()=>setShowCPassword(!showCPassword)} />}
                                </div>
                            </div>
                            <div className="mt-20 mb-60">
                                <CButton addClass='primary text-size-20 py-10 full large' mainClass="w-full" type="submit">Reset Password</CButton>
                            </div>
                        </div>
                    </form>
                </div>
                <SocialLinks />
            </div>
        </div>
    )
}