import { LICENSE } from "utils/constants";

// export const LICENSE_OPTIONS = [
//   {
//     key: 1,
//     id: LICENSE.FREE_TRIAL,
//     icon: null,
//     color: "white",
//     checked: true,
//     label: "Free Trial",
//   },
//   {
//     key: 2,
//     id: LICENSE.STARTER,
//     icon: null,
//     color: "white",
//     checked: false,
//     label: "Starter",
//   },
//   {
//     key: 3,
//     id: LICENSE.BUSINESS,
//     icon: null,
//     color: "white",
//     checked: false,
//     label: "Business",
//   },
//   {
//     key: 4,
//     id: LICENSE.PROFESSIONAL,
//     icon: null,
//     color: "white",
//     checked: false,
//     label: "Professional",
//   },
//   {
//     key: 5,
//     id: LICENSE.ANIMATION,
//     icon: null,
//     color: "white",
//     checked: false,
//     label: "Animation",
//   },
//   {
//     key: 6,
//     id: LICENSE.AGENCY,
//     icon: null,
//     color: "white",
//     checked: false,
//     label: "Agency",
//   },
// ];

export const LICENSE_OPTIONS = [
  {
    value: LICENSE.FREE_TRIAL,
    label: "Free Trial",
  },
  {
    value: LICENSE.STARTER,
    label: "Starter",
  },
  {
    value: LICENSE.BUSINESS,
    label: "Business",
  },
  {
    value: LICENSE.PROFESSIONAL,
    label: "Professional",
  },
  {
    value: LICENSE.ANIMATION,
    label: "Animation",
  },
  {
    value: LICENSE.AGENCY,
    label: "Agency",
  },
];

export const SOURCE_OPTIONS = [
  {
    key: 1,
    id: 'paddle',
    icon: null,
    color: "white",
    checked: true,
    label: "Paddle",
  },
  {
    key: 2,
    id: 'jvzoo',
    icon: null,
    color: "white",
    checked: false,
    label: "JVZoo",
  },
];

export const CUSTOM_STYLES = {
  control: (provided) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#3d3d3d',
    borderRadius: '10px',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: '#3d3d3d',
    '&:hover': {
      backgroundColor: '#7371fc',
      cursor: 'pointer',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#3d3d3d',
    color: 'white',
    borderRadius: '5px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    '&:hover': {
      backgroundColor: '#3d3d3d',
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    backgroundColor: '#3d3d3d',
    color: 'white',
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
};
