import './index.scss'

const InfoCard = ({ Icon, value, title }) => {
    return <div className="mini-card">
        <p>{title}</p>
        <div className="sub">
            <Icon />
            {value}
        </div>
    </div>
}

export default InfoCard