import {
  DRAW_OBJECT_TYPES,
  LOCK_BUTTON,
  MAIN_OBJECT_FILL_RECT,
  MAIN_OBJECT_SLIDER,
  MAIN_OBJECT_STROKE_COLOR,
  MOVEMENT_TYPES,
} from "constants/studio";
import RectObject from "./RectObject";

class MainRectObject extends RectObject {
  lockButton = null;
  imagesSlider = null;
  fillRect = null;

  constructor({ lockButton, imagesSlider, fillRect, ...info }) {
    super({ type: DRAW_OBJECT_TYPES.mainRect, ...info });
    this.lockButton = lockButton;
    this.imagesSlider = imagesSlider;
    this.fillRect = fillRect;
  }

  setLockButton(lockButton) {
    this.lockButton = lockButton;
  }

  setImagesSlider(imagesSlider) {
    this.imagesSlider = imagesSlider;
  }

  setFillRect(fillRect) {
    this.fillRect = fillRect;
  }

  getImagesSlider() {
    return this.imagesSlider;
  }

  hideMainSlider() {
    let imagesSlider = this.imagesSlider;

    if (!imagesSlider) {
      imagesSlider = document.getElementById(MAIN_OBJECT_SLIDER);
    }
    if (imagesSlider) {
      imagesSlider.style.display = "none";
    }
  }

  showMainSlider({ offsetX, offsetY, scale }) {
    let imagesSlider = this.imagesSlider;

    if (!imagesSlider) {
      imagesSlider = document.getElementById(MAIN_OBJECT_SLIDER);
    }

    const rectCoordinates = this.getCenterCoordinates({
      offsetX,
      offsetY,
      scale,
    });

    if (imagesSlider) {
      imagesSlider.style.display = "block";
      imagesSlider.style.top = rectCoordinates.y + "px";
      imagesSlider.style.left = rectCoordinates.x + "px";
      imagesSlider.style.width = rectCoordinates.width + "px";
      imagesSlider.style.height = rectCoordinates.height + "px";
    }
  }

  updateElements() {
    const imagesSlider = document.getElementById(MAIN_OBJECT_SLIDER);
    this.setImagesSlider(imagesSlider);

    const lockButton = document.getElementById(LOCK_BUTTON);
    this.setLockButton(lockButton);

    const fillRect = document.getElementById(MAIN_OBJECT_FILL_RECT);
    this.setFillRect(fillRect);
  }

  draw({ scale, offsetX, offsetY }) {
    const ctx = this.getContext();

    if (!ctx) return;
    ctx.save();

    const rectCoordinates = this.getCenterCoordinates({
      offsetX,
      offsetY,
      scale,
    });

    ctx.strokeStyle = MAIN_OBJECT_STROKE_COLOR;
    ctx.lineWidth = 1;

    ctx.setTransform(...rectCoordinates.matrix);

    ctx.beginPath();

    ctx.rect(
      rectCoordinates.originX,
      rectCoordinates.originY,
      rectCoordinates.width,
      rectCoordinates.height
    );

    ctx.stroke();

    ctx.restore();

    let lockButton = this.lockButton;

    if (!lockButton) {
      lockButton = document.getElementById(LOCK_BUTTON);
      this.setLockButton(lockButton);
    }
    if (lockButton) {
      lockButton.style.display = "block";
      lockButton.style.top = rectCoordinates.y + 10 + "px";
      lockButton.style.left = rectCoordinates.x + 10 + "px";
    }

    let imagesSlider = this.imagesSlider;

    if (!imagesSlider) {
      imagesSlider = document.getElementById(MAIN_OBJECT_SLIDER);
      this.setImagesSlider(imagesSlider);
    }

    if (imagesSlider) {
      imagesSlider.style.top = rectCoordinates.y + "px";
      imagesSlider.style.left = rectCoordinates.x + "px";
      imagesSlider.style.height = rectCoordinates.height + "px";
      imagesSlider.style.width = rectCoordinates.height + "px";
    }

    let fillRect = this.fillRect;

    if (!fillRect) {
      fillRect = document.getElementById(MAIN_OBJECT_FILL_RECT);
      this.setFillRect(fillRect);
    }

    if (fillRect) {
      fillRect.style.height = rectCoordinates.height + "px";
      fillRect.style.width = rectCoordinates.height + "px";
      fillRect.style.top = rectCoordinates.y + "px";
      fillRect.style.left = rectCoordinates.x + "px";
    }
  }

  checkMovement() {
    return MOVEMENT_TYPES.move;
  }

  resizeItem(movementType, { dx, dy }) {
    if (movementType !== MOVEMENT_TYPES.move) return;

    let newX = this.x || 0;
    let newY = this.y || 0;

    switch (movementType) {
      case MOVEMENT_TYPES.move:
        newX += dx;
        newY += dy;
        break;

      default:
        break;
    }

    this.x = newX;
    this.y = newY;
  }
}

export default MainRectObject;
