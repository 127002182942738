import useStudioCreator from "hooks/studio/useStudioCreator";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCanvasRef } from "store/slices/studio";
import { getGeneratedImages, getMainObjectLocked } from "store/utils";
import { ReactComponent as UnlockSvg } from "assets/imgs/studioCreator/unlock.svg";
import { ReactComponent as LockSvg } from "assets/imgs/studioCreator/lock.svg";
import { LOCK_BUTTON, MAIN_CANVAS } from "constants/studio";
import GeneratedImagesSlider from "../GeneratedImagesSlider";
import useBackground from "hooks/studio/useBackground";

const MainCanvas = () => {
  const canvasRef = useRef(null);
  const mainObjectLocked = useSelector(getMainObjectLocked);
  const generatedImages = useSelector(getGeneratedImages);

  const dispatch = useDispatch();
  const { onLockClick } = useStudioCreator();
  const { onCancelChooseImage, onAcceptChooseImage } = useBackground();

  useEffect(() => {
    if (!canvasRef.current) return;

    dispatch(setCanvasRef(canvasRef));
  }, [canvasRef, dispatch]);

  return (
    <>
      <canvas
        className="studio-canvas__canvas studio-canvas__canvas_overlay"
        ref={canvasRef}
        style={{ zIndex: 2 }}
        id={MAIN_CANVAS}
      />
      <button
        className="studio-canvas__lock-btn"
        id={LOCK_BUTTON}
        onClick={onLockClick}
      >
        {mainObjectLocked ? <LockSvg /> : <UnlockSvg />}
      </button>
      <GeneratedImagesSlider
        images={generatedImages}
        onCancel={onCancelChooseImage}
        onAccept={onAcceptChooseImage}
      />
    </>
  );
};

export default MainCanvas;
