import { useState, useMemo } from "react";

import EnhancedModel from "../EnhancedModel";
import VideoBlock from "../VideoBlock";
// import UpgradeToPro from "../UpgradeToPro";

import "./index.scss";

const ProPlan = ({ user }) => {
  const [isVideoBlockOpen, setIsVideoBlockOpen] = useState(true);
  // const [isUpgradeBlockOpen, setIsUpgradeBlockOpen] = useState(true);
  const [isEnhancedModelOpen, setIsEnhancedModelOpen] = useState(true);
  const name = useMemo(() => user?.user?.first_name, [user]);

  return (
    <div className="pro-plan">
      {/* {isUpgradeBlockOpen && (
        <UpgradeToPro
          onClose={() => setIsUpgradeBlockOpen(false)}
          name={name}
        />
      )} */}
      {isEnhancedModelOpen && (
        <EnhancedModel onClose={() => setIsEnhancedModelOpen(false)} />
      )}
      {isVideoBlockOpen && (
        <VideoBlock name={name} onClose={() => setIsVideoBlockOpen(false)} />
      )}
    </div>
  );
};

export default ProPlan;
