import React from "react";
import "./styles.scss";
import ResizableImageCanvas from "./resizeImage";

export const mainCanvasW = 768;
export const mainCanvasH = 768;

export function ImageEditor({
  imageUrl = null,
  imgW = 512,
  imgH = 512,
  onUpdateResizeImage = () => {},
}) {
  const getRect = () => {
    let newW = imgW;
    let newH = imgH;
    if (newW > mainCanvasW) {
      newW = 768;
      newH = (768 * imgH) / imgW;
    } else if (newH > mainCanvasH) {
      newH = 768;
      newW = (imgW * 768) / imgH;
    }
    let newX = (mainCanvasW - newW) / 2;
    let newY = (mainCanvasH - newH) / 2;
    return { x: newX, y: newY, w: newW, h: newH };
  };

  return (
    <div className="image-editor">
      <div className="main-canvas">
        {imageUrl && (
          <ResizableImageCanvas
            onUpdateImage={onUpdateResizeImage}
            imageUrl={imageUrl}
            canvasWidth={mainCanvasW} // Set canvas width here
            canvasHeight={mainCanvasH} // Set canvas height here
            rectOrg={getRect()}
          />
        )}
      </div>
    </div>
  );
}
