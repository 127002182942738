function isCanvasBlank(canvas, maskType) {
  const blank = document.createElement("canvas");
  const blankCtx = blank.getContext("2d");

  blank.width = canvas.width;
  blank.height = canvas.height;

  if (maskType) {
    blankCtx.fillStyle = maskType === "white" ? "black" : "white";
    blankCtx.fillRect(0, 0, blank.width, blank.height);
  }

  return canvas.toDataURL() === blank.toDataURL();
}

export default isCanvasBlank;
