import { getMousePos } from "components/atoms/ImageEditor/canvasUtil";
import {
  CURSOR_CANVAS,
  DEFAULT_ZOOM,
  DRAW_OBJECT_TYPES,
} from "constants/studio";
import _ from "lodash";
import { sortLayers, sortLayersReverse } from "utils/studio/sortLayers";

class LayerObject {
  type = null;
  position = 0;
  canvas = null;
  meta = {};
  rotation = 0;
  scaleX = 1;
  scaleY = 1;
  parentItem = null;
  version = 0;

  constructor({ type, position, canvas, meta = {} }) {
    this.type = type;
    this.position = position;
    this.meta = meta;

    if (canvas) {
      this.canvas = canvas;
    } else {
      this.addNewCanvas();
    }
  }

  getContext() {
    return this.canvas?.getContext("2d");
  }
  getCanvas() {
    return this.canvas;
  }
  setPosition(position) {
    this.position = position;
  }
  addNewCanvas() {
    const newCanvas = this.createCanvas();
    const wrapper = document.getElementById("studio-canvas");
    const brushCanvas = document.getElementById(CURSOR_CANVAS);
    if (wrapper) {
      wrapper.insertBefore(newCanvas, brushCanvas);
    }
    this.canvas = newCanvas;
  }
  createCanvas() {
    const newCanvas = document.createElement("canvas");
    newCanvas.classList.add("studio-canvas__canvas");
    newCanvas.classList.add("studio-canvas__canvas_overlay");

    if (
      this.type === DRAW_OBJECT_TYPES.image ||
      this.type === DRAW_OBJECT_TYPES.group
    ) {
      newCanvas.classList.add("imageCanvas");
    }

    return newCanvas;
  }
  createTmpCanvas() {
    const newCanvas = document.createElement("canvas");
    const canvas = this.getCanvas();
    newCanvas.width = canvas.width;
    newCanvas.height = canvas.height;

    return newCanvas;
  }
  clearContext() {
    const canvas = this.getCanvas();
    const context = this.getContext();
    if (!canvas || !context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
  }

  // static methods
  static drawLayers(
    layers,
    { scale = DEFAULT_ZOOM, offsetX = 0, offsetY = 0, ...rest }
  ) {
    if (!layers?.length) return;
    const sortedLayers = sortLayers(layers, true);

    sortedLayers.forEach((layer) => {
      layer?.clearContext && layer.clearContext();
    });

    for (let layer of sortedLayers) {
      if (!layer) {
        continue;
      }

      layer?.draw && layer.draw({ scale, offsetX, offsetY, ...rest });
    }
  }

  static getAllSelectedItems(
    layers,
    { clientX, clientY, canvas, offsetX, offsetY, scale, mainObjectLocked }
  ) {
    const { x, y } = getMousePos(canvas, { clientX, clientY });
    const sortedLayers = sortLayersReverse(layers, true);

    let selectedObjects = [];

    for (let layer of sortedLayers) {
      if (!layer) continue;

      if (
        layer.type === DRAW_OBJECT_TYPES.eraser ||
        layer.type === DRAW_OBJECT_TYPES.brush
      )
        continue;

      if (layer.type === DRAW_OBJECT_TYPES.group) {
        const selectedFromGroup = LayerObject.getAllSelectedItems(
          layer.objects,
          {
            clientX,
            clientY,
            canvas,
            offsetX,
            offsetY,
            scale,
            mainObjectLocked,
          }
        );

        selectedObjects = selectedObjects.concat(selectedFromGroup);
      } else {
        const isSelected = layer.checkIsSelected({
          x,
          y,
          offsetX,
          offsetY,
          scale,
        });

        if (
          isSelected &&
          !(layer?.type === DRAW_OBJECT_TYPES.mainRect && mainObjectLocked)
        ) {
          selectedObjects.push(layer.parentItem ? layer.parentItem : layer);
        }
      }
    }

    return selectedObjects;
  }

  static getSelectedItem(
    layers,
    { clientX, clientY, canvasRef, offsetX, offsetY, scale, mainObjectLocked }
  ) {
    const canvas = canvasRef.current;
    const { x, y } = getMousePos(canvas, { clientX, clientY });
    const sortedLayers = sortLayersReverse(layers, true);
    let selectedObject = null;

    if (!layers.length) {
      return null;
    }

    for (let layer of sortedLayers) {
      if (!layer) continue;

      if (
        layer?.type === DRAW_OBJECT_TYPES.brush ||
        layer?.type === DRAW_OBJECT_TYPES.eraser
      )
        continue;

      if (layer?.type === DRAW_OBJECT_TYPES.group) {
        selectedObject = LayerObject.getSelectedItem(layer.getObjects(), {
          clientX,
          clientY,
          canvasRef,
          offsetX,
          offsetY,
          scale,
          mainObjectLocked,
        });

        if (selectedObject) {
          break;
        }
      } else {
        const isSelected = layer.checkIsSelected({
          x,
          y,
          offsetX,
          offsetY,
          scale,
        });

        if (
          isSelected &&
          !(layer?.type === DRAW_OBJECT_TYPES.mainRect && mainObjectLocked)
        ) {
          selectedObject = layer.parentItem ? layer.parentItem : layer;
          break;
        }
      }
    }

    return selectedObject;
  }
  setParentItem(item) {
    this.parentItem = item;
  }
  setMeta(meta) {
    this.meta = meta;
  }
  setCanvas(canvas) {
    this.canvas = canvas;
  }
  setScaleX(scaleX) {
    this.scaleX = scaleX;
  }
  setScaleY(scaleY) {
    this.scaleY = scaleY;
  }
  addScaleX(scaleX) {
    this.scaleX *= scaleX;
  }
  addScaleY(scaleY) {
    this.scaleY *= scaleY;
  }
  // to be implemented in subclasses
  draw({ scale = DEFAULT_ZOOM, offsetX = 0, offsetY = 0 }) { }

  clone() {
    const object = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      _.cloneDeep(this)
    );

    object.version += 1;

    return object;
  }
}

export default LayerObject;
