import { DRAW_OBJECT_TYPES } from "constants/studio";
import ShapeObject from "./ShapeObject";
import { toVirtualX } from "utils/studio/utils";

class ImageObject extends ShapeObject {
  img = null;
  data = {};
  imageType = null;

  constructor({
    img,
    position,
    x,
    y,
    width,
    height,
    rotation,
    parentItem,
    canvas,
    meta,
    data,
    imageType,
  }) {
    super({
      type: DRAW_OBJECT_TYPES.image,
      x,
      y,
      width,
      height,
      position,
      rotation,
      canvas,
      meta,
    });

    this.img = img;
    this.parentItem = parentItem;
    this.data = data;
    this.imageType = imageType;
  }

  onDrawImage({ ctx, originX, originY, scale = 1, offsetX = 0, offsetY = 0, width = 0, height = 0 }) {
    const mainCtx = ctx || this.getContext();
    if (!mainCtx) return;

    const angle = this.rotation;

    const x = toVirtualX(originX ?? this.x, offsetX, scale);
    const y = toVirtualX(originY ?? this.y, offsetY, scale);

    const formatWidth = width * scale;
    const formatHeigth = height * scale;
    const newWidth = formatWidth * this.scaleX;
    const newHeight = formatHeigth * this.scaleY;
    const centerX = x + newWidth / 2;
    const centerY = y + newHeight / 2;

    mainCtx.save();

    mainCtx.translate(centerX, centerY);
    mainCtx.rotate(angle);
    mainCtx.translate(-centerX, -centerY);

    mainCtx.drawImage(this.img, x, y, newWidth, newHeight);

    mainCtx.restore();
  }

  draw(props) {
    this.onDrawImage({ ...props, width: this.width, height: this.height })
  }

  drawOriginal(props) {
    this.onDrawImage({ ...props, width: this.originalWidth, height: this.originalHeight })
  }

  setData(data) {
    this.data = data;
  }

  setImageType(imageType) {
    this.imageType = imageType;
  }

  setImg(img) {
    this.img = img;
  }
}

export default ImageObject;
