import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import { ReactComponent as Eye } from "assets/imgs/auth/eye.svg";
import { ReactComponent as EyeClose } from "assets/imgs/auth/eye_close.svg";
import { useEffect, useState } from "react";
import { CButton } from "components/atoms/CButton";
import { getApi } from "utils/customNetwork";
import { CDropdown } from "../../CDropdown";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useContext } from "react";
import Select from 'react-select';

import "./index.scss";
import { LICENSE_OPTIONS, SOURCE_OPTIONS, CUSTOM_STYLES } from "./constants";

export function UserModal({
  show = false,
  user = {},
  onClose = () => { },
  onHandleUpdate = () => { },
}) {
  const [isUserEffect, setIsUserEffect] = useState(false);
  const { showNoti, checkToken } = useContext(LayoutContext);
  const [firstName, setFirstName] = useState(user.first_name || "");
  const [lastName, setLastName] = useState(user.last_name || "");
  const [email, setEmail] = useState(user.email || "");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [moreCredits, setMoreCredits] = useState(0);
  const [roleOptions, setRoleOptions] = useState([
    {
      key: 1,
      id: "user",
      icon: null,
      color: "white",
      checked: true,
      label: "User",
    },
    {
      key: 2,
      id: "admin",
      icon: null,
      color: "white",
      checked: false,
      label: "Admin",
    },
  ]);
  // const [licenseOptions, setLicenseOptions] = useState(LICENSE_OPTIONS);
  const [selectedlicenseOptions, setSelectedlicenseOptions] = useState([LICENSE_OPTIONS[0]]);
  const [sourceOptions, setSourceOptions] = useState(SOURCE_OPTIONS);
  const [stateOptions, setStateOptions] = useState([
    {
      key: 1,
      id: "active",
      icon: null,
      color: "white",
      checked: true,
      label: "Active",
    },
    {
      key: 2,
      id: "inactive",
      icon: null,
      color: "white",
      checked: false,
      label: "Inactive",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // const [message, setMessage] = useState("");

  const addMoreCredits = async (event) => {
    event.preventDefault();
    let param = { user_id: user.id, credits: moreCredits };
    let apiUrl = "/admin/addCredits";
    setLoading(true);
    try {
      checkToken();
      await getApi(apiUrl, "POST", param);
      showNoti("success", "Successfully Updated");
      // setMessage("Successfully Updated");
      onHandleUpdate();
      onClose();
      // setTimeout(() => {
      //   setMessage("");
      //   onClose();
      // }, 3000);
    } catch (err) {
      showNoti("error", err);
      setError(err);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
    setLoading(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedlicenseOptions.length === 0) {
      showNoti('error', "Please select at least one license");
      return;
    }

    const password = event.target.password.value;
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
      const first_name = event.target.first_name.value;
      const last_name = event.target.last_name.value;
      const email = event.target.email.value;
      const password = event.target.password.value;
      let param = {
        first_name,
        last_name,
        email,
        role: getActiveMenu(roleOptions).id,
        source: getActiveMenu(sourceOptions).id,
        license: selectedlicenseOptions.map(option => option.value)
        // license: getActiveMenu(licenseOptions).id,
      };
      let apiUrl = "/admin/updateUser";

      if (user.id) {
        // edit
        param.user_id = user.id;
        if (password !== "") param.new_password = password;
        param.status = getActiveMenu(stateOptions).id;
      } else {
        // create
        apiUrl = "/admin/createUser";
        param.password = password;
      }
      setLoading(true);
      try {
        checkToken();
        await getApi(apiUrl, "POST", param);
        if (user.id) {
          showNoti("success", "Successfully Updated");
          // setMessage("Successfully Updated");
        }
        else {
          showNoti("success", "Successfully Created");
          // setMessage("Successfully Created");
        }
        onHandleUpdate();
        onClose();
        // setTimeout(() => {
        //   setMessage("");
        //   onClose();
        // }, 3000);
      } catch (err) {
        showNoti("error", err);
        setError(err);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
      setLoading(false);
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };
  const handleOptRatio = (id, index, value) => {
    switch (id) {
      case "role_option":
        const customOptions = [...roleOptions];
        customOptions.forEach((el) => (el.checked = false));
        customOptions[index].checked = value;
        setRoleOptions(customOptions);
        break;
      case "state_option":
        const customOptions1 = [...stateOptions];
        customOptions1.forEach((el) => (el.checked = false));
        customOptions1[index].checked = value;
        setStateOptions(customOptions1);
        break;
      // case "license_option":
      //   const customOptions2 = [...licenseOptions];
      //   customOptions2.forEach((el) => (el.checked = false));

      //   if (customOptions2[index]) {
      //     customOptions2[index].checked = value;
      //     setLicenseOptions(customOptions2);
      //   }
      //   break;
      case "source_option":
        const customOptions3 = [...sourceOptions];
        customOptions3.forEach((el) => (el.checked = false));
        customOptions3[index].checked = value;
        setSourceOptions(customOptions3);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (!isUserEffect) {
      if (user.role && user.role === "admin") {
        handleOptRatio("role_option", 1, true);
      }
      if (user.status && user.status === "inactive") {
        handleOptRatio("state_option", 1, true);
      }
      if (user.license) {
        const initialSelectedOptions = mapLicenseValuesToOptions(user.license);
        setSelectedlicenseOptions(initialSelectedOptions);
        // const findIndex = LICENSE_OPTIONS.findIndex((el) => el.value === user.license);
        // handleOptRatio("license_option", findIndex, true);
      }
      if (user.source) {
        const findIndex = sourceOptions.findIndex((el) => el.id === user.source);
        handleOptRatio("source_option", findIndex, true);
      }
      setIsUserEffect(true);
    }
  }, [user, handleOptRatio, LICENSE_OPTIONS, sourceOptions]);

  const mapLicenseValuesToOptions = (values) => {
    return values.map(value => {
      const option = LICENSE_OPTIONS.find(option => option.value === value);
      return option;
    }).filter(Boolean); // Filter out undefined values
  };

  const handleLicenseChange = (selectedlicense) => {
    setSelectedlicenseOptions(selectedlicense);
  };

  return (
    <Modal
      isOpen={show}
      ariaHideApp={false}
      onClick={onClose}
      className="user-modal"
    >
      <IconClose className="cursor-pointer ico-close" onClick={onClose} />
      <div className="user-area">
        <h3 className="text-center">{user.id ? "Edit" : "Create"} User</h3>
        <form onSubmit={handleSubmit}>
          <div className="login-card">
            <div className="outer-div">
              <div className="form-group mt-15 flex1">
                <p>First Name:</p>
                <div className="input-with-icon">
                  <input
                    type="text"
                    id="first_name"
                    placeholder="Firstname"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-15 flex1">
                <p>Last Name:</p>
                <div className="input-with-icon">
                  <input
                    type="text"
                    id="last_name"
                    placeholder="Lastname"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="outer-div">
              <div className="form-group mt-15 flex1">
                <p>Email:</p>
                <div className="input-with-icon">
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-15 flex1">
                <p>Role:</p>
                <CDropdown
                  id="role_option"
                  addClass="full bg-gray"
                  showIcon={false}
                  action={handleOptRatio}
                  activeMenu={getActiveMenu(roleOptions)}
                  menu={roleOptions}
                />
              </div>
            </div>
            {user.id && (
              <div className="form-group mt-15">
                <p>Status:</p>
                <CDropdown
                  id="state_option"
                  addClass="full bg-gray"
                  showIcon={false}
                  action={handleOptRatio}
                  activeMenu={getActiveMenu(stateOptions)}
                  menu={stateOptions}
                />
              </div>
            )}
            <div className="outer-div">
              <div className="form-group mt-15 flex1">
                <p>Membership:</p>
                <Select
                  options={LICENSE_OPTIONS}
                  value={selectedlicenseOptions}
                  onChange={handleLicenseChange}
                  isMulti
                  styles={CUSTOM_STYLES}
                />
                {/* <CDropdown
                  id="license_option"
                  addClass="full bg-gray"
                  showIcon={false}
                  action={handleOptRatio}
                  activeMenu={getActiveMenu(licenseOptions)}
                  menu={licenseOptions}
                  multiple={true}
                /> */}
              </div>
              <div className="form-group mt-15 flex1">
                <p>Source:</p>
                <CDropdown
                  id="source_option"
                  addClass="full bg-gray"
                  showIcon={false}
                  action={handleOptRatio}
                  activeMenu={getActiveMenu(sourceOptions)}
                  menu={sourceOptions}
                />
              </div>
            </div>
            <div className="outer-div">
              <div className="form-group mt-15 flex1">
                <p>{`${user.id ? "New" : ""}`} Password:</p>
                <div className="input-with-icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    required={user.id ? false : true}
                  />
                  {!showPassword ? (
                    <Eye
                      className="last-icon-img"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <EyeClose
                      className="last-icon-img"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </div>
              </div>
              <div className="form-group mt-15 flex1">
                <p>{`${user.id ? "New" : ""}`} Confirm Password:</p>
                <div className="input-with-icon">
                  <input
                    type={showCPassword ? "text" : "password"}
                    id="confirmpassword"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required={user.id ? false : true}
                  />
                  {!showCPassword ? (
                    <Eye
                      className="last-icon-img"
                      onClick={() => setShowCPassword(!showCPassword)}
                    />
                  ) : (
                    <EyeClose
                      className="last-icon-img"
                      onClick={() => setShowCPassword(!showCPassword)}
                    />
                  )}
                </div>
                {passwordError && <p className="text-danger">{passwordError}</p>}
              </div>
            </div>
            {user.id && (
              <div className="form-group mt-15">
                <p>Credits: {user.credits}</p>
                <div className="add-more-credits">
                  <input
                    type="number"
                    placeholder="More Credits"
                    value={moreCredits}
                    onChange={(e) => setMoreCredits(e.target.value)}
                  />
                  <CButton
                    addClass="primary light text-size-18 py-10 full"
                    mainClass="w-full"
                    type="button"
                    onClick={addMoreCredits}
                  >
                    Add
                  </CButton>
                </div>
              </div>
            )}
            {error && (
              <div className="form-group text-center text-danger mt-10">
                {error}
              </div>
            )}
            {/* {message && (
              <div className="form-group text-center text-success mt-10">
                {message}
              </div>
            )} */}
            {loading && (
              <div className="flex justify-center mt-10">
                <div className="spinner small-spinner"></div>
              </div>
            )}
            <div className={`${loading || error ? "" : "mt-30"}`}>
              <CButton
                addClass="primary light text-size-20 py-10 full large"
                mainClass="w-full"
                type="submit"
              >
                {user.id ? "Update" : "Register"}
              </CButton>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
