import { DRAW_OBJECT_TYPES } from "constants/studio";

/**
 * Sorts an array of layers based on their position property and includes the main object if specified.
 * @param {Array} layers - An array of layer objects.
 * @param {boolean} includeMainObject - Whether to include the main object (default is true).
 * @returns {Array} - The sorted array of layers.
 */
export const sortLayers = (layers, includeMainObject = true) => {
  if (!layers?.length) return [];

  const mainObject = layers?.find(
    (layer) => layer.type === DRAW_OBJECT_TYPES.mainRect
  );

  const sortedObjects = [...layers]
    .sort((prev, next) => prev?.position - next?.position)
    .filter((layer) => layer.type !== DRAW_OBJECT_TYPES.mainRect);

  if (!includeMainObject) {
    return sortedObjects;
  }

  return [mainObject, ...sortedObjects];
};

/**
 * Sorts an array of layers in reverse order based on their position property and includes the main object if specified.
 * @param {Array} layers - An array of layer objects.
 * @param {boolean} includeMainObject - Whether to include the main object (default is true).
 * @returns {Array} - The sorted array of layers in reverse order.
 */
export const sortLayersReverse = (layers, includeMainObject = true) => {
  if (!layers?.length) return [];

  const mainObject = layers?.find(
    (layer) => layer.type === DRAW_OBJECT_TYPES.mainRect
  );

  const sortedObjects = [...layers]
    .sort((prev, next) => next?.position - prev?.position)
    .filter((layer) => layer.type !== DRAW_OBJECT_TYPES.mainRect);

  if (!includeMainObject) {
    return sortedObjects;
  }

  return [mainObject, ...sortedObjects];
};
