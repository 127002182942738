import PaginatedTable from "components/atoms/PaginatedTable";
import { Slide } from "react-awesome-reveal";
import { Tooltip } from "react-tooltip";

import { ReactComponent as EyeIcon } from 'assets/imgs/admin/logs/eye.svg'
import { ReactComponent as ImageIcon } from 'assets/imgs/admin/logs/image.svg'

const formatter = (value) => {
    if (typeof value === "object" && value) {
        return JSON.stringify(value);
    }

    return value;
};

const headers = [
    {
        value: "createdAt",
        label: "Time",
    },
    // {
    //     value: "user",
    //     label: "User",
    // },
    {
        value: 'parameters',
        label: 'Payload',
        formatter
    },
    {
        value: 'platform',
        label: 'Engine',

    },
    {
        value: 'credits',
        label: "Credits"
    },
    {
        value: 'status',
        label: "Status"
    }
];



const ImageGenerations = ({ data }) => {
    const actions = [
        {
            type: "button",
            icon: <EyeIcon data-tooltip-id="tip-view-payload"
                data-tooltip-variant="light"
                data-tooltip-content="View Payload" />,
            text: "View Payload",
            onClick: (row,) => {
                if (!row.originalImages) return;
                const image = row.originalImages[0];
                window.open(image, "_blank")
            },
        },
        {
            type: "button",
            icon: <ImageIcon data-tooltip-id="tip-view-image"
                data-tooltip-variant="light"
                data-tooltip-content="View Image" />,
            text: "View Image",
            onClick: (row) => {
                if (!row.generateImages) return;
                const image = row.generateImages[0];
                window.open(image, "_blank")
            },
        },
    ];


    return <div>
        <Slide className="flex1">
            <h1>
                <span>Image Generation</span> Logs
            </h1>
        </Slide>
        <PaginatedTable
            onLoad={data.onLoad}
            hasMore={data.hasMore}
            style={{ backgroundColor: '#1B1B1B', borderRadius: '10px' }}
            data={data.data}
            headers={headers}
            actions={actions}
        />
        <Tooltip id="tip-view-payload" />
        <Tooltip id="tip-view-image" />

    </div>
}

export default ImageGenerations