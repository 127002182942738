import React, { useState } from 'react';
import { format } from 'date-fns';
import "./index.scss";
import { LICENSE } from "utils/constants";

const Accordion = ({ data, user }) => {
    const [expandedPanel, setExpandedPanel] = useState(null);
    const togglePanel = (panelIndex) => {
        setExpandedPanel(expandedPanel === panelIndex ? null : panelIndex);
    };

    if (!data || data.length === 0) {
        return <div className='no-records'>No records available!</div>;
    }

    return (
        <div className='accordion-panel'>
            <div className="accordion">
                {data.map((el, index) => (
                    <div
                        key={index}
                        className={`panel${expandedPanel === index ? ' panel--expanded' : ''}`}
                        aria-expanded={expandedPanel === index}
                    >
                        <div className="panel__label" onClick={() => togglePanel(index)}>
                            <span>{el.product_name}</span>
                        </div>
                        <div className="panel__inner">
                            <div className="panel__content">
                                <p><strong>Purchase Date: </strong>{format(new Date(el.purchase_date), 'MMM dd, yyyy')}</p>
                                <p><strong>Credits: </strong>
                                {(() => {
                                    if (
                                        (user.user.license.includes(LICENSE.PROFESSIONAL) && el.product_name.includes(LICENSE.PROFESSIONAL) || (user.user.license.includes(LICENSE.PROFESSIONAL) && el.product_name.includes('PRO')))
                                    ) {
                                        return 'Unlimited credits!';
                                    } else if ((user.user.license.includes(LICENSE.AGENCY) && el.product_name.includes(LICENSE.AGENCY)) ||
                                    (user.user.license.includes(LICENSE.ANIMATION) && el.product_name.includes(LICENSE.ANIMATION))) {
                                        return '0.00';
                                    } else {
                                        return el.credits.toLocaleString();
                                    }
                                })()}
                                </p>
                                <p><strong>Amount: </strong>{el.amount}</p>
                                {/* <p><strong>
                                    Next Billing Date:{' '}</strong>
                                    {el.next_bill_date ? format(new Date(el.next_bill_date), 'MMM dd, yyyy') : '-'}
                                </p>
                                <p><strong>Next Billing Amount: </strong>{el.next_bill_amount}</p> */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Accordion;
