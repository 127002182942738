import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { ACTION_MODES, DRAW_OBJECT_TYPES, MOVEMENT_TYPES } from "constants/studio";
import GroupObject from "lib/shapes/GroupObject";
import ImageObject from "lib/shapes/ImageObject";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLayer, resetPresent, setActiveMode, setFuture, setLayers, setMovementType, setPast, setSelectedItem } from "store/slices/studio";
import {
  getActiveMode,
  getCanvasRef,
  getIsUserInteract,
  getLayers,
  getMainObjectLocked,
  getMovementType,
  getOffsetX,
  getOffsetY,
  getScale,
  getSelectedItem,
} from "store/utils";
import { getApi } from "utils/customNetwork";
import { fileToDataUrl, formatDataUrl, imageToDataUrl } from "utils/getDataUrl";
import useHistoryDispatch from "hooks/history/useHistoryStudio";
import useText from "./useText";
import LayerObject from "lib/LayerObject";
import useBackground from "./useBackground";
import { useNavigate } from "react-router-dom";

const useContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { setLoading, showNoti } = useContext(LayoutContext);
  const { historyLayer } = useHistoryDispatch();
  const { onResizeText, onStopText } = useText();
  const { clearControls } = useBackground()

  const activeMode = useSelector(getActiveMode);
  const selectedItem = useSelector(getSelectedItem);
  const scale = useSelector(getScale);
  const offsetX = useSelector(getOffsetX);
  const offsetY = useSelector(getOffsetY);
  const canvasRef = useSelector(getCanvasRef);
  const layers = useSelector(getLayers);
  const mainObjectLocked = useSelector(getMainObjectLocked);
  const isUserInteract = useSelector(getIsUserInteract);
  const movementType = useSelector(getMovementType)

  const onUploadImage = ({ image, file, isLast }) => {
    historyLayer();
    const group = new GroupObject();
    const canvas = canvasRef?.current;

    if (!canvas) return;

    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    const context = canvas?.getContext("2d");

    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;

    const x = centerX - image.width / 2;
    const y = centerY - image.height / 2;

    const drawingInfo = {
      x,
      y,
      width: image.width,
      height: image.height,
      img: image,
      canvas: group.getCanvas(),
      data: { file },
      imageType: "upload",
    };

    const drawObject = new ImageObject(drawingInfo);
    group.addObject(drawObject);

    dispatch(addLayer(group));

    if (isLast) {
      if (activeMode === ACTION_MODES.text) {
        onStopText();
      }
      dispatch(setSelectedItem(null));
      dispatch(setActiveMode(ACTION_MODES.select));
      dispatch(setSelectedItem(group));

      group.drawResizeRect &&
        group.drawResizeRect(context, { offsetX, offsetY, scale });
    }
  };

  const onClearContent = () => { };

  const getImageObject = () => {
    let imageObject = null;
    if (selectedItem.type === DRAW_OBJECT_TYPES.image) {
      imageObject = selectedItem;
    } else if (selectedItem.type === DRAW_OBJECT_TYPES.group) {
      const objects = selectedItem.getObjects ? selectedItem.getObjects() : [];

      imageObject = objects.find(
        (item) => item.type === DRAW_OBJECT_TYPES.image
      );
    }

    return imageObject;
  };

  const onRemoveBackground = async () => {
    historyLayer();

    if (!selectedItem) {
      showNoti("error", "Please, select a picture first");
      return;
    }

    try {
      const formData = new FormData();

      const imageObject = getImageObject();
      if (!imageObject || imageObject.type !== DRAW_OBJECT_TYPES.image) {
        showNoti("error", "Please, select a picture first");
        return;
      }

      setLoading(true);

      if (imageObject.imageType === "upload") {
        formData.append("type", "upload");
        formData.append("product", imageObject.data?.file);
      } else {
        formData.append("type", "url");
        formData.append("url", imageObject.data?.url);
      }

      formData.append("width", imageObject.width);
      formData.append("height", imageObject.height);

      const data = await getApi("/removeBackgroundImg", "POST", formData);

      const img = new Image();
      img.crossOrigin = "anonymous";

      img.onload = function () {
        imageObject.setImg(img);
        imageObject.setImageType("url");
        imageObject.setData({ url: data.url });

        imageObject.clearContext();
        imageObject.draw({ scale, offsetX, offsetY });
        imageObject.drawResizeRect({ scale, offsetX, offsetY });
        // historyLayer();

        setLoading(false);
      };

      img.src = data.url;
    } catch (e) {
      showNoti("error", e);
      setLoading(false);
    }
  };

  const onRemoveText = async () => {
    historyLayer();

    if (!selectedItem) {
      showNoti("error", "Please, select a picture first");
      return;
    }

    try {
      const imageObject = getImageObject();

      if (!imageObject) {
        showNoti("error", "Please, select a picture first");
        return;
      }
      let image = null;

      if (imageObject.imageType === "upload") {
        image = await fileToDataUrl(imageObject.data.file);
      } else if (imageObject.imageType === "url") {
        image = await imageToDataUrl(imageObject.data.url);
      }

      if (!image) return;
      const formatImage = formatDataUrl(image);
      setLoading(true);

      const data = await getApi("/v1/images/generate/remove/text", "POST", {
        image: formatImage,
      });

      const img = new Image();
      img.crossOrigin = "anonymous";

      img.onload = function () {
        imageObject.setImg(img);
        imageObject.setImageType("url");
        imageObject.setData({ url: data.data.imagePath });

        imageObject.clearContext();
        imageObject.draw({ scale, offsetX, offsetY });
        imageObject.drawResizeRect({ scale, offsetX, offsetY });

        setLoading(false);
      };
      img.src = data.data.imagePath;
    } catch (e) {
      showNoti("error", e);
      setLoading(false);
    }
  };

  const onUpscale = async () => {
    historyLayer();

    if (!selectedItem) {
      showNoti("error", "Please, select a picture first");
      return;
    }

    try {
      setLoading(true);

      const imageObject = getImageObject();

      if (!imageObject) {
        showNoti("error", "Please, select a picture first");
        setLoading(true);
        return
      }

      let image = null;

      if (imageObject.imageType === "upload") {
        image = await fileToDataUrl(imageObject.data.file);
      } else if (imageObject.imageType === "url") {
        image = await imageToDataUrl(imageObject.data.url);
      }

      if (!image) {
        setLoading(true);
        return
      };
      const formatImage = formatDataUrl(image);

      const data = await getApi("/v1/images/generate/upscale", "POST", {
        image: formatImage,
      });

      const img = new Image();
      img.crossOrigin = "anonymous";

      img.onload = function () {
        imageObject.setImg(img);
        imageObject.setImageType("url");
        imageObject.setData({ url: data.data.imagePath });

        imageObject.clearContext();
        imageObject.draw({ scale, offsetX, offsetY });
        imageObject.drawResizeRect({ scale, offsetX, offsetY });

        imageObject.originalHeight = (data.data.height);
        imageObject.originalWidth = (data.data.width);

        selectedItem.originalHeight = (data.data.height);
        selectedItem.originalWidth = (data.data.width);

        setLoading(false);
      };
      img.src = data.data.imagePath;
    } catch (e) {
      showNoti("error", e);
      setLoading(false);
    }
  }

  const onStartSelect = (event) => {
    const { clientX, clientY } = event;

    const newSelectedItem = LayerObject.getSelectedItem(layers, {
      clientX,
      clientY,
      canvasRef,
      offsetX,
      offsetY,
      scale,
      mainObjectLocked,
    });

    const isNewSelectedItem =
      newSelectedItem && selectedItem !== newSelectedItem;

    if (newSelectedItem) {
      dispatch(setSelectedItem(newSelectedItem));

      newSelectedItem.drawResizeRect({ offsetX, offsetY, scale });

      const movementType =
        newSelectedItem?.checkMovement &&
        newSelectedItem.checkMovement({
          clientX,
          clientY,
          offsetX,
          offsetY,
          scale,
        });

      dispatch(setMovementType(movementType));
    } else {
      dispatch(setSelectedItem(null));
    }

    if (isNewSelectedItem) {
      clearControls();
      newSelectedItem.drawResizeRect({ offsetX, offsetY, scale });
    }
  };


  const onSelect = (event) => {
    if (!isUserInteract || !selectedItem?.resizeItem) return;

    const newMove = movementType;

    let dx = "movementX" in event ? event.movementX : 0;
    let dy = "movementY" in event ? event.movementY : 0;

    const { clientX, clientY } = event;

    if (newMove === MOVEMENT_TYPES.rotation) {
      selectedItem.rotateItem &&
        selectedItem.rotateItem(clientX, clientY, scale);
    }
    const formatDx = dx / scale;
    const formatDy = dy / scale;

    selectedItem.resizeItem(newMove, {
      dx: formatDx,
      dy: formatDy,
      isShift: event.shiftKey,
    });

    if (
      activeMode === ACTION_MODES.text &&
      selectedItem.type === DRAW_OBJECT_TYPES.text
    ) {
      onResizeText();
    }

    clearControls();

    selectedItem.clearContext();
    selectedItem.draw({
      offsetX,
      offsetY,
      scale,
    });
    selectedItem.drawResizeRect({
      offsetX,
      offsetY,
      scale,
    });
  };

  const onStopSelect = () => {
    selectedItem?.setAspectRatio &&
      selectedItem.setAspectRatio(selectedItem.width / selectedItem.height);

    dispatch(setMovementType(null));
  };

  const onImportImage = (image, user) => {
    try {
      navigate('/studio-creator')
      setLoading(true);
      let canvas = canvasRef?.current;
      let i = 0;
      while (!canvas && i <= 15) {
        canvas = canvasRef?.current;
        i++;
      }

      if (!canvas) {
        setLoading(false);
        return
      };

      dispatch(setPast([]));
      dispatch(setFuture([]));
      dispatch(setLayers([]));
      dispatch(resetPresent());

      const img = new Image();
      img.crossOrigin = "anonymous";

      img.onload = function () {
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const context = canvas?.getContext("2d");

        const centerX = canvasWidth / 2;
        const centerY = canvasHeight / 2;

        const x = centerX - image.width / 2;
        const y = centerY - image.height / 2;

        const group = new GroupObject();

        const drawingInfo = {
          x,
          y,
          width: image.width,
          height: image.height,
          img,
          canvas: group.getCanvas(),
          data: { url: image.image },
          imageType: "url",
        };

        const drawObject = new ImageObject(drawingInfo);
        group.addObject(drawObject);

        dispatch(addLayer(group));
        dispatch(setActiveMode(ACTION_MODES.select));
        dispatch(setSelectedItem(group));

        group.drawResizeRect &&
          group.drawResizeRect(context, { offsetX, offsetY, scale });
        setLoading(false);
      };

      img.src = image.image;
    } catch (e) {
      setLoading(false);
    }
  };


  return {
    onUploadImage,
    onClearContent,
    onRemoveBackground,
    onRemoveText,
    onUpscale,
    onSelect,
    onStartSelect,
    onStopSelect,
    onImportImage
  };
};

export default useContent;
