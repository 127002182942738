import { CButton } from "components/atoms/CButton";
import { CInput } from "components/atoms/CInput";

import "./index.scss";

const FeedSearch = ({
  keyword,
  onChange,
  activeMode,
  onSearch,
  setActiveMode,
}) => {
  return (
    <div id="feed-search">
      <div className="btn-group mt-15">
        <div className="search-area">
          <CInput
            value={keyword}
            onChange={(e) => onChange(e.target.value)}
            addClass="full text-white"
          />
          <CButton addClass="gradient" active={true} onClick={onSearch}>
            Search
          </CButton>
        </div>
        <div className="btn-mode">
          <CButton
            addClass="gradient"
            active={activeMode === "all"}
            onClick={() => setActiveMode("all")}
          >
            All
          </CButton>
          <CButton
            addClass="gradient"
            active={activeMode === "upscaled"}
            onClick={() => setActiveMode("upscaled")}
          >
            Upscaled
          </CButton>
        </div>
      </div>
    </div>
  );
};

export default FeedSearch;
