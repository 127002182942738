import React, { useState, useRef } from "react";

import "./index.scss";
import useClickOutside from "hooks/useClickOutside";
import { ReactComponent as ArrowSvg } from "./images/arrow.svg";

const CustomSelect = ({ options, onChange, selectValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    onChange(option.id);
    setIsOpen(false);
  };

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <div className="custom-select">
      <div ref={ref} className={`select-container ${isOpen ? "open" : ""}`}>
        <div className="selected-option" onClick={toggleDropdown}>
          <p>{selectValue ? selectValue.label : "Select an option"}</p>{" "}
          <ArrowSvg />
        </div>
        {isOpen && (
          <ul className="options">
            {options.map((option) => (
              <li
                className="option"
                key={option.id}
                onClick={() => handleOptionSelect(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
