import { getMousePos } from "components/atoms/ImageEditor/canvasUtil";
import { useDispatch, useSelector } from "react-redux";
import { getActiveMode, getBrushCanvasRef, getBrushSize, getEraserSize, getIsUserInteract, getLayers, getMainObjectLocked, getOffsetX, getOffsetY, getScale } from "store/utils";
import { toRealX, toRealY } from "utils/studio/utils";
import useCursor from "./useCursor";
import BrushObject from "lib/drawings/BrushObject";
import { DRAW_OBJECT_TYPES } from "constants/studio";
import { addLayer } from "store/slices/studio";
import LayerObject from "lib/LayerObject";
import EraserObject from "lib/drawings/EraserObject";
import GroupObject from "lib/shapes/GroupObject";

const useBrush = () => {
  const { onDrawCursor, onClearCursor } = useCursor();

  const brushCanvasRef = useSelector(getBrushCanvasRef);
  const brushSize = useSelector(getBrushSize);
  const eraserSize = useSelector(getEraserSize);
  const scale = useSelector(getScale);
  const offsetX = useSelector(getOffsetX);
  const offsetY = useSelector(getOffsetY);
  const activeMode = useSelector(getActiveMode);
  const isUserInteract = useSelector(getIsUserInteract);
  const layers = useSelector(getLayers);
  const mainObjectLocked = useSelector(getMainObjectLocked);

  const dispatch = useDispatch();

  const onClearBrush = () => {
    const canvas = brushCanvasRef?.current;

    const ctx = canvas?.getContext("2d");

    if (!canvas || !ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const onStartDraw = (event) => {
    const canvas = brushCanvasRef.current;

    if (!canvas) return;

    const { x, y } = getMousePos(canvas, event);

    const newPoint = {
      x: toRealX(x, offsetX, scale),
      y: toRealY(y, offsetY, scale),
    };

    switch (activeMode) {
      case DRAW_OBJECT_TYPES.brush: {
        const newCurLayer = {
          points: [newPoint],
          meta: {
            lineWidth: brushSize,
          },
          canvas: brushCanvasRef.current,
        };

        const brushLayer = new BrushObject(newCurLayer);
        brushLayer.draw({ scale, offsetX, offsetY });
        dispatch(addLayer(brushLayer));

        break;
      }

      case DRAW_OBJECT_TYPES.eraser: {
        onEraseBrush(newPoint, true);

        const selectedItems = LayerObject.getAllSelectedItems(layers, {
          clientX: event.clientX,
          clientY: event.clientY,
          offsetX,
          offsetY,
          scale,
          mainObjectLocked,
          canvas,
        });

        updateEraserLayer(selectedItems, newPoint, true);
        break;
      }
      default:
    }
  };

  const onBrush = (event) => {
    onClearCursor();

    const canvas = brushCanvasRef.current;
    const { x, y } = getMousePos(canvas, event);

    if (!isUserInteract) {
      onDrawCursor({ x, y, size: brushSize });
      return;
    }

    const lastLayer = layers?.[layers.length - 1];
    const newPoint = {
      x: toRealX(x, offsetX, scale),
      y: toRealY(y, offsetY, scale),
    };

    if (!lastLayer) return;

    if (lastLayer.type === DRAW_OBJECT_TYPES.group) {
      const brushLayer = lastLayer.objects.find(
        (layer) => layer.type === DRAW_OBJECT_TYPES.brush
      );

      if (brushLayer) {
        const newData = [...brushLayer.points, newPoint];
        brushLayer?.setPoints(newData);
      }
    } else {
      const pts = lastLayer?.points || [];
      const newData = [...pts, newPoint];
      lastLayer?.setPoints && lastLayer?.setPoints(newData);
    }

    lastLayer.draw({ scale, offsetX, offsetY });
  };

  const onEraseBrush = (newPoint, newLayer) => {
    const lastLayer = layers?.[layers.length - 1];

    if (lastLayer.type === DRAW_OBJECT_TYPES.eraser && !newLayer) {
      const pts = lastLayer?.points;

      const newData = [...pts, newPoint];
      lastLayer?.setPoints && lastLayer?.setPoints(newData);
      lastLayer?.draw && lastLayer.draw({ scale, offsetX, offsetY });
    } else {
      const newCurLayer = {
        points: [newPoint],
        position: 10,
        meta: {
          lineWidth: eraserSize,
        },
        canvas: brushCanvasRef.current,
      };

      const newEraseObject = new EraserObject(newCurLayer);

      dispatch(addLayer(newEraseObject));

      newEraseObject.draw({ scale, offsetX, offsetY });
    }
  };

  const onErase = (event) => {
    onClearCursor();

    const canvas = brushCanvasRef.current;
    const { x, y } = getMousePos(canvas, event);

    if (!isUserInteract) {
      onDrawCursor({ x, y, type: "ring", size: eraserSize });
      return;
    }

    const newPoint = {
      x: toRealX(x, offsetX, scale),
      y: toRealY(y, offsetY, scale),
    };

    const selectedItems = LayerObject.getAllSelectedItems(layers, {
      clientX: event.clientX,
      clientY: event.clientY,
      offsetX,
      offsetY,
      scale,
      mainObjectLocked,
      canvas,
    });

    onEraseBrush(newPoint);
    updateEraserLayer(selectedItems, newPoint);
  };

  const updateEraserLayer = (selectedItems, newPoint, newLayer) => {
    selectedItems.forEach((item) => {
      let eraserLayer = null;

      if (item.type !== DRAW_OBJECT_TYPES.group) {
        const eraserLayers = item.eraserLayers;
        eraserLayer = eraserLayers[eraserLayers.length - 1];
      } else {
        const groupObjects = item.getObjects();
        const eraserLayers = groupObjects.filter(
          (item) => item.type === DRAW_OBJECT_TYPES.eraser
        );
        eraserLayer = eraserLayers
          ? eraserLayers[eraserLayers?.length - 1]
          : null;
      }

      const canvas = item.getCanvas();

      if (!eraserLayer || newLayer) {
        const newCurLayer = {
          points: [newPoint],
          meta: {
            lineWidth: eraserSize,
          },
          canvas,
        };

        const newEraserLayer = new EraserObject(newCurLayer);

        if (item instanceof GroupObject) {
          item.addObject(newEraserLayer);
        } else {
          item.addEraserLayer(newEraserLayer);
        }

        newEraserLayer.draw({ offsetX, offsetY, scale, redraw: false });
        return;
      }

      const points = eraserLayer.getPoints();
      eraserLayer.setPoints([...points, newPoint]);
      eraserLayer.draw({ offsetX, offsetY, scale, redraw: false });
    });
  };


  return {
    onClearBrush,
    onBrush,
    onStartDraw,
    onErase
  };
};

export default useBrush;
