import * as React from 'react';
import './styles.scss'

export function CButton({
    mainClass="",
    addClass="", // primary || secondary || dark || gradient || gradient2
    disabled = false,
    onClick = () => {},
    active = false,
    size = "",
    type = "button",
    children
}) {
    return (
        <div className={`${mainClass}`} style={{display: 'inline-block'}}>
            <button 
                className={`custom-button ${addClass} ${active ? 'active' : ''} ${size}`}
                disabled={disabled}
                onClick={onClick}
                type={type}
            >
                {children}
            </button>
        </div>
    )
}