import { useContext, useState, useEffect } from "react";
import AdminTable from "components/atoms/AdminTable";
import { CButton } from "components/atoms/CButton";
import WebinarModal from "components/atoms/Modal/WebinarModal";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { AdminLayout } from "components/layouts/AdminLayout";
import { ReactComponent as IconEdit } from "assets/imgs/action/icon_edit.svg";
import { ReactComponent as IconRemove } from "assets/imgs/action/icon_delete.svg";
import { Tooltip } from "react-tooltip";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import "./index.scss";

const headers = [
  {
    value: "url",
    label: "Video URL",
    formatter: (value) => (
      <a href={value} target="_blank" rel="noreferrer">
        {value}
      </a>
    ),
  },
  {
    value: "title",
    label: "Video Title",
  },
];

export const AdminWebinar = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModaldel, setOpenModaldel] = useState(false);
  const [data, setData] = useState([]);
  const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
  const [openedItem, setOpenedItem] = useState(null);
  const [openedItemdel, setOpenedItemdel] = useState(null);

  const onGetData = async () => {
    try {
      setLoading(true);
      checkToken();

      const data = await getApi("/webinars/all", "GET");

      if (data.data) {
        setData(data.data);
      }
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetData();
  }, []);

  const handleSaveWebinar = async ({ url, title }) => {
    try {
      setLoading(true);
      const newWebinar = await getApi("/admin/webinars/create", "POST", {
        url,
        title,
      });
      if (newWebinar.data) {
        const newData = [newWebinar.data, ...data];
        setData(newData);
      }
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (webinarId) => {
    console.log(webinarId);
    try {
      setLoading(true);
      await getApi(`/admin/webinars/${webinarId.id}/delete`, "DELETE");

      const newData = [...data].filter((item) => item.id !== webinarId.id);
      setData(newData);
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const actions = [
    {
      type: "button",
      icon: <IconEdit data-tooltip-id="tip-edit"
        data-tooltip-variant="light"
        data-tooltip-content="Edit" />,
      onClick: (row) => {
        setOpenModal(true);
        setOpenedItem(row);
      },
    },
    {
      type: "button",
      icon: <IconRemove data-tooltip-id="tip-delete"
        data-tooltip-variant="light"
        data-tooltip-content="Delete" />,
      onClick: (row) => {
        setOpenModaldel(true);
        setOpenedItemdel(row);
      },
    },
  ];

  const handleEdit = async ({ id, title, url }) => {
    try {
      setLoading(true);
      const updatedItem = await getApi(`/admin/webinars/${id}/edit`, "PUT", {
        title,
        url,
      });

      if (updatedItem.data) {
        const newData = [...data].map((item) => {
          if (item.id === updatedItem.data.id) {
            return updatedItem.data;
          }

          return item;
        });

        setData(newData);
      }
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const onHandleUpdate = (webinar) => {
    if (webinar.id) {
      handleEdit(webinar);
    } else {
      handleSaveWebinar(webinar);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenedItem(null);
  };

  const onHandleUpdatedel = async () => {
    try {
      setLoading(true);
      await handleDelete(openedItemdel);
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
      setOpenModaldel(false);
      setOpenedItemdel(null);
    }
  };

  const handleClosedel = () => {
    setOpenModaldel(false);
    setOpenedItemdel(null);
  };

  return (
    <AdminLayout page="webinar" caption="Webinar" message="Webinar page">
      <div className="admin-dashboard body-container">
        <div className="head webinar-flex">
          <Slide className="flex1">
            <h1>
              <span>Webinar</span> Page
            </h1>
          </Slide>
          <CButton
            onClick={() => setOpenModal(true)}
            addClass="primary active text-size-18"
          >
            Create webinar
          </CButton>
        </div>
        <AdminTable headers={headers} data={data} actions={actions} />
        <Tooltip id="tip-edit" />
        <Tooltip id="tip-delete" />
      </div>
      {openModal && (
        <WebinarModal
          onHandleUpdate={onHandleUpdate}
          show={true}
          webinar={openedItem}
          onClose={handleClose}
        />
      )}
      {openModaldel && (
        <ConfirmationModal
          isOpen={true}
          onClose={handleClosedel}
          onConfirm={onHandleUpdatedel}
          itemModule="Webinar"
          itemName={openedItemdel.title}
        />
      )}
    </AdminLayout>
  );
};

