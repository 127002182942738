import Action from "../AdminTable/Action";
import { CButton } from "../CButton";

const PaginatedTable = ({
    headers = [],
    data = [],
    actions = [],
    style = {},
    onLoad = () => { },
    hasMore = false
}) => {
    const getRowValue = (header, row) => {
        const format = header.formatter;
        const value = row[header.value];
        if (format) {
            return format(value);
        }
        return value;
    };

    return (
        <div className="custom-table custom-table_paginated mt-25 mb-60" >
            <table style={style}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index} className="tableHeader">{header.label}</th>
                        ))}
                        {!!actions.length && <th className="tableHeader" key="actions">Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => (
                        <tr key={row.id}>
                            {headers.map((header, index) => (
                                <td key={index} className="tableCell">{getRowValue(header, row) || ""}</td>
                            ))}
                            {!!actions.length && (
                                <td className="tableCell tableCellActions">
                                    {actions.map((action, index) => (
                                        <Action key={index} action={action} row={row} />
                                    ))}
                                </td>
                            )}
                        </tr>
                    ))}
                    {hasMore && (
                        <tr>
                            <td className="tableCell">
                                <CButton addClass="primary" active onClick={onLoad}>
                                    Load more
                                </CButton>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

        </div>
    );
};

export default PaginatedTable;
