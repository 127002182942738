import classNames from "classnames";
import "./index.scss";

export const LABEL_COLORS = {
  green: "green",
  purple: "purple",
  yellow: "yellow",
};

const Label = ({
  Icon = null,
  color = LABEL_COLORS.green,
  onClick = () => {},
  children,
}) => {
  const wrapClasses = classNames("label", {
    label_yellow: color === LABEL_COLORS.yellow,
    label_green: color === LABEL_COLORS.green,
    label_purple: color === LABEL_COLORS.purple,
  });

  return (
    <button type="button" className={wrapClasses} onClick={onClick}>
      {Icon}
      <span>{children}</span>
    </button>
  );
};

export default Label;
