import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import "./index.scss";

export function ContactModal({ show = false, onClose = () => {} }) {
  return (
    <Modal isOpen={show} onClick={onClose} className="membership-modal small">
      <IconClose className="cursor-pointer ico-close" onClick={onClose} />
      <div className="contact-modal">
        <iframe
          className="no-border"
          sandbox="allow-scripts allow-popups allow-forms allow-same-origin"
          width="100%"
          title="Contact"
          height="700px"
          loading="lazy"
          src="https://forms.helpdesk.com?licenseID=1541615097&contactFormID=8144f1ec-3fd3-4372-a04e-f63d670832f8"
        >
          Your browser does not allow embedded content.
        </iframe>
      </div>
    </Modal>
  );
}
