import classNames from "classnames";
import "./index.scss";

export function EditorControlButton({
  children,
  isActive,
  onClick,
  disabled = false,
}) {
  const classes = classNames("editor-control-button", {
    "editor-control-button_active": isActive,
  });

  return (
    <button onClick={onClick} className={classes} disabled={disabled}>
      {children}
    </button>
  );
}
