import shortName from "utils/shortName";

const UserIcon = ({ user }) => {
  return (
    <div className="user-section">
      <div className="user-initial">
        {user?.user?.profile_img ? (
          <div className="user-avatar">
            <img src={user?.user?.profile_img} alt="profile" />
          </div>
        ) : (
          <div className="user-avatar">{shortName(user)}</div>
        )}
      </div>
      <div className="user-name">
        <h2>
          {user.user?.first_name} {user.user?.last_name}
        </h2>
        <p>{user?.user?.email}</p>
      </div>
    </div>
  );
};

export default UserIcon;
