import FontPicker from "react-fontpicker-ts-lite";
import { useMemo } from "react";

import { ReactComponent as BoldSvg } from "./images/bold.svg";
import { ReactComponent as ItalicSvg } from "./images/italic.svg";
import { ReactComponent as DecorationSvg } from "./images/decoration.svg";
import { ReactComponent as LineThroughSvg } from "./images/lineThrough.svg";
import { ReactComponent as ColorSvg } from "./images/color.svg";
import { ReactComponent as BackgroundSvg } from "./images/background.svg";
import { setFont } from "store/slices/studio";
import {
  getFont,
  getOffsetX,
  getOffsetY,
  getScale,
  getSelectedItem,
} from "store/utils";

import { FONT_SIZE_OPTIONS } from "./constants";
import CustomSelect from "components/atoms/CSelect";
import { useDispatch, useSelector } from "react-redux";
import ColorPopup from "../ColorPopup";
import { TEXT_INPUT } from "constants/studio";
import TextObject from "lib/shapes/TextObject";

import "react-fontpicker-ts-lite/dist/index.css";
import "./index.scss";

const TextPopup = () => {
  const font = useSelector(getFont);
  const dispatch = useDispatch();
  const selectedItem = useSelector(getSelectedItem);
  const scale = useSelector(getScale);
  const offsetX = useSelector(getOffsetX);
  const offsetY = useSelector(getOffsetY);

  const updateLayer = (meta) => {
    if (!selectedItem) return;
    selectedItem.setMeta(meta);

    const input = document.getElementById(TEXT_INPUT);

    if (input) {
      const size = TextObject.calculateSize({
        ...meta,
        scale,
        text: input.value,
      });

      if (size.width) {
        input.style.width = size.width + "px";

        selectedItem.setWidth(input.offsetWidth);
      }
    }

    selectedItem.draw({ scale, offsetX, offsetY });
    selectedItem.drawResizeRect({ scale, offsetX, offsetY });
  };

  const onChangeStyle = (style) => {
    if (font.style === style) {
      dispatch(setFont({ style: "normal" }));
    } else {
      dispatch(setFont({ style }));
    }
  };

  const onSetBold = () => {
    if (font.fontWeight === 700) {
      dispatch(setFont({ fontWeight: 400 }));
    } else {
      dispatch(setFont({ fontWeight: 700 }));
    }
  };

  const setFontFamily = (fontFamily) => {
    dispatch(setFont({ fontFamily }));
    updateLayer({ ...font, fontFamily });
  };

  const setSize = (fontSize) => {
    dispatch(setFont({ fontSize }));
    updateLayer({ ...font, fontSize });
  };

  const setColor = (color) => {
    dispatch(setFont({ color }));
  };

  const setBackground = (background) => {
    dispatch(setFont({ background }));
  };

  const setTextDecoration = (textDecoration) => {
    if (font.textDecoration === textDecoration) {
      dispatch(setFont({ textDecoration: null }));
    } else {
      dispatch(setFont({ textDecoration }));
    }
  };

  const fontSizeValue = useMemo(() => {
    const value = FONT_SIZE_OPTIONS.find(
      (option) => option.id === font.fontSize
    );
    if (!value) {
      return {
        id: font?.fontSize,
        label: `${Number(font?.fontSize || 0).toFixed(1)}px`,
      };
    }
    return value;
  }, [font?.fontSize]);

  return (
    <div className="text-popup">
      <FontPicker autoLoad defaultValue={"Roboto"} value={setFontFamily} />
      <CustomSelect
        value={font.fontSize}
        onChange={setSize}
        options={FONT_SIZE_OPTIONS}
        selectValue={fontSizeValue}
      />
      <button className="text-popup__button" onClick={onSetBold}>
        <BoldSvg />
      </button>
      <button
        className="text-popup__button"
        onClick={() => onChangeStyle("italic")}
      >
        <ItalicSvg />
      </button>
      <button
        className="text-popup__button"
        onClick={() => setTextDecoration("underline")}
      >
        <DecorationSvg />
      </button>
      <button
        className="text-popup__button"
        onClick={() => setTextDecoration("line-through")}
      >
        <LineThroughSvg />
      </button>
      <ColorPopup
        color={font.color}
        onChange={setColor}
        trigger={<ColorSvg />}
      />
      <ColorPopup
        color={font.background}
        onChange={setBackground}
        trigger={<BackgroundSvg />}
      />
    </div>
  );
};

export default TextPopup;
