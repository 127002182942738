export const getTrendImages = (state) => state.replaceObject.trendImages;
export const getStep = (state) => state.replaceObject.step;
export const getImageType = (state) => state.replaceObject.imageType;
export const getCurrentPage = (state) => state.replaceObject.currentPage;
export const getOrgImageSize = (state) => state.replaceObject.orgImageSize;
export const getImageSize = (state) => state.replaceObject.imageSize;
export const getIsLast = (state) => state.replaceObject.isLast;
export const getSelectedImage = (state) => state.replaceObject.selectedImage;
export const getActiveTrend = (state) => state.replaceObject.activeTrend;
export const getActiveMode = (state) => state.replaceObject.activeMode;
export const getKeyword = (state) => state.replaceObject.keyword;
export const getResultImage = (state) => state.replaceObject.resultImage;
export const getNewImage = (state) => state.replaceObject.newImage;
export const getLayers = (state) => state.replaceObject.layers;
export const getBackupLayers = (state) => state.replaceObject.backupLayers;
export const getRedoStep = (state) => state.replaceObject.redoStep;
export const getReplaceMode = (state) => state.replaceObject.replaceMode;