export const FONT_SIZE_OPTIONS = [
  {
    id: 8,
    label: "8px",
  },
  {
    id: 10,
    label: "10px",
  },
  {
    id: 12,
    label: "12px",
  },
  {
    id: 14,
    label: "14px",
  },
  {
    id: 16,
    label: "16px",
  },
  {
    id: 18,
    label: "18px",
  },
  {
    id: 20,
    label: "20px",
  },
  {
    id: 22,
    label: "22px",
  },
  {
    id: 24,
    label: "24px",
  },
  {
    id: 26,
    label: "26px",
  },
  {
    id: 28,
    label: "28px",
  },
  {
    id: 30,
    label: "30px",
  },
  {
    id: 32,
    label: "32px",
  },
  {
    id: 34,
    label: "34px",
  },
  {
    id: 36,
    label: "36px",
  },
  {
    id: 38,
    label: "38px",
  },
  {
    id: 40,
    label: "40px",
  },
  {
    id: 42,
    label: "42px",
  },
  {
    id: 44,
    label: "44px",
  },
  {
    id: 46,
    label: "46px",
  },
  {
    id: 48,
    label: "48px",
  },
  {
    id: 50,
    label: "50px",
  },
  {
    id: 52,
    label: "52px",
  },
];
