import { MOVEMENT_TYPES } from "constants/studio";
import LayerObject from "../LayerObject";

class DrawObject extends LayerObject {
  points = [];
  offsetX = 0;
  offsetY = 0;
  x = 0;
  y = 0;
  width = null;
  height = null;

  constructor({
    points = [],
    position,
    type,
    canvas,
    meta,
    rotation = 0,
    x = 0,
    y = 0,
    width = null,
    height = null,
  }) {
    super({ type, position, canvas, meta, rotation });
    this.points = points;
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
  }

  setPoints(points) {
    this.points = points;
  }
  getPoints() {
    return this.points;
  }

  resizeItem(movementType, { dx, dy }) {
    let newX = this.offsetX || 0;
    let newY = this.offsetY || 0;

    switch (movementType) {
      case MOVEMENT_TYPES.move:
        newX += dx;
        newY += dy;
        break;
      // case MOVEMENT_TYPES.resize_A:
      //   newX += dx;
      //   newY += dy;
      //   break;
      // case MOVEMENT_TYPES.resize_B:
      //   newY += dy;
      //   break;
      // case MOVEMENT_TYPES.resize_C:
      //   break;
      // case MOVEMENT_TYPES.resize_D:
      //   newX += dx;
      //   break;
      // case MOVEMENT_TYPES.resize_AB:
      //   newY += dy;
      //   break;
      // case MOVEMENT_TYPES.resize_AD:
      //   newX += dx;
      //   break;

      default:
        break;
    }

    this.offsetX = newX;
    this.offsetY = newY;
  }
}

export default DrawObject;
