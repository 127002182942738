export const getTrendImages = (state) => state.colorChanger.trendImages;
export const getStep = (state) => state.colorChanger.step;
export const getImageType = (state) => state.colorChanger.imageType;
export const getCurrentPage = (state) => state.colorChanger.currentPage;
export const getOrgImageSize = (state) => state.colorChanger.orgImageSize;
export const getImageSize = (state) => state.colorChanger.imageSize;
export const getIsLast = (state) => state.colorChanger.isLast;
export const getSelectedImage = (state) => state.colorChanger.selectedImage;
export const getActiveTrend = (state) => state.colorChanger.activeTrend;
export const getActiveMode = (state) => state.colorChanger.activeMode;
export const getKeyword = (state) => state.colorChanger.keyword;
export const getResultImage = (state) => state.colorChanger.resultImage;
export const getNewImage = (state) => state.colorChanger.newImage;
export const getLayers = (state) => state.colorChanger.layers;
export const getBackupLayers = (state) => state.colorChanger.backupLayers;
export const getRedoStep = (state) => state.colorChanger.redoStep;
export const getReplaceMode = (state) => state.colorChanger.replaceMode;