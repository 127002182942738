import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import "./creditHistoryModalStyles.scss";
import { useEffect, useState } from "react";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useContext } from "react";
import useTable from "hooks/useTable";
import { format } from "date-fns";
const rowsPerPage = 5;

export function CreditHistoryModal({
  show = false,
  user = {},
  onClose = () => {},
}) {
  const { checkToken } = useContext(LayoutContext);
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(histories, page, rowsPerPage);

  const getCreditHistory = async (event) => {
    let param = { user_id: user.id };
    let apiUrl = "/admin/getCreditHistories";
    setLoading(true);
    try {
      checkToken();
      const data = await getApi(apiUrl, "POST", param);

      setHistories(data.data);
    } catch (err) {
      setError(err);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (user) {
      getCreditHistory();
    }
  }, [user]);

  return (
    <Modal
      isOpen={show}
      ariaHideApp={false}
      onClick={onClose}
      className="credit-history-modal"
    >
      <IconClose className="cursor-pointer ico-close" onClick={onClose} />
      <div className="user-area">
        <h3 className="text-center">{user.first_name} Credit Histories</h3>
        <div className="custom-table mt-25 mb-10">
          <table>
            <thead>
              <tr>
                <th className="tableHeader">Credits</th>
                <th className="tableHeader">Photo</th>
                <th className="tableHeader">Prompt Text</th>
                <th className="tableHeader">Dimension</th>
                <th className="tableHeader">Created Date</th>
              </tr>
            </thead>
            <tbody>
              {slice.map((el) => (
                <tr key={el.id}>
                  <td className="tableCell">-${el.credits}</td>
                  <td className="tableCell">
                    <a href={el.image} target="_blank" rel="noreferrer">
                      <img src={el.image} alt={el.prompt_text} />
                    </a>
                  </td>
                  <td className="tableCell prompt">
                    <p>{el.prompt_text}</p>
                  </td>
                  <td className="tableCell">{el.width + " * " + el.height}</td>
                  <td className="tableCell">
                    {format(new Date(el.created_at), "MMMM dd, yyyy")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="flex justify-center mt-10">
              <div className="spinner small-spinner"></div>
            </div>
          )}
          {range.length > 0 && (
            <div className="pagination mb-20 mt-10">
              <button
                onClick={() => setPage(1)}
                disabled={page === 1 || range.length === 1}
              >
                {"<<"}
              </button>
              <button
                onClick={() => setPage(page - 1)}
                disabled={page === 1 || range.length === 1}
              >
                {"<"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {page} of {range.length}
                </strong>{" "}
              </span>
              <button
                onClick={() => setPage(page + 1)}
                disabled={range.length === 1 || page === range.length}
              >
                {">"}
              </button>
              <button
                onClick={() => setPage(range.length)}
                disabled={page === range.length}
              >
                {">>"}
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
