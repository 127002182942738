import { useState, useEffect, useContext } from "react";

import { Slide } from "react-awesome-reveal";
import Video from "components/atoms/Video";
import { DashboardLayout } from "components/layouts/DashboardLayout";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";

import { ReactComponent as YoutubeSvg } from "./images/youtube.svg";

import "./index.scss";

const VideoTraining = () => {
  const [data, setData] = useState([]);
  const { setLoading, showNoti, checkToken } = useContext(LayoutContext);

  const getData = async () => {
    try {
      setLoading(true);
      checkToken();

      const response = await getApi("/webinars/all", "GET");
      if (response.data) {
        setData(response.data);
      }
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onClick = () => {
    window.open(
      "https://www.youtube.com/channel/UC_aV_vW4KhVPjRxCLOK7iyg?view_as=subscriber?sub_confirmation=1",
      "_blank"
    );
  };

  return (
    <DashboardLayout
      caption="Video Training"
      message="Welcome to Creativio AI"
      page="Video Trainings"
    >
      <main className="video-training">
        <section className="video-training__wrap">
          <div className="body-container">
            <Slide>
              <div className="video-training__header">
                <h1>
                  <span>Video</span> Trainings
                </h1>
                <button onClick={onClick} className="video-training__subscribe">
                  <YoutubeSvg />
                  Subscribe On Youtube
                </button>
              </div>
            </Slide>
            <div className="video-list">
              {data.map((video) => (
                <Video video={video} />
              ))}
            </div>
          </div>
        </section>
      </main>
    </DashboardLayout>
  );
};

export default VideoTraining;
