export const getFormatData = (data = { success: 0, failure: 0 }) => ({
    labels: ['Success', 'Failure',],
    datasets: [
        {
            label: '№ of Logs',
            data: [data.success, data.failure],
            backgroundColor: [
                '#31BA2E',
                '#DF633C',
            ],
        },
    ]
});