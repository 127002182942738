import * as React from "react";
import "./styles.scss";

export function CTextarea({
  addClass = "",
  disabled = false,
  onChange = () => { },
  active = false,
  readOnly = false,
  placeholder = "",
  rows = 3,
  value = "",
  ...rest
}) {
  return (
    <textarea
      className={`custom-textarea ${addClass} ${active ? "active" : ""}`}
      disabled={disabled}
      placeholder={placeholder}
      rows={rows}
      readOnly={readOnly}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
}
