import { createSlice } from "@reduxjs/toolkit";

const IMAGE_TYPES = {
    url: "url",
    upload: "upload",
};

const STEPS = {
    upload: "upload",
    edit: "edit",
};

const TRENDS = {
    trending: "trending",
    new: "new",
    top: "top",
};

const MODES = {
    all: "all",
    upscaled: "upscaled",
};

const initialState = {
    step: STEPS.upload,
    imageType: IMAGE_TYPES.url,
    trendImages: [],
    currentPage: 1,
    isLast: false,
    selectedImage: null,
    orgImageSize: {
        width: 1024,
        height: 1024,
    },
    imageSize: {
        height: 1024,
        width: 1024,
    },
    activeTrend: TRENDS.trending,
    activeMode: MODES.all,
    keyword: "",
    resultImage: null,
    newImage: null,
    layers: [],
    backupLayers: [],
    redoStep: 0,
};

export const textRemover = createSlice({
    name: "textRemover",
    initialState,
    reducers: {
        setImageType: (state, action) => {
            state.imageType = action.payload;
        },
        setOrgImageSize: (state, action) => {
            state.orgImageSize = action.payload;
        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
        setTrendImages: (state, action) => {
            state.trendImages = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setImageSize: (state, action) => {
            state.imageSize = action.payload;
        },
        setIsLast: (state, action) => {
            state.isLast = action.payload;
        },
        setSelectedImage: (state, action) => {
            state.selectedImage = action.payload;
        },
        setActiveTrend: (state, action) => {
            state.activeTrend = action.payload;
        },
        setActiveMode: (state, action) => {
            state.activeMode = action.payload;
        },
        setKeyword: (state, action) => {
            state.keyword = action.payload;
        },
        setResultImage: (state, action) => {
            state.resultImage = action.payload;
        },
        setNewImage: (state, action) => {
            state.newImage = action.payload;
        },
        setLayers: (state, action) => {
            state.layers = action.payload
        },
        setBackupLayers: (state, action) => {
            state.backupLayers = action.payload
        },
        setRedoStep: (state, action) => {
            state.redoStep = action.payload
        }
    },
});

export const {
    setTrendImages,
    setCurrentPage,
    setStep,
    setImageType,
    setOrgImageSize,
    setImageSize,
    setIsLast,
    setSelectedImage,
    setActiveMode,
    setActiveTrend,
    setKeyword,
    setNewImage,
    setResultImage,
    setLayers,
    setBackupLayers,
    setRedoStep
} = textRemover.actions;

export default textRemover.reducer;
