import './dashboard.scss'
import { AdminSidebar } from "components/atoms/Sidebar/AdminSidebar";
import { AdminHeader } from "components/atoms/Header/AdminHeader";

export function AdminLayout({ 
    page = "Dashboard", 
    caption = "", 
    message = "", 
    children 
}) {
    return (
        <div className="app-dashboard">
            <AdminSidebar />
            <AdminHeader page={page} caption={caption} message={message} />
            {children}
        </div>
    );
}
