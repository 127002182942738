import { CButton } from "components/atoms/CButton";
import { ReactComponent as ChartUp } from "assets/imgs/chartup.svg";
import { ReactComponent as Stars } from "assets/imgs/stars.svg";
import { CDropdown } from "components/atoms/CDropdown";

import "./index.scss";

const FeedHeader = ({ activeTrend, onChangeMode, onChangeType, options }) => {
  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };

  return (
    <div id="feed-header">
      <div className="btn-actions">
        <CButton
          addClass="gradient"
          active={activeTrend === "trending"}
          onClick={() => onChangeMode("trending")}
        >
          Trending
        </CButton>
        <CButton
          addClass="gradient"
          active={activeTrend === "new"}
          onClick={() => onChangeMode("new")}
        >
          <Stars className="btn-icon" />
          New
        </CButton>
        <CButton
          addClass="gradient"
          active={activeTrend === "top"}
          onClick={() => onChangeMode("top")}
        >
          <ChartUp className="btn-icon" />
          Top
        </CButton>
        <CDropdown
          id="view_option"
          addClass="w-220"
          showIcon={false}
          action={onChangeType}
          activeMenu={getActiveMenu(options)}
          menu={options}
        />
      </div>
    </div>
  );
};

export default FeedHeader;
