import { useSelector } from "react-redux";
import { getCursorCanvasRef } from "store/utils";

const useCursor = () => {
  const cursorCanvas = useSelector(getCursorCanvasRef);

  const onDrawCursor = ({ x, y, type, size = 10 }) => {
    const canvas = cursorCanvas?.current;
    if (!canvas) return;
    const ctx = canvas?.getContext("2d");

    ctx.beginPath();

    ctx.beginPath();
    ctx.globalCompositeOperation = "source-out";

    if (type === "ring") {
      ctx.arc(x, y, size / 2, 0, 2 * Math.PI, false);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "white";
      ctx.stroke();
    } else {
      ctx.arc(x, y, size / 2, 0, 2 * Math.PI);
      ctx.fillStyle = "#7371fc80";
      ctx.fill();
    }
  };

  const onClearCursor = () => {
    const canvas = cursorCanvas?.current;
    if (!canvas) return;

    const cursorCtx = canvas?.getContext("2d");

    if (!cursorCtx) return;

    cursorCtx.clearRect(0, 0, cursorCanvas.width, cursorCanvas.height);
  };

  return { onDrawCursor, onClearCursor };
};

export default useCursor;
