/* eslint-disable no-loop-func */
import { DRAW_OBJECT_TYPES } from "constants/studio";
import { getTransformedPoint } from "./utils";
import DrawObject from "lib/drawings/DrawObject";
import { sortLayers } from "./sortLayers";
import LayerObject from "lib/LayerObject";

const onDownload = (layers, callback = () => { }) => {
  try {
    if (!layers.length) {
      callback()
      return
    }
    const filteredLayers = [...layers].filter(
      (layer) =>
        layer?.type !== DRAW_OBJECT_TYPES.brush &&
        layer?.type !== DRAW_OBJECT_TYPES.eraser
    );

    const sortedLayers = sortLayers(filteredLayers, false);

    const { maxX, minX, maxY, minY } = calculateMinMax(sortedLayers);

    const canvasWidth = maxX - minX;
    const canvasHeight = maxY - minY;

    const canvas = document.createElement("canvas");
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.translate(minX, minY);

    onDrawLayers(sortedLayers, minX, minY, ctx);

    canvas.toBlob(function (blob) {
      if (!blob) return;
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = "studio_editor.png";
      a.dispatchEvent(new MouseEvent("click"));
      callback()
    });

    ctx.restore();

  } catch (e) {
    callback()
  }

};

const onGetLayerVertices = (layer) => {
  const { matrix, originX, originY, width, height } =
    layer.getCenterCoordinates({});

  const resizePoints = layer.getResizePoints({
    x: originX,
    y: originY,
    width,
    height,
  });

  const vertices = {
    resize_A: getTransformedPoint(resizePoints.resize_A, matrix),
    resize_B: getTransformedPoint(resizePoints.resize_B, matrix),
    resize_C: getTransformedPoint(resizePoints.resize_C, matrix),
    resize_D: getTransformedPoint(resizePoints.resize_D, matrix),
  };

  return vertices;
};

const calculateMinMax = (layers) => {
  const firstLayer = layers[0];
  let minX = firstLayer.x || 0;
  let minY = firstLayer.y || 0;
  let maxX = (firstLayer.x || 0) + (firstLayer.width * firstLayer.scaleX || 0);
  let maxY = (firstLayer.y || 0) + (firstLayer.height * firstLayer.scaleY || 0);

  for (let layer of layers) {
    if (layer instanceof DrawObject) {
      continue;
    }

    const vertices = onGetLayerVertices(layer);

    Object.values(vertices).forEach((vertex) => {
      minX = Math.min(minX, vertex.x);
      minY = Math.min(minY, vertex.y);
    });

    Object.values(vertices).forEach((vertex) => {
      maxX = Math.max(maxX, vertex.x);
      maxY = Math.max(maxY, vertex.y);
    });
  }

  return {
    minX,
    maxX,
    minY,
    maxY,
  };
};

const onDrawLayers = (layers, minX, minY, ctx) => {
  layers.forEach((layer) => {
    layer.draw({
      originX: layer.x - minX,
      originY: layer.y - minY,
      ctx,
    });
  });
};

export const onDownloadLayer = (layer, callback = () => { }) => {
  try {
    if (!(layer instanceof LayerObject)) {
      callback()
      return
    };

    const canvas = document.createElement("canvas");

    canvas.width = layer.originalWidth * layer.scaleX;
    canvas.height = layer.originalHeight * layer.scaleY;
    const ctx = canvas.getContext("2d");
    ctx.save();

    const props = { originX: 0, originY: 0, offsetX: 0, offsetY: 0, scale: 1, ctx }

    if (layer.drawOriginal) {
      layer.drawOriginal(props)
    } else {
      layer.draw(props)
    }

    canvas.toBlob(function (blob) {
      if (!blob) return;
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = "studio_editor.png";
      a.dispatchEvent(new MouseEvent("click"));
      callback()
    });

    ctx.restore();
  } catch (e) {
    callback()
  }

}

export default onDownload;
